import React, {useEffect, useState} from "react";
import { useNavigate, Link } from "react-router-dom";
import { loadUserOwnershipAsync, loadUserTransactionAsync } from "../../store/reducer/manage-products/user-ownership";
import { useSelector, useDispatch } from "react-redux";
import SuccessIcon from "../../assets/icons/ic-success.svg"
import FailedIcon from "../../assets/icons/ic-failed.svg"


const CheckTransactionStatus = () => {
  const [status, setStatus] = useState('in_process');
  const { transaction, products } = useSelector((state) => state.userOwnership) ?? {};
  const profile = useSelector((state) => state.userInfo.profile) ?? {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [purchased, setPurchased] =useState([]);

  useEffect(() => {
    const transactionId = localStorage.getItem('transaction_id');
    if (profile && transactionId) {
      checkTransactionStatusById(transactionId)
      const intervalId = setInterval(() => checkTransactionStatusById(transactionId), 5 * 1000);
      return () => clearInterval(intervalId);
    } else {
      navigate('/products');
    }
  }, []);

  useEffect(() => {
    if (transaction?.status !== status) {
      setStatus(transaction?.status);
    }
    if (transaction?.status === 'completed' || transaction?.status === 'failed') {
      localStorage.removeItem('transaction_id');
    }
    if (transaction?.status === 'completed') {
      dispatch(loadUserOwnershipAsync(profile.userId));
      setPurchased(products?.filter((product) => transaction.products_id.includes(product.id)));
    }
  }, [transaction]);

  const checkTransactionStatusById = async (id) => {
    try {
      await dispatch(loadUserTransactionAsync(id));
    } catch (error) {
      console.error('Error checking transaction', error);
    }
  };
  return (
    <>
      {status === 'in_process' && (
        <div className="d-flex flex-column align-items-center my-10 py-10">
          <div>
            <div className="">
              <img src="images/loader.gif" alt="" />
            </div>
          </div>
          <h2>Проверяем статус оплаты...</h2>
        </div>
      )}
      {status === 'completed' && (
        <div className="d-flex flex-column align-items-center my-10 py-10 text-center">
          <div>
            <div className="">
              <img src={SuccessIcon} alt="" width={150} height={150} />
            </div>
          </div>
          <h2>Оплата прошла успешно</h2>
          <h4>Методические материаллы всегда доступны в личном кабинете.</h4>
          <h4>Можете скачать материалы по ссылкам:</h4>
          {purchased.map((product) => {
            return(
              <a key={product.id} href={product.media} download={product.title}>
                {product.media}
              </a>
            )
          })}
          <Link to="/purchased" className="btn btn-primary btn-sm mt-5">
            Вернуться к покупкам
          </Link>
        </div>
      )}
      {status === 'failed' && (
        <div className="d-flex flex-column align-items-center my-10 py-10 text-center">
          <div>
            <div className="">
              <img src={FailedIcon} alt="" width={150} height={150} />
            </div>
          </div>
          <h2>Оплата не прошла</h2>
          <Link to="/purchased" className="btn btn-primary btn-sm mt-5">
            Вернуться к покупкам
          </Link>
        </div>
      )}
    </>
  );
};

export default CheckTransactionStatus;