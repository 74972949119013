// rootReducer.js
import { combineReducers } from "redux";
import {configureStore} from "@reduxjs/toolkit"
import productReducer from "./productReducer";
import explorationReducer from "./explorationReducer";
import teachersInHistoryReducer from "./teachersInHistoryReducer";
import sliderReducer from "./sliderReducer";
import registerDataReducer from "./registerDataReducer";
import onlineLecturesReducer from "./onlineLecturesReducer";
import interactiveLessonsReducer from "./interactiveLessonsReducer";
import userInfoReducer  from "./auth/userInfoReducer";
import detailArticleReducer from "./manage-sections/detail-article"
import pedagogicalSearchReducer from "./pedagogicalSearchReducer";
import detailExplorationReducer from "./manage-explorations/detail-exploration";
import detaiSliderReducer from "./manage-main-slider/detail-main-slider";
import detailTeacherInHistoryReducer from "./manage-teachers-in-history/detail-teachers-in-history"
import detailOnlineLectureReducer from "./mange-online-lectures/detail-online-lecture";
import detailProductReducer from "./manage-products/detail-product";
import productsFilterReducer from "./manage-products/productsFilter";
import userOwnershipReducer from "./manage-products/user-ownership"
import detailInteractiveLessonReducer from "./manage-interactive-lessons/detail-interactive-lesson";
import interactiveLessonsFormReducer from "./manage-interactive-lessons/interactive-lesson-form";
import {settingsApi} from "../../api/settings/settingsApi";
import interactiveLessonsRequestsReducer from "./interactiveLessonsRequestsReducer";
import psychologicalConsultReducer from "./psychologicalConsultReducer";
import contactUsFormReducer from "./manage-contact-us/contact-us-form";
import educationProgramsReducer from "./educationProgramsReducer";
import detailEducationProgramReducer from "./manage-education-programs/detail-education-program";


export function makeStore(){
  return configureStore({
    reducer: {
      exploration: explorationReducer,
      teachersInHistory: teachersInHistoryReducer,
      slider: sliderReducer,
      registerData: registerDataReducer,
      interactiveLessons: interactiveLessonsReducer,
      interactiveLessonsForm: interactiveLessonsFormReducer,
      detailInteractiveLesson: detailInteractiveLessonReducer,
      interactiveLessonsRequests: interactiveLessonsRequestsReducer,
      userInfo: userInfoReducer,
      detailArticle: detailArticleReducer,
      pedagogicalSearch: pedagogicalSearchReducer,
      detailExploration: detailExplorationReducer,
      detailSlider: detaiSliderReducer,
      detailTeacherInHistory: detailTeacherInHistoryReducer,
      onlineLectures: onlineLecturesReducer,
      detailOnlineLecture: detailOnlineLectureReducer,
      products: productReducer,
      detailProduct: detailProductReducer,
      productsFilter: productsFilterReducer,
      userOwnership: userOwnershipReducer,
      psychologicalConsult: psychologicalConsultReducer,
      contactUsForm: contactUsFormReducer,
      educationPrograms: educationProgramsReducer,
      detailEducationProgram: detailEducationProgramReducer,
      [settingsApi.reducerPath]: settingsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(settingsApi.middleware),
  })
} 

const store = makeStore()

export default store
