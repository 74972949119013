import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import TeacherCard from "./TeacherCard";
import {loadTeachersInHistoryAsync} from "../../store/reducer/teachersInHistoryReducer"

function TeachersGroup() {
 
  const teachersInHistory = useSelector((state) => state.teachersInHistory.teachersInHistory);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadTeachersInHistoryAsync())
  }, [dispatch])
  
  return (
    <div>
      <section>
        <Container>
          <div className="row justify-content-center text-center mb-6">
            <Col xs="12" lg="8">
              <div>
                <h2 className="font-w-6">История педагогического  успеха</h2>
                <p className="lead mb-0">
                  Перед вами пройдут профессиональные судьбы выпускников Алтайского государственного университета и других классических университетов, посвятивших себя школе, детям, тех, для кого профессия учителя стала смыслом и образом жизни.
                </p>
              </div>
            </Col>
          </div>
          <Row className="justify-content-center gap-3">
            {teachersInHistory.map((teacher, index) => (
              <TeacherCard key={index} item={teacher} onEditorial={false}/>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default TeachersGroup;
