import {createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFilterData, getProducts } from "../../services/Products";

const initialState = {
  products: [],
  selectedProduct: "",
  categories: [],
  minMaxPrices: [],
  cart: [],
  wishList: [],
}

export const loadProductsAsync = createAsyncThunk (
  "loadProductsAsync",
  async(payload) => {
      const response = await getProducts(payload);
      if (response.success) return response.data;
      else return undefined;
  }
)

export const loadFilterDataAsync = createAsyncThunk (
  "loadFilterDataAsync",
  async() => {
      const response = await getFilterData();
      if (response.success) return response.data;
      else return undefined;
  }
)


const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
      state.filteredProducts = action.payload;
    },
   
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    setSelectedPortFolio(state, action) {
      state.selectedPortFolio = action.payload;
    },

    updateCartItemQuantity(state, action) {
      const { index, quantity } = action.payload;
      state.cart[index].quantity = quantity;
    },
    removeCartItem(state, action) {
      const productId = action.payload;
      const index = state.cart.findIndex((item) => item.id === productId);

      if (index !== -1) {
        state.cart.splice(index, 1);
      }
    },
    removeWishListItem(state, action) {
      const productId = action.payload;
      const index = state.wishList.findIndex((item) => item.id === productId);

      if (index !== -1) {
        state.wishList.splice(index, 1);
      }
    },
    
  },
  extraReducers: builder => {
    builder.addCase(loadProductsAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.products = action.payload || []
        }
    })
     builder.addCase(loadFilterDataAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const {
                    categories,
                    prices
                } = action.payload;

                state.categories = categories;
                state.minMaxPrices = prices;
                
            }
        })  
  } 
});

export const {
  setProducts,
  setFilters,
  filterProducts,
  resetFilters,
  updateCartItemQuantity,
  removeCartItem,
  setSelectedProduct,
  removeWishListItem,
} = productsSlice.actions;

export default productsSlice.reducer;
