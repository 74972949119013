import React from "react";
import { useNavigate } from "react-router-dom";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import {
  Col,
  Row,
  Container,
  Table,
} from "reactstrap";

import { Link } from "react-router-dom";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";
import { useCart } from "../../hooks/hooks";
import { createTransaction } from "../../services/Products";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";

function ProductCart() {
  const { cart, removeItemFromCart, clearCart} = useCart();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const firstBreadcrumb = { label: "Методические материалы", link: "/products" };
  const secondBreadcrumb = {
    label: "Корзина",
    link: "/product-cart",
    active: true,
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      if(item.price){
        const itemTotal = item.price;
        return total + itemTotal;
      }
    }, 0);
  };
  const calculateTotalSalePrice = () => {
    return cart.reduce((total, item) => {
      if(item.price_with_discount){
        const itemTotal = item.price_with_discount;
        return total + itemTotal;
      }
      else {
        const itemTotal =item.price;
        return total + itemTotal;
      }
    }, 0);
    
  };
  let products_id = []
  cart.map((product) => {
    products_id.push(product.id)
  })
  const onPurches = async () => {
    if(!isAuthenticated) {
      navigate('/login')
      toast.error('Войдите, чтобы купить методические материалы', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
      return  
    }
    
    const payload = {
      products_id: products_id,
      price: calculateTotalSalePrice(),
      politika: "Y",
      rules: "Y",
    };
    const transcation = await createTransaction(payload);
    localStorage.setItem('transaction_id', transcation.data.transaction_id);
    window.open(transcation.data.url, "_blank");
    clearCart()
    navigate("/check-transaction-status");
  };
  return (
    <div className="page-wrapper">
      <Page_Heading
        title="Корзина"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section>
          <Container>
            <Row>
              <Col>
                <div className="table-responsive border-bottom-0 p-5 shadow rounded">
                <Table className="cart-table text-center mb-5 table-bordered">
        <thead>
          <tr>
            <th className="h5 mb-0 py-3 font-w-6" scope="col">
              Товар
            </th>
            <th className="h5 mb-0 py-3 font-w-6" scope="col">
              Цена
            </th>
            <th className="h5 mb-0 py-3 font-w-6" scope="col">
              Удалить
            </th>
          </tr>
        </thead>

        <tbody className="border-top-0">
          {cart.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="d-md-flex align-items-center">
                  <div >
                    <img
                      className="rounded mb-2 mb-lg-0 object-fit-cover"
                      src={item.preview_image_link}
                      alt={item.title}
                      height={100}
                      width={100}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div
                      onClick={() => navigate(`/product-single/${item.id}`)}
                      className="ms-2 fs-6 text-start product-name link-title cursor-pointer"
                    >
                      {item.title}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="d-flex flex-column">
                  {item.price_with_discount ? (
                    <>
                      <span style={{ marginRight: "5px", fontSize: "21px" }}>
                        {item.price_with_discount} ₽
                      </span>
                      <del className="text-muted mr-2">{item.price} ₽</del>
                    </>
                  ) : (
                    <span style={{ marginRight: "5px", fontSize: "21px" }}>
                      {item.price} ₽
                    </span>
                  )}
                </div>
              </td>
              <td className="border-right-0">
                <button
                  type="submit"
                  onClick={() => {
                    removeItemFromCart(item.id);
                  }}
                  className="btn btn-primary py-2 px-3 fs-3"
                >
                  <i className="bi bi-x-octagon"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
                </div>
              </Col>
            </Row>
            <Row className="mt-8">
              <Col lg="6" className="mt-5 mt-lg-0 ps-lg-10">
                <div className="rounded-4 p-5 bg-light">
                  <div className="rounded-4 p-5 bg-white">
                    <h4 className="text-dark text-center mb-2">Ваша корзина</h4>
                    <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                      <span className="text-muted">Товары ({cart.length})</span>
                      <span className="text-dark">{calculateTotalPrice()} ₽</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom py-3">
                      <span className="text-muted">Скидка</span>
                      <span className="text-danger">{calculateTotalSalePrice()-calculateTotalPrice()} ₽</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-3 mb-5">
                      <span className="text-dark h5">Всего</span>
                      <span className="text-success font-weight-bold h5">
                        {calculateTotalSalePrice()} ₽
                      </span>
                    </div>
                    <Link className="btn btn-dark" to="#" onClick={onPurches}>
                      Перейти к оформлению
                    </Link>
                    <Link
                      className="btn btn-outline-primary mt-3"
                      to="/products"
                    >
                      Вернуться к выбору методических материалов
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <RegisterBanner />
      </div>
    </div>
  );
}

export default ProductCart;
