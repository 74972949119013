import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getInteractiveLessonsRequests, getUserInteractiveLessonsRequests } from "../../services/InteractiveLessonsService";

const initialState = {
  interactiveLessonsRequests: [],
  selectedInteractiveLessonRequests: ''
}

export const loadInteractiveLessonsRequestsAsync = createAsyncThunk (
  "loadInteractiveLessonsAsync",
  async() => {
      const response = await getInteractiveLessonsRequests();
      if (response.success) return response.data;
      else return undefined;
  }
)


export const loadUserInteractiveLessonsRequestsAsync = createAsyncThunk (
  "loadUserInteractiveLessonsAsync",
  async(userId) => {
      const response = await getUserInteractiveLessonsRequests({user_id: userId});
      if (response.success) return response.data;
      else return undefined;
  }
)
const interactiveLessonsRequestsSlice = createSlice({
  name: "interactiveLessonsRequests",
  initialState,
  reducers: {
    setSelectedInteractiveLessonRequests(state, action) {
      state.selectedInteractiveLessonRequests = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadInteractiveLessonsRequestsAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.interactiveLessonsRequests = action.payload || []
        }
    })
    builder.addCase(loadUserInteractiveLessonsRequestsAsync.fulfilled, (state, action) => {
      if (action.payload) {
          state.interactiveLessonsRequests = action.payload || []
      }
  })
  }  
});

export const { 
  setSelectedInteractiveLessonRequests,

} = interactiveLessonsRequestsSlice.actions;

export default interactiveLessonsRequestsSlice.reducer;
