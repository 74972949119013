import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getTransaction, getUserOwnership } from "../../../services/Products";

const initialState = {
    products: [],
    transaction: {},
}

export const loadUserOwnershipAsync = createAsyncThunk(
    "loadUserOwnershipAsync",
    async (userId) => {
        const response = await getUserOwnership({user_id: userId})
        return response.data;
    }
)

export const loadUserTransactionAsync = createAsyncThunk(
    "loadUserTransactionAsync",
    async (transactionId) => {
        const response = await getTransaction({transaction_id: transactionId})
        return response.data;
    }
)


const userOwnershipSlice = createSlice({
  name: "userOwnership",
  initialState,
  reducers: {
    clearState: () => ({...initialState}),
    changeTransactionStatus: (state, action) => {
        state.transaction.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder
        .addCase(loadUserOwnershipAsync.fulfilled, (state, action) => {
            if (action.payload) {
                state.products = action.payload
            }
        })  
        .addCase(loadUserTransactionAsync.fulfilled, (state, action) => {
            if (action.payload) {
                state.transaction = action.payload
            }
        })
    }
});

export const {
    clearState,
    changeTransactionStatus
} = userOwnershipSlice.actions;

export default userOwnershipSlice.reducer;
