import React, {useEffect} from "react";
import {Container, Button} from "reactstrap"
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useLocation, Outlet} from "react-router-dom"
 
import ConfirmDeleteModal  from "../../../Components/Editorial/ConfirmDeleteModal"
import EducationItem from "../../../Components/education/EducationItem";
import { loadEducationProgramsAsync } from "../../../store/reducer/educationProgramsReducer";

const ManageEducationPrograms = () => {
    const educationPrograms = useSelector((state) => state.educationPrograms);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();
    
    useEffect(() => {
        dispatch(loadEducationProgramsAsync())
    }, [location.pathname])
    
    const onCreateClick = () => {
        navigate("add-education-program")
    }
    
    return (
        
        <Container className="d-flex gap-3 flex-column px-10">
            {location.pathname === "/editorial/manage-education-programs" ?
                                                                        <>
                                                                        <div className="w-100 d-flex justify-content-between mb-4">
                                                                            <h3>Программы обучения</h3>
                                                                            <Button className="btn btn-primary" onClick={onCreateClick}>Создать</Button>
                                                                        </div>
                                                                        <div className="d-flex flex-wrap flex-column gap-3">
                                                                            {educationPrograms.educationPrograms.map((item, index) => (
                                                                                <EducationItem key={index} program={item} onEditorial/>
                                                                            ))}
                                                                        </div>
                                                                        <ConfirmDeleteModal item={educationPrograms.selectedEducationProgram}/>
                                                                        </> 
                                                                : <Outlet/>
            }
        </Container>
      
    )
}

export default ManageEducationPrograms;