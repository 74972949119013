import React, {useEffect} from "react";
import Fade from 'react-reveal/Fade';
import {loadSliderImagesAsync} from '../../store/reducer/sliderReducer'
import {
  Container
} from "reactstrap";

import LottiePlayer from "../player";
import Bg from "../../assets/lottie/heroBanner1.json";
import {useSelector, useDispatch} from "react-redux";
import Slider from "../../Components/Slider/Slider";
function HeroBanner1() {
  const sliderImages = useSelector((state) => state.slider.sliderImages);
  const teachersInHistory = useSelector((state) => state.teachersInHistory.teachersInHistory);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(loadSliderImagesAsync());
  }, [dispatch, teachersInHistory])
  
  const options = {
    nav: true,
    items: 1,
    loop: true,
    margin:100
  };
  return (
      <section className="overflow-hidden position-relative">
        <Container>
          <Fade>
            <Slider items={sliderImages}/>
          {/* <OwlCarousel
              className='owl-carousel owl-theme'
              {...options}
          >
            {
              sliderImages.map((img, index) => (
                <>
                {img.page_link ? <Link to={img.page_link} key={index}  className='item'>
                                  <img  src={img.preview_image_link} alt="" className="img-fluid" fetchpriority="high"/>
                                </Link>
                                : <div key={index}  className='item'>
                                  <img  src={img.preview_image_link} alt="" className="img-fluid" fetchpriority="high"/>
                                </div>
                }
                </>
                
                
              ))
            }

          </OwlCarousel> */}
          </Fade>
        </Container>
        <div className="position-absolute animation-1">
          <LottiePlayer src={Bg} />
        </div>
      </section>
  );
}

export default HeroBanner1;

