import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button} from "reactstrap";
import { loadInteractiveLessonsRequestsAsync } from "../../store/reducer/interactiveLessonsRequestsReducer";
import { updateInteractiveLessonRequestStatus } from "../../services/InteractiveLessonsService";

const InteractiveLessonsRequestItem = ({lessonRequest, onEditorial}) => {
    const dispatch = useDispatch();
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, поэтому добавляем 1
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    const onUpdateStatus = (status, id) => {
        const payload = {
            id: id,
            status: status,
        }
        updateInteractiveLessonRequestStatus(payload)
        dispatch(loadInteractiveLessonsRequestsAsync())
    }
    return (
        <div className="p-4 border rounded" style={{maxWidth: "320px"}}>
                        <div className="d-flex justify-content-end">
                            {lessonRequest.status == "Ожидает" && <div className="text-warning">{lessonRequest.status}</div>}
                            {lessonRequest.status == "Готово" && <div className="text-success">{lessonRequest.status}</div>}
                            {lessonRequest.status == "Отклонено" && <div className="text-danger">{lessonRequest.status}</div>}
                        </div>
                        {onEditorial && <h5>
                                            {lessonRequest.full_name}
                                        </h5>
                        }
                        <p className="">
                            <b>Урок:</b> {lessonRequest.lesson_link}
                        </p>
                        {onEditorial && <>
                                            <p>
                                                <b>Эл.почта:</b> {lessonRequest.email}
                                            </p>
                                            <p>
                                                <b>Номер телефона:</b> {lessonRequest.phone_number}
                                            </p>
                                        </>
                        }
                        <p className="">
                            <b>Дата обращения:</b> {lessonRequest.creation_time && formatDate(lessonRequest.creation_time)}
                        </p>
                        <p className="">
                            <b>Дата проведения урока:</b> {lessonRequest.lesson_date && lessonRequest.lesson_date.replace(/^(\d+)-(\d+)-(\d+)$/, "$3.$2.$1")}
                        </p>
                        {onEditorial && <div className="d-flex flex-column gap-2">
                                            {lessonRequest.status != "Готово" && <Button onClick={() => onUpdateStatus("Готово", lessonRequest.id)} size="sm" color="success">Готово</Button>}
                                            {lessonRequest.status != "Ожидает" && <Button onClick={() => onUpdateStatus("Ожидает", lessonRequest.id)} size="sm" color="warning">Ожидает</Button>}
                                            {lessonRequest.status != "Отклонено" && <Button onClick={() => onUpdateStatus("Отклонено", lessonRequest.id)} size="sm" color="danger">Отклонить</Button>}
                                        </div>
                        }
                        
                    </div>
    )
}

export default InteractiveLessonsRequestItem