import React, { useState } from "react";

import {
  Children,
  SidebarContainer,
  SidebarWrapper,
  SidebarLogoWrapper,
  SidebarLogo,
  SidebarBrand,
  SidebarToggler,
} from "./SidebarStyles";
import BrandLogo from "./logo.svg";
import LogoNoText from "../../../assets/icons/ic-logo-no-text.svg"
import PinnedIcon from "../../../assets/icons/ic-pinned.svg"
import PinnedFillIcon from "../../../assets/icons/ic-pinned-fill.svg"
import SidebarItems from "./SidebarItems";

const MOBILE_VIEW = window.innerWidth < 468;

export default function Sidebar({ children }) {
  const [displaySidebar, setDisplaySidebar] = useState(!MOBILE_VIEW);

  const handleSidebarDisplay = (e) => {
    e.preventDefault();
    if (window.innerWidth > 468) {
      setDisplaySidebar(!displaySidebar);
    } else {
      setDisplaySidebar(false);
    }
  };

  return (
    <React.Fragment>
      <SidebarContainer displaySidebar={displaySidebar}>
        <SidebarWrapper>
          <SidebarLogoWrapper displaySidebar={displaySidebar}>
            <SidebarLogo href="#">
              <span className="app-brand-logo">
              <img src={LogoNoText} alt="Brand logo"  width={64}/>
                {/* {displaySidebar ? <img src={BrandLogo} alt="Brand logo"  width={128}/>
                                : <img src={LogoNoText} alt="Brand logo"  width={64}/>
                } */}
              </span>
            </SidebarLogo>
            <SidebarToggler
              displaySidebar={displaySidebar}
              onClick={handleSidebarDisplay}
            > 
             {displaySidebar ? <img src={PinnedFillIcon} width={24} height={24}/>
                             : <img src={PinnedIcon} width={24} height={24}/>
             }
            </SidebarToggler>
          </SidebarLogoWrapper>
          <SidebarItems displaySidebar={displaySidebar} />
        </SidebarWrapper>
      </SidebarContainer>
      <Children displaySidebar={displaySidebar}>{children}</Children>
    </React.Fragment>
  );
}
