import React from "react";
import { useParams, Outlet } from "react-router-dom"
import PageHeading from "../../Components/page-heading/Page_Heading";
import TeachersGroup from "../../Components/Teachers/TeachersGroup";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";

function TeachersPage() {
  const params = useParams();
  const firstBreadcrumb = { label: "", link: "/team" };
  const secondBreadcrumb = {
    label: "Преподаватели",
    link: "/team",
    active: true,
  };
  return (
    <div>
      {!params.teacherInHistoryId ? <>
                                        <PageHeading
                                        title="Имена нашей истории"
                                        firstBreadcrumb={firstBreadcrumb}
                                        secondBreadcrumb={secondBreadcrumb}
                                      />
                                      <div className="page-content">
                                        <TeachersGroup />
                                        <RegisterBanner />
                                      </div>
                                    </>
                                  :
                                  <Outlet/>  
    }
      
    </div>
  );
}

export default TeachersPage;
