import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getSliderImages} from "../../services/MainSliderService";

const initialState = {
    sliderImages:[],
}
  
export const loadSliderImagesAsync = createAsyncThunk (
    "loadSliderImagesAsync",
    async() => {
        const response = await getSliderImages();
        if (response.success) return response.data;
        else return undefined;
    }
)

const sliderSlice = createSlice({
    name: "slider",
    initialState,
    reducers: {
        updateSliderImagesOrder(state, action) {
            state.sliderImages = action.payload;
        },
        
    },
    extraReducers: builder => {
        builder.addCase(loadSliderImagesAsync.fulfilled, (state, action) => {
            if (action.payload) {
                state.sliderImages = action.payload || []
            }
        })
    } 
});

export const { 
    updateSliderImagesOrder
 } = sliderSlice.actions;

export default sliderSlice.reducer;
