import React from 'react';
import {useLocation} from "react-router-dom";

const SettingsContent = ({pages}) => {

    const location = useLocation();

    return (
        <div className="settingsContent">
            {pages.map((page, idx) => {
                if (location.pathname.includes(page.path)) return (
                    <div className="settingsContent_wrapper" key={idx}>
                        {page.Component}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(SettingsContent);
