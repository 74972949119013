import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService";
import { useAuth } from "../../context/auth";

const RequireAuth = ({children}) => {
    const location = useLocation();
    const { isAuthenticated } = useAuth();
    if(!isAuthenticated) {
        return <Navigate to="/login" state={{from: location}} />
    }
    return children;
}

export default RequireAuth;