import React from "react";

const MapSection = () => {
  return (
    <div className="map">
      <iframe
        className="w-100 h-100 border-0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d421.03293056891806!2d83.77561439229623!3d53.34823384510488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x42dda48d940b3e17%3A0x4e9d35a365be72d3!2z0JDQu9GC0LDQudGB0LrQuNC5INCz0L7RgdGD0LTQsNGA0YHRgtCy0LXQvdC90YvQuSDRg9C90LjQstC10YDRgdC40YLQtdGC!5e0!3m2!1sru!2skz!4v1700286519677!5m2!1sru!2skz"
        allowFullScreen=""
        title="Google Maps"
      ></iframe>
    </div>
  );
};

export default MapSection;
