import React from "react";
import SlideTitle from "./SlideTitle";
import SlideImage from "./SlideImage";

import "./../styles.css";

export default function Slide({ data: { preview_image_link, page_link} }) {
  return (
    <div className="slide">
      <SlideImage src={preview_image_link} alt={preview_image_link} link={page_link} />
      {/* <SlideTitle title={title} /> */}
    </div>
  );
}
