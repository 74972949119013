import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import { useMount } from "../../hooks/hooks";
import PageHeading from "../../Components/page-heading/Page_Heading";
import Teachers from "../../Components/Teachers/TeachersGroup";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { TextEditor } from "../../Components/EditorComponent/TextEditor/TextEditor";
import { loadDetailTeacherInHistoryAsync } from "../../store/reducer/manage-teachers-in-history/detail-teachers-in-history"
import LottiePlayer from "../../Components/player";

const TextArticle = ({content}) => {

  const [mounted] = useMount();
  return (
      <div className="d-flex w-100 flex-column pb-3" width={"full"} direction={"column"} >
          {mounted && <TextEditor readOnly initialValues={content}/>}
      </div>
  )
}



const TeacherSingle = () => {
  const detailTeacherInHistory = useSelector((state) => state.detailTeacherInHistory);
  const [mounted] = useMount();

  const dispatch = useDispatch();
  const params = useParams();
  const firstBreadcrumb = { label: "", link: "/team-single" };
  const secondBreadcrumb = {
    label: detailTeacherInHistory.name,
    link: "/teachers-single",
    active: true,
  };
  
  useEffect(() => {
    dispatch(loadDetailTeacherInHistoryAsync(params.teacherInHistoryId))
  }, [params.teacherInHistoryId]);
  
  const {content} = detailTeacherInHistory
  
  if (!mounted || !content) return null;

    const values = Boolean(content) ? content : undefined;
    return (
      <div>
        <PageHeading
          title="История педагогического успеха в лицах"
          firstBreadcrumb={firstBreadcrumb}
          secondBreadcrumb={secondBreadcrumb}
        />
        <div className="page-content">
          <section className="bg-light">
            <Container>
              <div>
                  
                  <div className="d-flex justify-content-around mb-4 teachers-in-history-header position-relative overflow-hidden">
                    <div className="shadow p-4 bg-white rounded-4 " style={{width:"340px", float: "left", zIndex: "10"}}>
                        <img
                          className="img-fluid w-100 rounded-4"
                          src={detailTeacherInHistory.image_link}
                          alt=""
                        />
                    </div>
                    
                    <div className=" align-self-center ">
                    <h2 className="p-3 text-center position-relative" style={{zIndex: "10"}}>
                      {detailTeacherInHistory.name}
                      <br/>
                      <span className="text-primary">
                        - {detailTeacherInHistory.position}
                      </span>
                    </h2>
                    <div className="position-absolute animation-2 opacity-5" >
                        <LottiePlayer src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json" />
                    </div>
                    </div>
                  </div>
                  <TextArticle content={detailTeacherInHistory.content}/>
              </div>
              
            </Container>
          </section>
          <Teachers />
        </div>
      </div>
    );
}

export default TeacherSingle;
