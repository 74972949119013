import React from "react";
import { useNavigate } from 'react-router-dom';
import {Container, Button} from "reactstrap"
 
import AddItem from "../../../Components/Editorial/AddItem";
import AccessComponent from "./AccessComponent";
const AddSubsection = () => {
    let navigate = useNavigate();
    const password = localStorage.getItem("accessPassword");
    const access = password === process.env.REACT_APP_ACCESS_PASSWORD
    return(
        // <>
        // {/* {access ? <div className="d-flex mt-5"> */}
                        <Container className="px-5">
                            <div className="w-100 d-flex justify-content-between mb-4">
                                <h3>Добавить рубрику</h3>
                                <Button className="btn" onClick={() => navigate('/editorial/manage-subsections')}>Назад</Button>
                            </div>
                            <AddItem item={"subsection"}/>
                        </Container>
                    // </div>
                // :
                // <AccessComponent/>
        // }    
        // </>
    )
}

export default AddSubsection;