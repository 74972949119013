import React, { useEffect } from "react";
import { Button } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  loadProductsAsync,
  loadFilterDataAsync
} from "../../store/reducer/productReducer";
import {
  changeFilterCategory,
  changeFilterMinMaxPrice,
  clearFilterCategory
} from "../../store/reducer/manage-products/productsFilter";
import { Slider } from "antd";

const SideBar = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);

  const productsFilter = useSelector((state) => state.productsFilter);
  
  const handleCategoryClick = (category) => {
    dispatch(changeFilterCategory(category))
  };
  
  const resetPriceFilter = () => {
    dispatch(changeFilterMinMaxPrice([0,products.minMaxPrices[1]]))
  }
  
  const resetFilter = () => {
    dispatch(changeFilterMinMaxPrice([0,products.minMaxPrices[1]]))
    dispatch(clearFilterCategory())
  }
  
  useEffect(()=> {
    dispatch(loadFilterDataAsync())
  }, [])
  
  useEffect(() => {
    const payload = {
      // page: productsFilter.filterPage,
      // count: productsFilter.filterCount,
      order: productsFilter.filterOrder,
      category: productsFilter.fliterCategory,
      min_price: productsFilter.filterMinPrice,
      max_price: productsFilter.filterMaxPrice,
    }
    
    dispatch(loadProductsAsync(payload));
  }, []);
  
  const onFilter = () => {
    const payload = {
      category: productsFilter.fliterCategory,
      min_price: productsFilter.filterMinPrice,
      max_price: productsFilter.filterMaxPrice,
      order: productsFilter.filterOrder
    }
    window.scroll(0,0)
    dispatch(loadProductsAsync(payload));
  }
  
  return (
    <div>
      <div className="p-5 py-5 rounded-4 border border-light">
        {/* <div className="pb-4 mb-4 mb-4 border-bottom border-light">
          <Button
            style={{ width: "100px", height: "36px" }}
            onClick={resetFilter}
            className="bg-transparent p-0 text-dark"
          >
            Сбросить
          </Button>
        </div> */}
        <div className=" mb-4 pb-4 border-light">
          <h4 className=" mb-3">Категории</h4>
          {products.categories.map((category, index) => (
            <div key={index} className="form-check mb-2">
              <input
                id={category}
                type="checkbox"
                className="form-check-input"
                value={category}
                defaultChecked={
                  productsFilter.fliterCategory.includes(category) ? true : false
                }
                onClick={() => handleCategoryClick(category)}
              />
              <label className="form-check-label" key={category}>
                {category}
              </label>
            </div>
          ))}
        </div>
        <div className="mb-4 pb-4 border-bottom">
          <h4 className="mb-3">Цена</h4>
          <button
            className="border-0 p-0 bg-transparent btn-link"
            onClick={() => resetPriceFilter()}
          >
            Сбросить
          </button>
          <p>Oт: {productsFilter.filterMinPrice} До: {productsFilter.filterMaxPrice}</p>
          <div className="price_slider_wrapper">
            {products.minMaxPrices[1] != null && <Slider
                                                    range
                                                    defaultValue={[0,products.minMaxPrices[1]]}
                                                    min={0}
                                                    max={products.minMaxPrices[1]}
                                                    onChange={(value) => dispatch(changeFilterMinMaxPrice(value))}
                                                  />
            }
            
          </div>
        </div>
        <Button className="btn btn-primary btn-sm" onClick={onFilter}>
          Применить
        </Button>
        
      </div>
    </div>
  );
}

export default SideBar;
