import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ItemsList,
  ItemContainer,
  ItemWrapper,
  ItemName,
} from "./SidebarStyles";

import { SIDEBAR_DATA as dummyData}  from "./Data";

const SidebarItems = ({ displaySidebar }) => {
  const [activeItem, setActiveItem] = useState(0);
  const navigate = useNavigate();
  const onItemClick =(itemData) => {
    setActiveItem(itemData.name)
    navigate(itemData.path)
  }
  return (
    <ItemsList>
      {dummyData.map((itemData, index) => (
        <ItemContainer
          key={index}
          onClick={() => onItemClick(itemData)}
          className={itemData.name === activeItem ? "active" : ""}
        >
          <Link to={itemData.path}>
            <ItemWrapper>
              {itemData.icon && <img src={itemData.icon} alt="" height={32} width={32}/>}
              <ItemName displaySidebar={displaySidebar}>
                {itemData.name}
              </ItemName>
            </ItemWrapper>
          </Link>
        </ItemContainer>
      ))}
    </ItemsList>
  );
};

export default SidebarItems;
