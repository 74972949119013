import React from "react";
import { useNavigate } from 'react-router-dom';
import {Container, Button} from "reactstrap"
 
import AddItem from "../../../Components/Editorial/AddItem";
import AccessComponent from "./AccessComponent";

const AddArticle = () => {
    let navigate = useNavigate();
    
    return(
        <Container className="px-5">
            <div className="w-100 d-flex justify-content-between mb-4">
                <h3>Добавить статью</h3>
                <Button className="btn" onClick={() => navigate('/editorial/manage-articles')}>Назад</Button>
            </div>
            <AddItem item={"article"}/>
        </Container>
    )
}

export default AddArticle;