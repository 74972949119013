import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import {
    Input, 
    Button, 
    Container, 
    FormGroup, 
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import {FormikProvider, useField, useFormik} from "formik";
import {getStaticUrlFile} from "../../common/helpers";
import {createArticle, createSection} from "../../services/PedagogicalSearchService";
import {uploadFiles} from "../../services/uploadFiles";
import DropZone from '../DropZone/DropZone';
import { 
    changeDescriptionArticle,
    changeDestination,
    changeShowDescription,
    changeTitleArticle,
} from '../../store/reducer/manage-sections/detail-article'
import { TextEditor } from '../EditorComponent/TextEditor/TextEditor';
import { toast } from "react-toastify";

const MAX_TITLE_LENGTH = 128;

export const destinationItems = [
    {
        title: "Творческий поиск"
    },
    {
        title: "Родители и дети"
    }
]


const Title = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_TITLE_LENGTH) return;

        dispatch(changeTitleArticle(value));
    }

    return (
        <Input placeholder={"Заголовок"} type='text' onChange={onChange}/>
    )
}

const Description = () => {

    const dispatch = useDispatch();
    const detailArticle = useSelector((state) => state.detailArticle);

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changeDescriptionArticle(value));
    }

    const handleCheckboxChange = () => {
        dispatch(changeShowDescription(!detailArticle.show_description))
        if(detailArticle.show_description) dispatch(changeDescriptionArticle(''))
    }

    return (
        <>
            <FormGroup check>
                    <Input 
                        type="checkbox" 
                        onChange={handleCheckboxChange}
                        />
                    <Label check>
                        Добавить описание
                    </Label>
            </FormGroup>
            <Input placeholder={"Описание"} type='text' onChange={onChange} value={detailArticle.description} disabled={!detailArticle.show_description}/>
        </>
    )
}

const Destination = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const article = useSelector((state) => state.detailArticle);
    
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    
    const onItemClick = (item) => {
        dispatch(changeDestination(item))
    }
    
    return (
        <div className="d-flex flex-column">
            
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
                <DropdownToggle caret>Выберите площадку</DropdownToggle>
                <DropdownMenu>
                    {
                        destinationItems.map((item) => (
                            <DropdownItem onClick={() => onItemClick(item.title)}>{item.title}</DropdownItem>
                        ))
                    }
                </DropdownMenu>
            </Dropdown>
            <p className='mt-2'>Площадка: {article.destination}</p>

        </div>
    )
}

const AddItem = ({item}) => {
    
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    let selectedEditSection;
    let selectedEditSubsection;
    const detailArticle = useSelector((state) => state.detailArticle);
    const ref = useRef({});

    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);
    
    const onChangeFiles = (files) => {
        if (files.length > 0) {
            setPreviewFile(files[0]);
            setFiles([files[0]]);
        }
    }
    const onAddSection = async() => {
        const responseUpload = await uploadFiles({files: [previewFile]});
        const preview = responseUpload?.data?.names[0] || "";
        
        const payload = {
            title: detailArticle.title,
            description: detailArticle.description,
            preview_image_link: getStaticUrlFile(preview),
            show_description: detailArticle.show_description,
            destination: detailArticle.destination
        }
        if(payload.title === "" || payload.preview_image_link === ""){
            toast.warn('Проверьте, все ли поля заполнены', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return; 
        }
        const response = await createSection(payload);
        if( response.success ){
            toast.success('Рубрика успешно добавлена', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
            
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }
   
   const onAddSubsection = async() => {
    const section = localStorage.getItem('selectedEditSection');
    
    if (section) {
        selectedEditSection = JSON.parse(section);
    }else {
        console.error("Ошибка получения подрубрик")
        return
    }
    const responseUpload = await uploadFiles({files: [previewFile]});
    const preview = responseUpload?.data?.names[0] || "";
    const payload = {
        title: detailArticle.title,
        description: detailArticle.description,
        preview_image_link: getStaticUrlFile(preview),
        parent_id: selectedEditSection.id,
        show_description: detailArticle.show_description
    }
    if(payload.title === "" || payload.preview_image_link === "" || payload.parent_id === ""){
        toast.warn('Проверьте, все ли поля заполнены', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return; 
    }
    const response = await createSection(payload);
    if( response.success ){
        toast.success('Подрубрика успешно добавлена', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        
        
    }
    else {
        toast.error('Что-то пошло не так', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });  
    }
   }
   
    const onAddArticle = async() => {
        const subsection = localStorage.getItem('selectedEditSubsection');
        if (subsection) {
            selectedEditSubsection = JSON.parse(subsection);
        }else {
            console.error("Ошибка получения подрубрик")
            return
        }
        const content = await ref.current.save();
        const payload = {
            title: detailArticle.title,
            description: detailArticle.description,
            parent_id: selectedEditSubsection.id,
            content: content,
            show_description: detailArticle.show_description
        }
        if(payload.title === "" || payload.parent_id === ""){
            toast.warn('Проверьте, все ли поля заполнены', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return; 
        }
        
        const response = await createArticle(payload);
        if( response.success ){
            toast.success('Статья успешно добавлена', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
            
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }
   

    return (
        <Container>
            <FormikProvider>
                <form>
                    <div className='d-flex w-100 flex-column gap-3 p-4'>

                        <div className='d-flex w-100 flex-column gap-3 '>
                            <Title/>
                            <Description/>
                            {item === "section" && <Destination/>}
                            
                            {item !== "article" && <div className='d-flex flex-column w-100'>
                                                        <p className='fw-bold'>Изображение для заголовка</p>
                                                        <DropZone files={files} onChangeFiles={onChangeFiles}/>
                                                    </div>
                                
                            
                            }
                            {item === "article" && <div className="d-flex w-100 flex-column mt-4 border rounded" styles={{backGround:"#000"}}>
                                                        <TextEditor setRef={setRef} />
                                                    </div>
                            }
                        </div>

                        <div className='d-flex w-100 gap-1'>
                            {item === "section" && <Button className='btn btn-primary'  onClick={onAddSection}>Создать</Button>}
                            {item === "subsection" && <Button className='btn btn-primary' onClick={onAddSubsection}>Создать</Button>}
                            {item === "article" && <Button className='btn btn-primary'  onClick={onAddArticle}>Создать</Button>}
                            {/* <Button label={"Cancel"} view={"secondary"} width={"full"} onClick={onClose}/> */}
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </Container>
    );
};

export default AddItem;