import React, {useState} from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import { Link } from "react-router-dom";
import { Container, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import InteractiveLessonsForm from "../../Components/interactive-lessons/InteractiveLessonsForm";
import InteractiveLessonsList from "../../Components/interactive-lessons/InteractiveLessonsList";
const InteractiveLessons = () => {
    const firstBreadcrumb = { label: "", link: "/team" };
    const secondBreadcrumb = {
        label: "Интерактивные уроки",
        link: "/interactive-lessons",
        active: true,
    };
    
    
    const [isShowForm, setIsShowForm] = useState(false)
    const toggleForm = () => {
        setIsShowForm(!isShowForm);
      }
    return (
        <div>
            {/* <Page_Heading
                title="Интерактивные уроки"
                firstBreadcrumb={firstBreadcrumb}
                secondBreadcrumb={secondBreadcrumb}
            /> */}
            <Container className="page-wrapper mb-5">
                <div className="d-flex">
                    <div className="d-flex flex-column justify-content-center px-2">
                        <h2 className="mb-5">Интерактивные уроки</h2>
                        <p>
                        Участников платформы ждут интерактивные уроки для школьников, которые проведут в режиме on-line ученые АлтГУ. Если Вы готовы пригласить нашего ученого в класс, регистрируйтесь <Link to="/signup">здесь</Link>. Чтобы записаться на урок, нажмите на любую понравившуюся тему и заполните форму. 
                        </p>
                    </div>
                </div>
            </Container>
            <Container>
                <InteractiveLessonsList toggle={toggleForm}/>
                
                {isShowForm && <InteractiveLessonsForm 
                                    isOpen={isShowForm} 
                                    toggle={toggleForm}
                                    backdrop={"static"}
                                    keyboard={false}
                                />
                }
                
                
            </Container>
        </div>
        
    )
 }
 
 export default InteractiveLessons;