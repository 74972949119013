import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import {Button, FormGroup, Input, Label, Container} from "reactstrap";
import {useMount} from "../../../hooks/hooks";
import {
    changeTitleOnlineLecture,
    changeAuthorOnlineLecture,
    changePreview,
    changeCategoryOnlineLecture
} from "../../../store/reducer/mange-online-lectures/detail-online-lecture";
import {uploadFiles} from "../../../services/uploadFiles";
import {getStaticUrlFile} from "../../../common/helpers";
import {TextEditor} from "../../../Components/EditorComponent/TextEditor/TextEditor";
import { updateOnlineLecture } from "../../../services/OnlineLecturesService"
import { loadDetailOnlineLectureAsync } from '../../../store/reducer/mange-online-lectures/detail-online-lecture';
import OnlineLecturesItem from "../../../Components/OnlineLectures/OnlineLecturesItem"
import { toast } from "react-toastify";


const MAX_TITLE_LENGTH = 128;
const MAX_AUTHOR_LENGTH = 150;
const MAX_CATEGORY_LENGTH = 50;

const Title = () => {
    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture);
    const dispatch = useDispatch();
    
    const onChange = (event) => {
        
        const value = event.currentTarget.value || "";
        
        if (value.length > MAX_TITLE_LENGTH) return;
        
        dispatch(changeTitleOnlineLecture(value))
    }

    return (
        <FormGroup floating>
            <Input 
            placeholder={"Заголовок"} 
            value={detailOnlineLecture.title} 
            onChange={onChange}
            id="title"
            />
            <Label for="title">
                Заголовок
            </Label>
        </FormGroup>
    )
}

const Author = () => {
    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture);


    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_AUTHOR_LENGTH) return;

        dispatch(changeAuthorOnlineLecture(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Автор"} 
            value={detailOnlineLecture.author} 
            onChange={onChange}
            id="author"
        />
        <Label for="author">
            Автор
        </Label>
    </FormGroup>
    )
}

const Category = () => {
    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture);
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_CATEGORY_LENGTH) return;

        dispatch(changeCategoryOnlineLecture(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Категория"} 
            value={detailOnlineLecture.category} 
            onChange={onChange}
            id="category"
        />
        <Label for="category">
            Категория
        </Label>
    </FormGroup>
    )
}

const Preview = () => {
    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture);
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const inputRef = useRef(null);
    let preview
    const onClickPreviewHandler = () => inputRef?.current?.click();
    const onChangePreview = async (event) => {
        const files = event?.currentTarget?.files;
        
        if (files.length > 0) {
            
            setPreviewFile(files[0]);
            setFiles([files[0]]);
            
            const responseUpload = await uploadFiles({files: [files[0]]});
            preview = responseUpload?.data?.names[0] || "";
            dispatch(changePreview(getStaticUrlFile(preview)));
        }
    }
    const payload = {
        id:detailOnlineLecture.id,
        title: detailOnlineLecture.title,
        author: detailOnlineLecture.author,
        preview_image_link: detailOnlineLecture.preview_image_link,
        category: detailOnlineLecture.category,
        content: detailOnlineLecture.content,
        creation_time: detailOnlineLecture.creation_time
    }
    return (
        <div className='d-flex flex-column gap-1 w-100 mb-5'>
            <div className='d-flex justify-content-center'>
                <OnlineLecturesItem  onEditorial={false} lecture={payload}/>
            </div>
            <Button onClick={onClickPreviewHandler} className='btn btn-primary '>Изменить изображение</Button>

            <input type={"file"}
                   accept={"image/*"}
                   ref={inputRef}
                   onChange={onChangePreview}
                   className={"d-none"}/>
        </div>
    )
}

const ArticleText = () => {
    const [mounted] = useMount();
    const [message, setMessage] = useState(undefined);

    const ref = useRef({});
    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture);

    const {content} = detailOnlineLecture

    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setMessage(undefined);
        }, 5 * 1000);

        return () => clearTimeout(timeoutId);
    }, []);

    if (!mounted || !content) return null;

    const values = Boolean(content) ? content : undefined;

    return (
        <div className="d-flex w-100 flex-column mt-4 " styles={{backGround:"#000"}}>
            <div className="border rounded-4 mb-4">
                <TextEditor  setRef={setRef} initialValues={values}/>
            </div>

            <div className={"d-flex w-100 gap-3 mb-4"}>

                {message && <p className="fw-bold" styles={{color:message.color}} >{message.text}</p>}

                <div className="d-flex w-100 gap-2">
                    <SaveButton editorRef={ref} setMessage={setMessage}/>
                    <CancelButton/>
                </div>
            </div>
        </div>
    )
}

const SaveButton = ({editorRef, setMessage}) => {

    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture);


    const saveOnlineLectureInfo = async () => {
        const content = await editorRef?.current?.save();

        const payloadOnlineLectureInfo = {
            id: detailOnlineLecture.id,
            title: detailOnlineLecture.title,
            author: detailOnlineLecture.author,
            category: detailOnlineLecture.category,
            preview_image_link: detailOnlineLecture.preview_image_link,
            content: content
        }

        await updateOnlineLecture(payloadOnlineLectureInfo);
    }

    const onSaveHandler = async () => {
        try {
            await saveOnlineLectureInfo();
            toast.success('Изменения сохранени!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }

    return <Button className='btn btn-primary' onClick={onSaveHandler}>Сохранить</Button>
}

const CancelButton = () => {

    const navigate = useNavigate()

    const onCancelHandler = () => navigate(0);

    return <Button onClick={onCancelHandler}>Отменить</Button>;
}


const EditOnlineLecture = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    
    useEffect(() => {
        dispatch(loadDetailOnlineLectureAsync(params.lectureId))
    }, []);
    
    return (
        <div className='w-100  mt-5'>
            <Container className='d-flex flex-column gap-3'>
                <div className="w-100 d-flex justify-content-between mb-4">
                    <h3>Изменить онлайн лекторий</h3>
                    <Button className="btn" onClick={() => navigate('/editorial/manage-online-lectures')}>Назад</Button>
                </div>
                <Preview/>
                <Title/>
                <Author/>
                <Category/>
                <ArticleText/>
            </Container>
        </div>

    );
};

export default EditOnlineLecture;