import {axiosClient} from "../common/axios";
import Api from "../api/Api";

export const addSliderImage = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.ADD_SLIDER_IMAGE, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const updateSliderImages = async (payload) => {
    try {

        const response = await axiosClient.put(Api.endpoints.UPDATE_SLIDER_IMAGES, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const getSliderImages = async () => {
    
    try {
        const response = await axiosClient.get(Api.endpoints.GET_SLIDER_IMAGES)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const deleteSliderImage = async ({image_id}) => {
    try {
        const url = Api.endpoints.DELETE_SLIDER_IMAGE.replace('{image_id}', image_id);
        
        const response = await axiosClient.post(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}