import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {Input, Button, Container} from 'reactstrap'
import {FormikProvider} from "formik";
import {getStaticUrlFile} from "../../../common/helpers";
import { createOnlineLecture } from '../../../services/OnlineLecturesService';
import {uploadFiles} from "../../../services/uploadFiles";
import DropZone from '../../../Components/DropZone/DropZone';
import { TextEditor } from '../../../Components/EditorComponent/TextEditor/TextEditor';
import {
    changeTitleOnlineLecture,
    changeOnlineLectureId,
    changeAuthorOnlineLecture,
    changeContent,
    changePreview,
    changeCategoryOnlineLecture
} from "../../../store/reducer/mange-online-lectures/detail-online-lecture";
import { toast } from "react-toastify";

const MAX_TITLE_LENGTH = 128;
const MAX_AUTHOR_LENGTH = 150;
const MAX_CATEGORY_LENGTH = 50;

const Title = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_TITLE_LENGTH) return;

        dispatch(changeTitleOnlineLecture(value));
    }

    return (
        <Input placeholder={"Заголовок"} type='text' onChange={onChange}/>
    )
}

const Author = () => {

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_AUTHOR_LENGTH) return;

        dispatch(changeAuthorOnlineLecture(value));
    }

    return <Input placeholder={"Автор"} type='text' onChange={onChange}/>
}

const Category = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_CATEGORY_LENGTH) return;

        dispatch(changeCategoryOnlineLecture(value));
    }
    
    return <Input placeholder={"Категория"} type='text' onChange={onChange}/>
}

const AddOnlineLecture = () => {
    
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture);
    const ref = useRef({});
    const navigate = useNavigate();
    
    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);
    
    const onChangeFiles = (files) => {
        if (files.length > 0) {
            setPreviewFile(files[0]);
            setFiles([files[0]]);
        }
    }
   
    const onAddOnlineLecture = async() => {
        const responseUpload = await uploadFiles({files: [previewFile]});
        const preview = responseUpload?.data?.names[0] || "";
        const content = await ref.current.save();
        
        const payload = {
            title: detailOnlineLecture.title,
            author: detailOnlineLecture.author,
            category: detailOnlineLecture.category,
            preview_image_link: getStaticUrlFile(preview),
            content: content
        }
        if(payload.title === "" || payload.author === "" || payload.category === "" ){
            toast.warn('Проверьте, все ли поля заполнены', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return; 
        }
        
        const response = await createOnlineLecture(payload);
        if( response.success ){
            toast.success('Онлайн лекция успешно добавлена', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
            
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }
   

    return (
        
        <Container>
            <div className="w-100 d-flex justify-content-between mb-4">
                <h3>Добавить онлайн лекторий</h3>
                <Button className="btn" onClick={() => navigate('/editorial/manage-online-lectures')}>Назад</Button>
            </div>
            <FormikProvider>
                <form>
                    <div className='d-flex w-100 flex-column gap-3 p-4'>

                        <div className='d-flex w-100 flex-column gap-3 '>
                            <Title/>
                            <Author/>
                            <Category/>
                            <div className='d-flex flex-column w-100'>
                                <p className='fw-bold'>Изображение для заголовка</p>
                                <DropZone files={files} onChangeFiles={onChangeFiles}/>
                            </div>
                            <div className="d-flex w-100 flex-column mt-4 border rounded" styles={{backGround:"#000"}}>
                                <TextEditor setRef={setRef} />
                            </div>
                        </div>

                        <div className='d-flex w-100 gap-1'>
                            <Button className='btn btn-primary'  onClick={onAddOnlineLecture}>Создать</Button>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </Container>
    );
};

export default AddOnlineLecture;