import React, {useState} from 'react';
import classNames from "classnames";
import { useLocation, Link } from "react-router-dom"
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import SettingsBg from "../../assets/images/settings-background.svg"
const MenuSetting = ({pages}) => {

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const classes = classNames("MenuSetting", {
        [`MenuSetting_open`]: isOpen,
    })

    const onToggleMenu = () => setIsOpen(!isOpen);

    return (
        <div className={classes}>

            <HamburgerButton className={"MenuSetting_hamburger"} onClick={onToggleMenu}/>

            <ul className={"MenuSetting_links"} onClick={onToggleMenu}>
                {pages.map((page, idx) => {
                    const classes = classNames("MenuSetting_link", {
                        [`MenuSetting_link_active`]: location.pathname == page.path,
                    })
                    return (
                        <li className={classes} key={idx}>
                            <Link to={page.path} className='d-flex'>
                                    {page.icon}
                                    <p className='fs-10 align-items-center fw-bold  text-middle mb-0'>{page.title}</p>
                            </Link>
                        </li>
                    )
                })}
            </ul>

            <div className={"MenuSetting_background"}>
                <img
                    alt="Anonymous form"
                    src={SettingsBg}
                    layout="responsive"
                    width={283}
                    height={236}
                    objectFit="fill"
                    quality={100}
                    />
            </div>

        </div>
    );
};

export default React.memo(MenuSetting);
