import React from "react";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Container, Row, Col, Flex } from "reactstrap";
import CardComponent from "./FeatureCard";
import LottiePlayer from "../player";
import Bg from "../../assets/lottie/feature.json";
import Bachelor from "../../assets/icons/Programs/bachelor.svg";
import Master from "../../assets/icons/Programs/master.svg";
import Graduated from "../../assets/icons/Programs/graduated.svg";
import ATP from "../../assets/icons/Programs/atp.svg"
function Feature() {
  const cardData = [
    {
      title: "Бакалавриат",
      description:
        "",
      backgroundColor: "#ffb6b6",
      icon: Bachelor,
      link: "/education#bachelor"
    },
    {
      title: "Магистратура",
      description:
        "",
      backgroundColor: "#ffefb6",
      icon: Master,
      link: "/education#master"
    },
    {
      title: "Аспирантура",
      description:
        "",
      backgroundColor: "#b6dcff",
      icon: Graduated,
      link: "/education#graduated"
    },
    {
      title: "Дополнительные профессиональные программы",
      description:
        "",
      backgroundColor: "#c0ffb6",
      icon: ATP,
      link: "/education#ATP"
    },
  ];

  return (
    <div>
      <section className="px-lg-7 px-2 pb-0 pt-0">
        <div className="bg-light py-10 px-3 px-lg-8 rounded-4 position-relative overflow-hidden">
          <div className="position-absolute animation-3">
            <LottiePlayer src={Bg} />
          </div>
          <Container className="z-index-1">
            <Row className="align-items-end justify-content-between mb-6">
              <Col xs={12} lg={6} xl={5}>
                <div>
                  <h2>Будущий учитель - Учитель будущего</h2>
                </div>
              </Col>
              <Col xs={12} lg={6} xl={5} className="ms-auto mt-5 mt-lg-0">
                <p className="lead">
                  Мы предоставляем большой выбор программ обучения по разным направлениям подготовки
                </p>
              </Col>
            </Row>
            <div 
              className="d-flex flex-wrap justify-content-center gap-5"
              style={{
                marginTop: "2.5rem",
                "@media screen and (minWidth: 700px)": { marginTop: 0 },
              }}
            >
              {cardData.map((data, index) => (
                  <Zoom>
                    <CardComponent
                      key={index}
                      title={data.title}
                      description={data.description}
                      backgroundColor={data.backgroundColor}
                      icon={data.icon}
                      link={data.link}
                    />
                  </Zoom>
              ))}
            </div>
          </Container>
          <div className="position-absolute animation-2">
            <LottiePlayer src={Bg} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Feature;
