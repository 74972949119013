import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import MockBackgroundImage from "../../../assets/images/bg/background-mock.png";

const initialState = {
    preview_image_link: MockBackgroundImage,
    page_link: null,
    order: -1
}
  
const detaiSliderlSlice = createSlice({
    name: "detaiSlider",
    initialState,
    reducers: {
        changeImage(state, action) {
            state.preview_image_link = action.payload;
        },
        changeLink(state, action) {
            state.page_link = action.payload;
        },
        changeOrder(state, action) {
            state.order = action.payload;
        },
        
    },
    
});

export const { 
    changeImage,
    changeLink,
    changeOrder
 } = detaiSliderlSlice.actions;

export default detaiSliderlSlice.reducer;
