import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getOnlineLectures } from "../../services/OnlineLecturesService";


const initialState = {
  onlineLectures: [],
  selectedOnlineLecture: ''
}

export const loadOnlineLecturesAsync = createAsyncThunk (
  "loadOnlineLecturesAsync",
  async() => {
      const response = await getOnlineLectures();
      if (response.success) return response.data;
      else return undefined;
  }
)

const onlineLecturesSlice = createSlice({
  name: "onlineLectures",
  initialState,
  reducers: {
    setSelectedLecture(state, action) {
      state.selectedOnlineLecture = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadOnlineLecturesAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.onlineLectures = action.payload || []
        }
    })
  }  
});

export const { setSelectedLecture } = onlineLecturesSlice.actions;

export default onlineLecturesSlice.reducer;
