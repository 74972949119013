import Api from "../../Api";
import axios from "axios";

export const initTeacherRegistration = async (teacher) => {
    const data = {
        name: teacher.name,
        surname: teacher.surname,
        email: teacher.email,
        region: teacher.region,
        city: teacher.city,
        school: teacher.institution,
        position: teacher.position,
        phone_number: teacher.phoneNumber,
        password: teacher.password,
        confirm_password: teacher.confirmPassword,
    }
    let response = null;
    try {
        response = await axios.post(Api.endpoints.TEACHER_SIGNUP, data);
    } catch (e) {
        return {
            status: e.response?.status || 0,
            token: "",
            errors: [],
            email: e.response?.data.email,
            phoneNumber: e.response?.data.phone_number,
            name: e.response?.data.name,
            surname: e.response?.data.surname,
            region: e.response?.data.region,
            city: e.response?.data.city,
            school: e.response?.data.institution,
            position: e.response?.data.position,

        }
    }

    return {
        status: response.status,
        token: response.data.access_token,
        errors: Api.getErrors(response),
    }
}
