import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import CustomInput from '../../CustomInput/CustomInput';
import { Button } from 'reactstrap'
import { useContinueEmailChangeMutation, useInitEmailChangeMutation } from '../../../api/settings/settingsApi';
import { useEmailContext } from '../../../context/ChangeEmailContext';
const TIMER_TIMEOUT = 60;
const MAX_CODE_VALUE = 999999;
const MIN_CODE_VALUE = 100000;

const ConfirmationEmail = () => {

    const {
        newEmail,
        setEmailConfirmCode,
        token,
        setToken,
    } = useEmailContext();

    const [continueEmailChange] = useContinueEmailChangeMutation();
    const [initEmailChange] = useInitEmailChangeMutation();
    const [errorMessage, setErrorMessage] = useState("");
    const [localToken, setLocalToken] = useState(token);
    const [showTimer, setShowTimer] = useState(false);

    const [code, setCode] = useState("");

    const onChangeCode = (e) => setCode(e.currentTarget.value);

    const repeatSendCodeEmailHandler = useCallback(() => {
        initEmailChange({
            email: newEmail,
        }).unwrap().then((response) => {
            setLocalToken(response.token);
            setShowTimer(() => true);
            setTimeout(() => setShowTimer(() => false), TIMER_TIMEOUT * 1000);
        })
    }, [setShowTimer, setLocalToken, initEmailChange, newEmail]);

    const onContinueHandler = (e) => {
        e.preventDefault();

        if (Number.parseInt(code) > MAX_CODE_VALUE || Number.parseInt(code) < MIN_CODE_VALUE) {
            setErrorMessage("Введите правильный код");
            return;
        }

        setErrorMessage("");
        continueEmailChange({
            email_code: Number.parseInt(code),
            unique_token: localToken,
            new_email: newEmail,
        }).unwrap()
            .then((response) => {
                setToken(response.token);
                setEmailConfirmCode(code);
            })
            .catch((error) => {
                setErrorMessage(error?.data?.detail || "");
            })
    }

    return (
        <div className='d-flex flex-column gap-3' as={"form"} onSubmit={onContinueHandler}>
            <CustomInput name={"email"} state={"success"} value={newEmail} onChange={() => ({})} placeholder={"Новый адрес Эл. Почты"}/>

            <div className='d-flex flex-column gap-2 w-100'>
                <CustomInput
                    value={code}
                    onChange={onChangeCode}
                    touched={Boolean(errorMessage)}
                    error={errorMessage}
                    type={"number"}
                    min={1}
                    max={999999}
                    name={"code"}
                    placeholder={"Mail confirmation code"}/>

                <p>На ваш предыдущий адрес электронной почты был отправлен код подтверждения</p>
                <Button
                    disabled={showTimer}
                    onClick={repeatSendCodeEmailHandler}
                    className='btn-link'
                >
                    Отправить код заного  
                </Button>

                {showTimer && <Timer/>}
            </div>

            <Button type={"submit"}> Продолжить </Button>
        </div>
    );
};

const Timer = () => {
    const [timer, setTimer] = useState(TIMER_TIMEOUT);
    const [intervalId, setIntervalId] = useState();

    useEffect(() => {
        const id = setInterval(() => {
            setTimer((timer) => timer - 1);
            if (timer < 1) clearInterval(intervalId);
        }, 1000);
        setIntervalId(id);
    }, []);

    return timer > 0 ? <p className='fw-light'>{timer}</p> : null;
}

export default React.memo(ConfirmationEmail);
