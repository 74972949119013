import axios, {AxiosRequestConfig} from "axios";
import Api from "../api/Api";

export const uploadFiles = async ({files}) => {
    try {
        const formData = new FormData();
        files.forEach((file) => formData.append("files", file))

        const config = {
            url: Api.endpoints.UPLOAD_FILES,
            method: "POST",
            data: formData,
            headers: {"Content-Type": "multipart/form-data"},
        }

        const response = await axios(config);
        return {
            success: response.status === 200,
            data: response.data,
        }
    } catch {
        return {
            success: false,
        }
    }
}