import Api from "../../Api";
import axios from "axios";

export const initParentRegistration = async (parent) => {

    const data = {
        name: parent.name,
        surname: parent.surname,
        email: parent.email,
        region: parent.region,
        city: parent.city,
        institution: parent.institution,
        study_year: parent.study_year,
        phone_number: parent.phone_number,
        password: parent.password,
        confirm_password: parent.confirm_password,
    }


    let response = null;
    try {
        response = await axios.post(Api.endpoints.PARENT_SIGNUP, data);
    } catch (e) {
        return {
            status: e.response?.status || 0,
            token: "",
            errors: [],
            email: e.response?.data.email,
            phoneNumber: e.response?.data.phone_number,
            name: e.response?.data.name,
            surname: e.response?.data.surname,
            region: e.response?.data.region,
            city: e.response?.data.city,
            university: e.response?.data.university,
            study_year: e.response?.data.study_year,

        }
    }

    return {
        status: response.status,
        token: response.data.access_token,
        errors: Api.getErrors(response),
    }
}
