import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Components/header/Header";
import Index from "./Pages/index";
import Footer from "./Components/footer/Footer";
import Teachers from "./Pages/teachers-page/TeachersPage";
import FaqPage from "./Pages/faq-page/FaqPage";
import TeacherSingle from "./Pages/teachers-single/TeacherSingle";
import SignIn from "./Pages/sign-in/SignIn";
import SignUp from "./Pages/sign-up/SignUp";
import ProductGrid from "./Pages/product-grid/ProductGrid";
import ProductSingle from "./Pages/product-single/ProductSingle";
import ProductCart from "./Pages/cart/ProductCart";
import ForgetPassword from "./Pages/forget-password/ForgetPassword";
import BackToTop from "./Components/BackToTop";
import Contact from "./Pages/contact/Contatct";
import ContatcUs2 from "./Pages/contact/ContatcUs2";
import ErrorPage from "./Pages/error-page/ErrorPage";
import TAndC from "./Pages/term-And-Condition/tAndC";
import Privacy from "./Pages/term-And-Condition/privacy";
import OnlineLectures from "./Pages/online-lectures/OnlineLectures";
import Lecture from "./Pages/online-lectures/Lecture";
import InteractiveLessons from "./Pages/interactive-lessons/InteractiveLessons";
import Education from "./Pages/education/Education";
import AdminPage from "./Pages/AdminPage/AdminPage";
import AddSection from "./Pages/AdminPage/PedagogicalSearch/AddSection";
import AddSubsection from "./Pages/AdminPage/PedagogicalSearch/AddSubsection";
import AddArticle from "./Pages/AdminPage/PedagogicalSearch/AddArticle";
import Sections from "./Pages/PedagogicalSearchPage/Sections";
import Subsections from "./Pages/PedagogicalSearchPage/Subsections";
import Articles from "./Pages/PedagogicalSearchPage/Articles";
import ArticleDetail from "./Pages/PedagogicalSearchPage/ArticleDetail";
import ManageArticles from "./Pages/AdminPage/PedagogicalSearch/ManageArticles";
import ManageSections from "./Pages/AdminPage/PedagogicalSearch/ManageSections";
import ManageSubsections from "./Pages/AdminPage/PedagogicalSearch/ManageSubsections";
import InfoArticleEdit from "./Components/Editorial/InfoArticleEdit";
import EditSliderImages from "./Pages/AdminPage/EditSliderImages/EditSliderImages"
import ManageTeachersInHistory from "./Pages/AdminPage/TeachersInHistory/ManageTeachersInHistory";
import AddTeacherInHistory from "./Pages/AdminPage/TeachersInHistory/AddTeacherInHistory";
import EditTeacherInHistory from "./Pages/AdminPage/TeachersInHistory/EditTeacherInHistory";
import ManageOnlineLectures from "./Pages/AdminPage/OnlineLectures/ManageOnlineLectures";
import AddOnlineLecture from "./Pages/AdminPage/OnlineLectures/AddOnlineLecture";
import EditOnlineLecture from "./Pages/AdminPage/OnlineLectures/EditOnlineLecture";
import ManageProducts from "./Pages/AdminPage/Products/ManageProducts";
import AddProduct from "./Pages/AdminPage/Products/AddProduct";
import EditProduct from "./Pages/AdminPage/Products/EditProduct";
import Account from "./Pages/Account/Account";
import RequireAuth from "./Components/RequireAuth/RequireAuth";
import IsAuthenticated from "./Components/IsAuthenticated/IsAuthenticated";
import { AuthProvider } from "./context/auth";
import Profile from "./Components/SettingsComponents/Profile";
import Email from "./Components/SettingsComponents/Email/Email";
import Materials from "./Components/SettingsComponents/Materials";
import ManageInteractiveLessons from "./Pages/AdminPage/interactiveLessons/ManageInteractiveLessons";
import AddInteractiveLesson from "./Pages/AdminPage/interactiveLessons/AddInteractiveLesson";
import ManageInteractiveLessonsRequests from "./Pages/AdminPage/interactiveLessonsRequests/ManageInteractiveLessonsRequest";
import InteractiveLessonsRequests from "./Components/SettingsComponents/InteractiveLessonsRequests";
import EditInteractiveLesson from "./Pages/AdminPage/interactiveLessons/EditInteractiveLesson";
import ParentsAndChildrens from "./Pages/ParentsAndChildrens/ParentsAndChildrens";
import PsychologicalConsult from "./Pages/PsychologicalConsult/PsychologicalConsult";
import ManageEducationPrograms from "./Pages/AdminPage/EducationPrograms/ManageEducationProgram";
import AddEducationProgram from "./Pages/AdminPage/EducationPrograms/AddEducationProgram";
import EditEducationProgram from "./Pages/AdminPage/EducationPrograms/EditEducationProgram";
import ManagePsychologicalConsult from "./Pages/AdminPage/PsychologicalConsult/ManagePsychologicalConsult";
import UserPsychologicalConsult from "./Components/SettingsComponents/UserPsychologicalConsult";
import CheckTransactionStatus from "./Pages/Transaction/CheckTransactionStatus";

const App = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 50); // Change the delay as needed

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const isSpecialRoute =
    location.pathname.includes("maintenance") ||
    location.pathname.includes("coming-soon") ||
    location.pathname.includes("error-404");

  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload(); // Refresh the page on popstate event (back button)
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [location.pathname]);
  return (
    <AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isLoading ? (
        <div id="ht-preloader">
          <div className="loader clear-loader">
            <img src="images/loader.gif" alt="" />
          </div>
        </div>
      ) : (
        // Rest of your JSX code
        <>
            <div className="page-wrapper">
              <Header />
              <Routes>
                <Route path="/" element={<Index />} />
                
                <Route path="/account" element={
                  <RequireAuth>
                    <Account />
                  </RequireAuth>
                }> 
                  <Route path="settings" element={<Profile />} />
                  <Route path="email" element={<Email />} />
                  <Route path="materials" element={<Materials/>}/>
                  <Route path="interactive-lessons-requests" element={<InteractiveLessonsRequests/>}/>
                  <Route path="psychological-consult" element={<UserPsychologicalConsult/>}/>
                                    
                </Route>
                <Route path="/check-transaction-status" element={
                  <RequireAuth>
                    <CheckTransactionStatus />
                  </RequireAuth>
                }/> 
                <Route path="/teachers-in-history" element={<Teachers />}>
                  <Route path=":teacherInHistoryId" element={<TeacherSingle />} />
                </Route>
                
                <Route path="/online-lectures" element={<OnlineLectures/>}>
                  <Route path=":lectureId" element={<Lecture/>}/>
                </Route>
                

                 
                <Route path="/sections" element={<Sections/>}>
                  <Route path=":sectionId" element={<Subsections/>}>
                    <Route path=":subsectionId" element={<Articles/>}>
                        <Route path=":articleId" element={<ArticleDetail/>}/>
                    </Route>
                  </Route>
                </Route>
                
                <Route path="/parents-and-childrens" element={<ParentsAndChildrens/>}>
                    <Route path=":PaCsectionId" element={<Articles/>}>
                        <Route path=":articleId" element={<ArticleDetail/>}/>
                    </Route>
                    <Route path="psychological-consult" element={<PsychologicalConsult/>}/>
                </Route>
                
                <Route path="/products" element={<ProductGrid />} >
                  <Route path=":productId" element={<ProductSingle />} />
                </Route>
                
                <Route path="/faq" element={<FaqPage />} />
                
                <Route path="/login" element={
                  <IsAuthenticated>
                    <SignIn />
                  </IsAuthenticated>
                } />
                
                <Route path="/signup" element={
                  <IsAuthenticated>
                    <SignUp />
                  </IsAuthenticated>
                } />
                                
                <Route path="/product-single" element={<ProductSingle />} />
                <Route path="/product-cart" element={<ProductCart />} />
                <Route path="/forgot-password" element={<ForgetPassword />} />
                
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/contact-us-2" element={<ContatcUs2 />} />
                <Route path="/terms-and-conditions" element={<TAndC />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/interactive-lessons" element={<InteractiveLessons/>}/>
                <Route path="/education" element={<Education/>}/>
                
                <Route path="/editorial" element={
                  <RequireAuth>
                    <AdminPage/>
                  </RequireAuth>}
                >
                  <Route path="edit-slider-images" element={<EditSliderImages/>}/>
                  <Route path="manage-sections" element={<ManageSections/>}>
                    <Route path="add-sections" element={<AddSection/>}/>
                    <Route path=":sectionId" element={<InfoArticleEdit/>}/>
                  </Route>
                  <Route path="manage-subsections" element={<ManageSubsections/>}>
                    <Route path="add-subsections" element={<AddSubsection/>}/>
                    <Route path=":subsectionId" element={<InfoArticleEdit/>}/>
                  </Route>
                  <Route path="manage-articles" element={<ManageArticles/>}>
                    <Route path="add-articles" element={<AddArticle/>}/>
                    <Route path=":articleId" element={<InfoArticleEdit/>}/>
                  </Route>
                  <Route path="manage-teachers-in-history" element={<ManageTeachersInHistory/>}>
                    <Route path="add-teacher-in-history" element={<AddTeacherInHistory/>}/>
                    <Route path=":teachersInHistoryId" element={<EditTeacherInHistory/>}/>
                  </Route>
                  <Route path="manage-online-lectures" element={<ManageOnlineLectures/>}>
                    <Route path="add-online-lecture" element={<AddOnlineLecture/>}/>
                    <Route path=":lectureId" element={<EditOnlineLecture/>}/>
                  </Route>
                  <Route path="manage-interactive-lessons" element={<ManageInteractiveLessons/>}>
                    <Route path="add-interactive-lesson" element={<AddInteractiveLesson/>}/>
                    <Route path=":lessonId" element={<EditInteractiveLesson/>}/>
                  </Route>
                  <Route path="manage-interactive-lessons-requests" element={<ManageInteractiveLessonsRequests/>}/>
                  <Route path="manage-psychological-consult" element={<ManagePsychologicalConsult/>}/>
                  <Route path="manage-products" element={<ManageProducts/>}>
                    <Route path="add-product" element={<AddProduct/>}/>
                    <Route path=":productId" element={<EditProduct/>}/>
                  </Route>
                  <Route path="manage-education-programs" element={<ManageEducationPrograms/>}>
                    <Route path="add-education-program" element={<AddEducationProgram/>}/>
                    <Route path=":programId" element={<EditEducationProgram/>}/>
                  </Route>  
                </Route>
                {!location.pathname.includes("download-file") && <Route path="*" element={<ErrorPage />}/>}
                
                
                
              </Routes>
              {!location.pathname.match(/(\/editorial|\/add-|\/manage-|\/edit-)/) && <Footer />}
              <BackToTop />
            </div>
        </>
      )}
    </AuthProvider>
  );
}

export default App;
