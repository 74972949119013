import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadPsychologicalConsultAsync } from "../../../store/reducer/psychologicalConsultReducer";
import PsychologicalConsultItem from "../../../Components/PsychologicalConsult/PsychologicalConsultItem";

const ManagePsychologicalConsult = () => {
    const dispatch = useDispatch();
    const psychologicalConsult = useSelector((state) => state.psychologicalConsult);
    let location = useLocation();
    useEffect(() => {
        dispatch(loadPsychologicalConsultAsync())
    }, [location.pathname])
  
    return(
        <div className="d-flex flex-wrap p-10 gap-3 justify-content-center">
            {
                psychologicalConsult.psychologicalConsult.length != 0 && psychologicalConsult.psychologicalConsult.map((consultRequest  , index) => (
                    <PsychologicalConsultItem consultRequest={consultRequest} key={index} onEditorial/>
                ))
            }
        </div>
        
    )
}

export default ManagePsychologicalConsult;