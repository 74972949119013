import {axiosClient} from "../common/axios";
import Api from "../api/Api";

export const createExploration = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.CREATE_EXPLORATION, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const changeExplorationInfo = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.CHANGE_EXPLORATION_INFO, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const changeExplorationContent = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.CHANGE_EXPLORATION_CONTENT, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const getExplorations = async () => {
    
    try {
        const response = await axiosClient.get(Api.endpoints.GET_EXPLORATIONS)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const getExplorationDetail = async ({exploration_id}) => {
    try {
        const url = Api.endpoints.GET_EXPLORATION_DETAIL.replace('{exploration_id}', exploration_id);

        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const deleteExploration = async ({exploration_id}) => {
    try {
        const url = Api.endpoints.DELETE_EXPLORATION.replace('{exploration_id}', exploration_id);
        
        const response = await axiosClient.post(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}