import {axiosClient} from "../common/axios";
import Api from "../api/Api"

export const addPsychologicalConsult = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.ADD_PSYCHOLOGICAL_CONSULT, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}
export const getPsychologicalConsults = async () => {
    try {
        const response = await axiosClient.get(Api.endpoints.GET_PSYCHOLOGICAL_CONSULTS)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const updatePsychologicalConsultStatus = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.UPDATE_PSYCHOLOGICAL_CONSULT_STATUS, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }

}

export const getUserPsychologicalConsults = async ({user_id}) => {
    try {
        const url = Api.endpoints.GET_USER_PSYCHOLOGICAL_CONSULTS.replace('{user_id}', user_id.user_id);
        
        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}