import Api from "../../api/Api";
import {uploadFiles} from "../../services/uploadFiles";
import {getStaticUrlFile} from "../../common/helpers";

export const STANDING_PLUGINS = (() => ({
    header: require("@editorjs/header"), 
}));
export const EDITOR_JS_TOOLS = (() => ({
    embed: require('@editorjs/embed'),
    list: require("@editorjs/list"),
    attaches: {
        class: require("@editorjs/attaches"),
        config: {
            uploader: {
                uploadByFile: async (file) => {
                    
                    const responseUpload = await uploadFiles({files: [file]});
                    const preview = responseUpload?.data?.names[0] || "";
                    return {
                        success:1,
                        file: {
                            url:getStaticUrlFile(preview) 
                        }
                    }
                },
            }
        }
    },
    image: {
        class: require('@editorjs/image'),
        config: {
            uploader: {
                uploadByFile: async (file) => {
                    
                    const responseUpload = await uploadFiles({files: [file]});
                    const preview = responseUpload?.data?.names[0] || "";
                    return {
                        success:1,
                        file: {
                            url:getStaticUrlFile(preview) 
                        }
                    }
                },
                uploadByUrl: (url) => {
                    return {
                        success:1,
                        file: {
                            url: url
                        }
                    }
                },
            },
        },
    },
    marker: require('@editorjs/marker'),
    checklist: require('@editorjs/checklist'),
    delimiter: require('@editorjs/delimiter'), 
    video: {
        class: require('@weekwood/editorjs-video'),
        config: {
            uploader: {
                uploadByFile: async (file) => {
                    
                    const responseUpload = await uploadFiles({files: [file]});
                    const preview = responseUpload?.data?.names[0] || "";
                    return {
                        success:1,
                        file: {
                            url:getStaticUrlFile(preview) 
                        }
                    }
                },
                uploadByUrl: async (url) => {
                    return {
                        success:1,
                        file: {
                            url: url
                        }
                    }
                },
            },
            player: {
                controls: true,
                autoplay: false
            }
        },
    }, 
}));

export const WidthType = {
    DEFAULT: "default",
    UNSET: "unset" 
};

export const Plugins = {
    EMBED: "embed",
    ATTACHES: "attaches",
    LIST: "list",
    IMAGE: "image",
    HEADER: "header",
    MARKED: "marker",
    CHECK_LIST: "checklist",
    DELIMITER: "delimiter",  
    VIDEO: "video"
};
