import React from "react";
import {
    Row,
    Col,
    CardBody,
    CardTitle,
  } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedProduct } from "../../store/reducer/productReducer";
import { useCart, useWishlist } from "../../hooks/hooks";

const ProductCardHorizontal = ({item}) => {
  const dispatch = useDispatch();
  const { addItemToCart } = useCart();
  const { addToWishlist}  = useWishlist();

  return (
      <div className=" shadow p-4 rounded-4 mb-5">
                <Row className="align-items-center">
                  <Col lg={4} md={5}>
                    <div>
                      <Link to={`${item.id}`}>
                        <img
                          className="img-fluid rounded-4"
                          src={item.preview_image_link}
                          alt=""
                        />
                      </Link>
                    </div>
                  </Col>
                  <Col lg={8} md={7}>
                    <CardBody className="d-flex flex-column align-items-start">
                      <CardTitle>
                        <Link
                          to={`${item.id}`}
                          onClick={() => {
                            dispatch(setSelectedProduct(item.id));
                          }}
                          className=" mb-2 d-block link-title h6"
                        >
                          {item.title}
                        </Link>
                      </CardTitle>
                      <span className="product-price text-dark">
                      {item.price_with_discount ? <>
                                                      <span style={{ marginRight: "5px", fontSize:"24px" }}>
                                                        {item.price_with_discount} ₽
                                                      </span>
                                                      <del className="text-muted mr-2">
                                                        {item.price} ₽
                                                      </del>
                                                    </>
                                                  :<span style={{ marginRight: "5px", fontSize:"24px" }}>
                                                    {item.price} ₽
                                                  </span>
                      }
                      </span>
                      <p className="my-3">{item.short_description}</p>
                      <div className="product-link mt-3">
                        <Link
                          style={{ marginRight: "3px" }}
                          className="add-cart"
                        
                          onClick={() => {
                            addItemToCart(item);
                          }}
                        >
                          <i className="bi bi-bag-check me-2"></i>Добавить в корзину
                        </Link>
                        <Link 
                          className="wishlist-btn add-cart" 
                          
                          onClick={() => {
                            addToWishlist(item);
                          }}
                        >
                          <i className="bi bi-bag-heart"></i>Добавить в желаемое
                        </Link>
                      </div>
                    </CardBody>
                  </Col>
                </Row>
              </div>
  )
}

export default ProductCardHorizontal;