import React, {useState, useEffect} from "react";
import SectionsList from "../../../Components/PedagogicalSearch/SectionsList";
import SubsectionsList from "../../../Components/PedagogicalSearch/SubsectionsList";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useLocation, Outlet} from 'react-router-dom';
import {Container, Button} from 'reactstrap';
import PSAdminItems from "../../../Components/PedagogicalSearch/PSAdminItems";
import ConfirmDeleteModal from "../../../Components/Editorial/ConfirmDeleteModal"

const ManageArticles = () => {
    const [showSectionList, setShowSectionList] = useState(true);
    const [showSubsectionList, setShowSubsectionList] = useState(false);
    const articles = useSelector((state) => state.pedagogicalSearch);
    const navigate = useNavigate();
    let location = useLocation();
    
    const onCreateClick = () =>{
        navigate("add-articles")
    }
    return (
       
        <Container className="d-flex gap-3 flex-column px-10 mt-10">
            {location.pathname === "/editorial/manage-articles" ?
             <>          
                {!showSectionList && !showSubsectionList &&
                                        <>
                                        <div className="w-100 d-flex justify-content-between mb-4">
                                            <h3>Статьи</h3>
                                            <Button className="btn btn-primary" onClick={onCreateClick}>Создать</Button>
                                        </div>
                                        {articles.articles.map((item, index) => (
                                            <PSAdminItems item={item} index={index}/>
                                            ))}
                                        <ConfirmDeleteModal item={articles.selectedItem}/>
                                        </>
                }
                {showSectionList && <SectionsList 
                                        setShowSectionList={setShowSectionList} 
                                        setShowSubsectionList={setShowSubsectionList}
                                        manageArticles={true}
                                    />
                }  
                {showSubsectionList && <SubsectionsList setShowSubsectionList={setShowSubsectionList}/>}  
            </> 
                : <Outlet/>
            }
        </Container>
         
    )
}

export default ManageArticles;