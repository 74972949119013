import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useNavigate} from "react-router-dom"
import { loadUserInteractiveLessonsRequestsAsync } from "../../store/reducer/interactiveLessonsRequestsReducer";
import InteractiveLessonsRequestItem from "../../Components/interactive-lessons/InteractiveLessonsRequestItem";


const InteractiveLessonsRequests = () => {
    const interactiveLessonsRequests = useSelector((state) => state.interactiveLessonsRequests);
    const profile = useSelector((state) => state.userInfo.profile);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadUserInteractiveLessonsRequestsAsync({user_id: profile.userId}))
    }, [profile])
    return (
        <div className="d-flex flex-column w-100">
            <h5 className='fw-bold mt-6 mb-3' >Интерактивные уроки</h5>
            <div className='d-flex flex-column gap-2 mx-0 my-5 border rounded p-4 user-ownership-wrapper align-items-center'>
                {
                    interactiveLessonsRequests.interactiveLessonsRequests.length != 0 && interactiveLessonsRequests.interactiveLessonsRequests.map((lessonRequest, index) => (
                        <InteractiveLessonsRequestItem lessonRequest={lessonRequest} key={index} onEditorial={false}/>
                    ))
                }
                
            </div>
        </div>
    )
}

export default InteractiveLessonsRequests;