import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { getTeachersInHistory } from "../../services/TeachersInHistory";

const initialState = {
  teachersInHistory: [],
  selectedTeacher: ''
}

export const loadTeachersInHistoryAsync = createAsyncThunk (
  "loadTeachersInHistoryAsync",
  async() => {
      const response = await getTeachersInHistory();
      if (response.success) return response.data;
      else return undefined;
  }
)

const teachersInHistorySlice = createSlice({
  name: "teachersInHistory",
  initialState,
  reducers: {
    setSelectedTeacher(state, action) {
      state.selectedTeacher = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadTeachersInHistoryAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.teachersInHistory = action.payload || []
        }
    })
  }  
});

export const { setSelectedTeacher } = teachersInHistorySlice.actions;

export default teachersInHistorySlice.reducer;
