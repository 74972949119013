import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardImg, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { setSelectedItem } from "../../store/reducer/pedagogicalSearchReducer";
import { useDispatch } from "react-redux";


const PSAdminItems = ({item, index}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onItemClick = (editItem) => {
        navigate(`${editItem.id}`)
    }
    const handleDeleteClick = () => {
        dispatch(setSelectedItem(item))
      }
    return (
        <>
            <Card key={index} className="d-flex flex-row p-4 search-card ">
                <div className="d-flex justify-content-between">
                {item.preview_image_link && <CardImg
                                                className="rounded-4 object-fit-cover"
                                                width="156px"
                                                height="156px"    
                                                style={{
                                                    height: 156,
                                                    width: 156
                                                    }}
                                                    src={item.preview_image_link} 
                                            />
                }
                </div>
                
                <CardBody>
                    <CardTitle className="d-flex justify-content-between align-items-start">
                        <div>
                            {item.title}
                        </div>
                        <div>
                            <Button  className="btn btn-link text-primary-light p-0 mx-3" onClick={() => onItemClick(item)}>Редактировать</Button>
                            <Button className="btn btn-link text-danger p-0" data-bs-toggle="modal" data-bs-target="#confirmDelete" onClick={handleDeleteClick}>Удалить</Button>
                        </div>
                    </CardTitle>
                    <CardText>{item.description}</CardText>
                </CardBody>
            </Card>
        </>
            
    )
}

export default PSAdminItems;