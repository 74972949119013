import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import BgImage from "../../assets/images/bg/01.png";
import { useAuth } from "../../context/auth";

function RegisterBanner() {
  const { isAuthenticated } = useAuth();
  return (
    <>
      {!isAuthenticated && <Container>
        <Row className="row mt-4">
          <Col className="col-12">
            <div
              className="bg-dark p-4 p-lg-10 rounded-4 text-center"
              style={{
                
                background: "rgb(255,153,153)",
                background: "linear-gradient(90deg, rgba(255,153,153,1) 0%, rgba(255,153,51,1) 100%)"
              }}
            >
              <h3 className="text-white mb-0 font-w-7">
                Инновационная платформа "Учитель будущего"!
              </h3>
              <p className="text-light my-4">
                Станьте частью профессионального педагогического сообщества и повысьте свою квалификацию
              </p>

              <a href="/signup" className="btn btn-pink">
                Зарегистрироваться
              </a>
            </div>
          </Col>
        </Row>
      </Container>}
      
    </>
  );
}

export default RegisterBanner;
