import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom"
import { 
    Card, 
    CardBody, 
    CardTitle, 
    CardText,
    Col,
    Button 
  } from "reactstrap";
import { setSelectedInteractiveLesson } from "../../store/reducer/interactiveLessonsReducer";
import { useDispatch } from "react-redux";  
import { setLessonLink } from "../../store/reducer/manage-interactive-lessons/interactive-lesson-form";
import { useIsMobile } from "../../hooks/hooks";
const InteractiveLessonsItem = ({interactiveLesson, toggle, onEditorial}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isMobile } = useIsMobile();
    const handleCardClick = (interactiveLesson) => {
        if(onEditorial) return
        toggle()
        dispatch(setLessonLink(interactiveLesson.name))
        dispatch(setSelectedInteractiveLesson(interactiveLesson));
      }
      const handleEditClick = (lesson) => {
        navigate(`${lesson.id}`)
       
      }
      const handleDeleteClick = () => {
        dispatch(setSelectedInteractiveLesson(interactiveLesson))
      }
    return (
    <Col>
      <Card className="h-100 lectures-card mb-5">
        <CardBody className="d-flex flex-column" onClick={() => {handleCardClick(interactiveLesson)}}>
        {onEditorial && 
            <div className="mb-2 d-flex justify-content-end gap-2">
              <Button className="btn btn-link text-primary-light p-0"  onClick={() => handleEditClick(interactiveLesson)}>Редактировать</Button>
              <Button className="btn btn-link text-danger p-0" data-bs-toggle="modal" data-bs-target="#confirmDelete" onClick={handleDeleteClick}>Удалить</Button>
            </div>
          }
          <div className= {`${isMobile ? "flex-column-reverse" : "justify-content-between"} d-flex `}>
            <div className="d-flex flex-column justify-content-between pe-3">
              <CardTitle tag="h5">
                {interactiveLesson.name}  
              </CardTitle>
              <CardText className="mb-4">
                {interactiveLesson.description}
              </CardText>
              <div className="text-muted">
              {interactiveLesson.category}
            </div> 
            </div>
            <img 
              className={`${isMobile ? "w-100" : ""} rounded object-fit-cover`}
              width="196"
              height="196"
              src={interactiveLesson.preview_image_link} 
              alt="" 
            />
          </div>
          
        </CardBody>
      </Card>
    </Col>
    )
}
export default InteractiveLessonsItem;