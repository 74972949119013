import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fullName:"",
    email:"",
    phone_number:"",
    lesson_date:"",
    message:"",
    lesson_link: "",
    status: ""
}

const interactiveLessonsFormSlice = createSlice({
  name: "interactiveLessonsForm",
  initialState,
  reducers: {
    setFullName(state, action) {
      state.fullName = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPhoneNumber(state, action) {
      state.phone_number = action.payload;
    },
    setLessonDate(state, action) {
      state.lesson_date = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setLessonLink(state, action) {
      state.lesson_link = action.payload;
    },
    
  },
});

export const { 
  setFullName,
  setEmail,
  setPhoneNumber,
  setLessonDate,
  setMessage,
  setLessonLink
} = interactiveLessonsFormSlice.actions;

export default interactiveLessonsFormSlice.reducer;
