import React, {useEffect} from "react";
import {Container, Button} from "reactstrap"
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useLocation, Outlet} from "react-router-dom";
import OnlineLecturesItem from "../../../Components/OnlineLectures/OnlineLecturesItem";
import { loadOnlineLecturesAsync } from "../../../store/reducer/onlineLecturesReducer";
import ConfirmDeleteModal from "../../../Components/Editorial/ConfirmDeleteModal";

const ManageOnlineLectures = () => {
    const onlineLectures = useSelector((state) => state.onlineLectures);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();
    
    useEffect(() => {
      dispatch(loadOnlineLecturesAsync())
    }, [location.pathname])
    
    const onCreateClick = () => {
        navigate("add-online-lecture")
    }
    
    return (
        
        <Container className="d-flex gap-3 flex-column px-10">
            {location.pathname === "/editorial/manage-online-lectures" ?
                                                                        <>
                                                                        <div className="w-100 d-flex justify-content-between mb-4">
                                                                            <h3>Онлайн лекторий</h3>
                                                                            <Button className="btn btn-primary" onClick={onCreateClick}>Создать</Button>
                                                                        </div>
                                                                        <div className="d-flex flex-wrap gap-3">
                                                                            {onlineLectures.onlineLectures.map((item, index) => (
                                                                                <OnlineLecturesItem key={index} lecture={item} onEditorial/>
                                                                            ))}
                                                                        </div>
                                                                        <ConfirmDeleteModal item={onlineLectures.selectedOnlineLecture}/>
                                                                        </> 
                                                                    : <Outlet/>
            }
        </Container>
      
    )
}

export default ManageOnlineLectures;