import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getInteractiveLessons } from "../../services/InteractiveLessonsService";

const initialState = {
  interactiveLessons: [],
  selectedInteractiveLesson: ''
}

export const loadInteractiveLessonsAsync = createAsyncThunk (
  "loadInteractiveLessonsAsync",
  async() => {
      const response = await getInteractiveLessons();
      if (response.success) return response.data;
      else return undefined;
  }
)

const interactiveLessonsSlice = createSlice({
  name: "interactiveLessons",
  initialState,
  reducers: {
    setSelectedInteractiveLesson(state, action) {
      state.selectedInteractiveLesson = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadInteractiveLessonsAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.interactiveLessons = action.payload || []
        }
    })
  }  
});

export const { 
  setSelectedInteractiveLesson,

} = interactiveLessonsSlice.actions;

export default interactiveLessonsSlice.reducer;
