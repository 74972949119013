import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { 
    Card, 
    CardBody, 
    CardTitle, 
    CardText,
    Col,
    Button 
  } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../Editorial/ConfirmDeleteModal";
import {setSelectedLecture} from "../../store/reducer/onlineLecturesReducer"

const OnlineLecturesItem = ({ lecture, onEditorial }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let creationTime = new Date(lecture.creation_time)
  creationTime = creationTime.toLocaleDateString();
  const handleCardClick = (lecture) => {
    navigate(`${lecture.id}`)
   
  }
  const handleDeleteClick = () => {
    dispatch(setSelectedLecture(lecture))
  }
  return (
    <Col lg={4} className="mb-3">
      <Card className="h-100 lectures-card">
        <CardBody className="d-flex flex-column">
        {onEditorial && 
            <div className="mb-2 d-flex justify-content-end gap-2">
              <Button className="btn btn-link text-primary-light p-0"  onClick={() => handleCardClick(lecture)}>Редактировать</Button>
              <Button className="btn btn-link text-danger p-0" data-bs-toggle="modal" data-bs-target="#confirmDelete" onClick={handleDeleteClick}>Удалить</Button>
            </div>
          }
          <Link to={!onEditorial && `${lecture.id}`} className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex justify-content-between">
              <div> 
                <CardTitle tag="h5">
                  {lecture.title}  
                </CardTitle>
                <CardText className="mb-4">
                  {lecture.author}
                </CardText>
              </div>
              <img 
                className="me-3 rounded object-fit-cover"
                width="72"
                height="72"
                src={lecture.preview_image_link} 
                alt="" 
              />
            </div>
            <div className="d-flex justify-content-between">
              <div className="badge bg-light text-dark rounded-pill">
                {creationTime}
              </div>
              <div className="text-muted">
                {lecture.category}
              </div>  
            </div>
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
};

export default OnlineLecturesItem;
