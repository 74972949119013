import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import {useGetSettingsDataQuery} from "../../api/settings/settingsApi";
import { Button, Container, Row, Col } from "reactstrap";
import Profile from "../../Components/SettingsComponents/Profile";
import MenuSetting from "../../Components/SettingsComponents/MenuSetting";
import SettingsContent from "../../Components/SettingsComponents/SettingsContent";
import EmailProvider from "../../context/ChangeEmailContext";
import Email from "../../Components/SettingsComponents/Email/Email";
import Materials from "../../Components/SettingsComponents/Materials";

import { useAuth } from "../../context/auth";
import InteractiveLessonsRequests from "../../Components/SettingsComponents/InteractiveLessonsRequests";
import PsychologicalConsult from "../../Components/SettingsComponents/UserPsychologicalConsult";

const Loading = () => (
    <div className="d-flex flex-column gap-2 align-center justify-content-center" >
        <p className="fw-bold" weight={"bold"}>Загрузка...</p>
    </div>
)

const Settings = () => {
    const { userType } = useAuth();
    const {data, isLoading} = useGetSettingsDataQuery("");
    const profile = useSelector((state) => state.userInfo.profile);
    const pages = [
        {
            title: "Персональные данные",
            path: 'settings',
            icon: <i class="bi bi-person-fill fs-5 text-dark"></i>,
            Component: <Profile city={profile.city}
                                region={profile.region}
                                name={profile.name}
                                surname={profile.surname}
                                institution={profile.institution}
                                study_year={profile.study_year}/>,
        },
        {
            title: "Эл. Почта",
            path: "email",
            icon: <i class="bi bi-envelope-fill fs-5 text-dark"></i>,
            Component: (
                <EmailProvider phoneNumber={data?.phone_number || ""} email={data?.email || ""}>
                    <Email/>
                </EmailProvider>
            ),
        },
        {
            title: "Методические материалы",
            path: 'materials',
            icon: <i class="bi bi-bag-check-fill fs-5 text-dark"></i>,
            Component: <Materials />,
        },
        {
            title: "Заявки на интерактивные уроки",
            path: 'interactive-lessons-requests',
            icon: <i class="bi bi-file-text-fill fs-5 text-dark"></i>,
            Component: <InteractiveLessonsRequests />,
        },
        {
            title: "Заявки на консультацию психолога",
            path: 'psychological-consult',
            icon: <i class="bi bi-file-earmark-text-fill fs-5 text-dark"></i>,
            Component: <PsychologicalConsult />,
        }
    ]
    if(userType === "admin") {
        pages.push(
            {
                title: "Административная панель",
                path: '/editorial',
                icon: <i class="bi bi-gear-fill fs-5 text-dark"></i>,
                Component: <></>,
            }
        )
    }
    return(
        <div className="d-flex border" style={{marginTop: "175px", borderRadius: "21px"}}>
            <MenuSetting pages={pages}/>
            {
                isLoading ?
                    <Loading/>
                    :
                    <SettingsContent pages={pages}/>
            }
        </div>
    )
}

export default Settings