import React, { useEffect, useState } from "react";
import PageHeading from "../../Components/page-heading/Page_Heading";
import {
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import RegisterBanner from "../../Components/register-banner/RegisterBanner";
import { loadDetailProductAsync } from "../../store/reducer/manage-products/detail-product";
import {useMount} from '../../hooks/hooks';
import { TextEditor } from "../../Components/EditorComponent/TextEditor/TextEditor";
import { useCart, useWishlist } from "../../hooks/hooks";
const TextArticle = ({content}) => {

  const [mounted] = useMount();

  return (
      <div className="d-flex w-100 flex-column pb-3" width={"full"} direction={"column"} >
          {mounted && <TextEditor readOnly initialValues={content}/>}
      </div>
  )
} 

const ProductSingle = () => {
  const dispatch = useDispatch();
  const detailProduct = useSelector((state) => state.detailProduct);
  const params =useParams();
  const [mounted] = useMount();
  const { addItemToCart } = useCart();
  const { addToWishlist}  = useWishlist();
  const id = useSelector((state) => state.products.selectedProduct);
  
  useEffect(() => {
    dispatch(loadDetailProductAsync(params.productId))
  }, [id]);
  
  
  
  const firstBreadcrumb = { label: "Методические материалы", link: "/products" };
  const secondBreadcrumb = {
    label: detailProduct.title,
    link: "/product-single",
    active: true,
  };
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  const {content} = detailProduct;
  
  if (!mounted || !content) return null;

  const values = Boolean(content) ? content : undefined;
  return (
    <div className="page-wrapper">
      <PageHeading
        title="Описание методического материала"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <div>
          <section>
            <Container>
              <Row className="align-items-center">
                <Col lg={5} md={6}>
                  <img
                    className="img-fluid w-100 rounded-4"
                    src={detailProduct.preview_image_link}
                    alt={detailProduct.title}
                  />
                </Col>
                <Col lg={7} md={6} className=" mt-5 mt-md-0">
                  <div className="product-details">
                    <h3> {detailProduct.title} </h3>
                    <div className="product-price my-4">
                    {detailProduct.price_with_discount ? <>
                                                        <span style={{ marginRight: "5px", fontSize:"24px" }}>
                                                          {detailProduct.price_with_discount} ₽
                                                        </span>
                                                        <del className="text-muted mr-2">
                                                          {detailProduct.price} ₽
                                                        </del>
                                                      </>
                                                    :<span style={{ marginRight: "5px", fontSize:"24px" }}>
                                                      {detailProduct.price} ₽
                                                    </span>
                      }

                    </div>
                    <ul className="list-unstyled mb-4">

                      <li>
                        <span className="text-dark"> Категории :</span>{" "}
                        {detailProduct.category}
                      </li>
                    </ul>
                    <p>{detailProduct.shortDescription}</p>

                    <div className="product-link d-flex align-items-center mt-4">
                      <Button
                        className="btn btn-primary me-3"
                        type="button"
                        onClick={() => addItemToCart(detailProduct)}
                      >
                        Добавить в корзину
                      </Button>
                      <Button
                        className="btn btn-dark"
                        type="button"
                        onClick={() => {
                          addToWishlist(detailProduct);
                        }}
                      >
                        Добавить в желаемое
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <Container>
              <Row>
                <Col md={12}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={
                          activeTab === "1"
                            ? "text-light active ms-0"
                            : "text-dark ms-0"
                        }
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Описание
                      </NavLink>
                    </NavItem>
                  </Nav>
                   <TabContent activeTab={activeTab} className="pt-5">
                    <TabPane tabId="1">
                      <h5 className="mb-3">Описание материала</h5>
                      <TextArticle content={values}/>
                    </TabPane>
                   
                  </TabContent>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
      <RegisterBanner />
    </div>
  );
}

export default ProductSingle;
