import React from "react";
import {
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
  } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedProduct } from "../../store/reducer/productReducer";
import { useCart, useWishlist } from "../../hooks/hooks";

const ProductCardVertical = ({item, onEditorial}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { addItemToCart } = useCart();
    const { addToWishlist}  = useWishlist();

    const onItemClick = (editItem) => {
        navigate(`${editItem.id}`)
    }
    const handleDeleteClick = () => {
      dispatch(setSelectedProduct(item))
    }
    return (
        <Col lg="5" md="6" className="mt-5">
                    <Card className="shadow rounded-4 p-3" key={item.id}>
                    {onEditorial && 
                        <div className="mb-2 d-flex justify-content-end gap-2">
                        <Button className="btn btn-link text-primary-light p-0"  onClick={() => onItemClick(item)}>Редактировать</Button>
                        <Button className="btn btn-link text-danger p-0" data-bs-toggle="modal" data-bs-target="#confirmDelete" onClick={handleDeleteClick}>Удалить</Button>
                        </div>
                    }
                      <img
                        className="w-100 rounded-4 object-fit-cover"
                        src={item.preview_image_link}
                        height={200}
                        alt=""
                      />
                      <CardBody>
                        <CardTitle>
                          <Link
                            to={`${item.id}`}
                            onClick={() => {
                              dispatch(setSelectedProduct(item.id));
                            }}
                            className="mt-4 mb-2 d-block link-title h6"
                          >
                            {item.title}
                          </Link>
                        </CardTitle>
                        <span className="product-price text-dark">
                          {item.price_with_discount ? <>
                                                        <span style={{ marginRight: "5px", fontSize:"24px" }}>
                                                          {item.price_with_discount} ₽
                                                        </span>
                                                        <del className="text-muted mr-2">
                                                          {item.price} ₽
                                                        </del>
                                                      </>
                                                    :<span style={{ marginRight: "5px", fontSize:"24px" }}>
                                                      {item.price} ₽
                                                    </span>
                          }
                          
                        </span>
                        <div className="product-link mt-3">
                          <Link
                            style={{ marginRight: "3px" }}
                            className="add-cart"
                            onClick={() => {
                              addItemToCart(item);
                            }}
                          >
                            <i className="bi bi-bag-check me-2"></i>Добавить в корзину
                          </Link>
                          <Link
                            className="wishlist-btn add-cart"
                            onClick={() => {
                              addToWishlist(item);
                            }}
                          >
                            <i className="bi bi-bag-heart me-2"></i>Добавить в желаемое
                          </Link>
                        </div>
                      </CardBody>
                      
                    </Card>
                  </Col>
    )
}

export default ProductCardVertical;