import React from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../hooks/hooks";
const Page_Heading = ({
  title,
  animationSrc,
  firstBreadcrumb,
  secondBreadcrumb,
  thirdBreadcrumb,
  fourthBreadcrumb
}) => {
  const {isMobile} = useIsMobile();
  
  
  return (
    <div className="pt-6 pb-6 position-relative overflow-hidden">
      <Container>
        <Row className="text-center">
          <Col>
            {isMobile ? <h2 className="mb-3">{title}</h2>
                      : <h1 className="mb-3">{title}</h1>
            }
            <Breadcrumb className="bg-white shadow d-inline-block px-4 py-2 rounded-4">
              <BreadcrumbItem className="mt-2">
                <Link className="text-dark" to="/">
                  Главная
                </Link>
              </BreadcrumbItem>
              {firstBreadcrumb.label != '' ? <BreadcrumbItem className="mt-2">
                <Link className="text-dark" to={firstBreadcrumb.link}>
                  {firstBreadcrumb.label}
                </Link>
              </BreadcrumbItem>
              : null
              }
              
              {thirdBreadcrumb && thirdBreadcrumb.label != '' ? <BreadcrumbItem className="mt-2">
                <Link className="text-dark" to={thirdBreadcrumb.link}>
                  {thirdBreadcrumb.label}
                </Link>
              </BreadcrumbItem>
              : null
              }
              
              {fourthBreadcrumb && fourthBreadcrumb.label != '' ? <BreadcrumbItem className="mt-2">
                <Link className="text-dark" to={fourthBreadcrumb.link}>
                  {fourthBreadcrumb.label}
                </Link>
              </BreadcrumbItem>
              : null
              }

              <BreadcrumbItem className="mt-2">
                {secondBreadcrumb.active ? (
                  <span className="text-primary">{secondBreadcrumb.label}</span>
                ) : (
                  <Link className="text-dark" to={secondBreadcrumb.link}>
                    {secondBreadcrumb.label}
                  </Link>
                )}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
      <div className="position-absolute animation-1">
        <lottie-player
          src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
  );
};

export default Page_Heading;
