import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import InteractiveLessonsItem from "../../Components/interactive-lessons/InteractiveLessonsItem";
import CustomPagination from "../../Components/pagination";
import { Col, Container, Row } from "reactstrap";
import { loadInteractiveLessonsAsync } from "../../store/reducer/interactiveLessonsReducer";

const InteractiveLessonsList = ({toggle}) => {
    const dispatch = useDispatch();
    const interactiveLessons = useSelector((state) => state.interactiveLessons.interactiveLessons);
    
    const [activePage, setActivePage] = useState(1);
    const pageSize = 3;
    const totalPages = Math.ceil(interactiveLessons.length / pageSize);
    const startIndex = (activePage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const lessonsToShow = interactiveLessons.slice(startIndex, endIndex);
  
    const handlePageChange = (pageNumber) => {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      
      setActivePage(pageNumber);
    };
    
    useEffect(() => {
        dispatch(loadInteractiveLessonsAsync())        
    }, [])
    return (
        <div className="page-wrapper">
            <Container>
                
                {interactiveLessons.map((interactiveLesson, index) => (
                        <InteractiveLessonsItem key={index} interactiveLesson={interactiveLesson} toggle={toggle} onEditorial={false}/>
                ))}
                
            </Container>
            {/* <Row
                className="mt-5"
                style={{ display: "grid", justifyContent: "center" }}
            >
                <CustomPagination
                    activepage={activePage}
                    totalpages={totalPages}
                    onPageChange={handlePageChange}
                />
            </Row> */}
        </div>
    )
}

export default InteractiveLessonsList;