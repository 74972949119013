import React, {useRef} from "react";
import { 
    Container,
    Col,
    Row 
     } from "reactstrap";
import { useExpand } from "../../hooks/hooks";
import EducationItem from "../../Components/education/EducationItem";
import LottiePlayer from "../../Components/player";
import Bg from "../../assets/lottie/bg-3.json";
import DotLottiePlayer from "../DotPlayer";
const ProgramSection = ({
    innerRef,
    title, 
    subtitle,
    programs,
    text1,
    text2,
    text3,
}) => {
    const { expanded, toggleExpanded } = useExpand();
    const scrollToTop = () => {
        if(expanded) innerRef.current?.scrollIntoView()
      };
    return (
        <section  className="px-lg-7 px-2" ref={innerRef}>
                <div className="bg-light py-7 px-3 px-lg-8 rounded-4 position-relative overflow-hidden">
                    <Container>
                        <div className="mb-6">
                            <Row>
                                <h3 className="mb-0">{title}</h3>
                                <h5 className="text-secondary mb-6">{subtitle}</h5>
                                <p>{text1}</p>
                                <p>{text2}</p>
                                <p>{text3}</p>
                            </Row>
                            
                            <div className="position-absolute animation-2">
                                <LottiePlayer src={Bg} />
                            </div>
                            <div className="position-absolute animation-3">
                                <LottiePlayer src={Bg} />
                            </div>
                        </div>
                        <Container className={expanded ? "program-container-expanded z-index-1" : "program-container z-index-1"}>
                            {programs.map((program, index) => (
                                <EducationItem key={index} program={program} onEditorial={false}/>
                            ))}
                        </Container>
                        <div className={expanded ? "btn-expand btn-expand-rotate" : "btn-expand"}>
                            <hr />
                            <button onClick={() => {
                                toggleExpanded();
                                scrollToTop();
                            }}>
                               <span></span> 
                            </button>
                            <hr />
                        </div>
                    </Container>
                </div>
            </section>
    )
}

export default ProgramSection;