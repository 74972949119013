import React from 'react';
import { useEmailContext } from '../../../context/ChangeEmailContext';
import ConfirmationEmail from './ConfirmationEmail';
import StageDefault from './StageDefault';

const Email = () => {

    const {
        currentStage,
    } = useEmailContext();

    const CurrentStage = () => {
        switch (currentStage) {
            case "ConfirmationEmail":
                return <ConfirmationEmail/>
            default:
                return <StageDefault/>
        }
    }

    return (
        <div>
            <h5 className='fw-bold mt-6 mb-3' >Эл. почта</h5>
            <div className='my-5'>
                <CurrentStage/>
            </div>

        </div>
    );
};

export default Email;
