import React, {useEffect, useState} from "react";
import { 
    Card, 
    CardImg, 
    CardBody, 
    CardTitle, 
    CardText, 
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import {useSelector, useDispatch} from "react-redux";
import { useLocation } from "react-router-dom"
import {loadAllSectionsAsync,loadArticlesAsync,loadSubsectionsAsync} from "../../store/reducer/pedagogicalSearchReducer"
import { destinationItems } from "../Editorial/AddItem";
import { useIsMobile } from "../../hooks/hooks";

const SectionsList = ({manageArticles, setShowSectionList, setShowSubsectionList}) => {
    const sections = useSelector((state) => state.pedagogicalSearch.sections);
    const location = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [destination, setDestination] = useState(null);
    
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const dispatch = useDispatch();
    
    const onItemClick = (item) => {
        setDestination(item)
    }
    
    useEffect(() => {
      dispatch(loadAllSectionsAsync())
    }, [])
    
    const handleSectionSelect = (section) => {
        if(section.destination === "Родители и дети"){
            localStorage.setItem('selectedEditSubsection', JSON.stringify(section));
            setShowSectionList(false)
            dispatch(loadArticlesAsync(section.id))
        }else if(section.destination === "Творческий поиск"){
            localStorage.setItem('selectedEditSection', JSON.stringify(section));
            setShowSectionList(false)
            if(manageArticles)setShowSubsectionList(true)
            dispatch(loadSubsectionsAsync(section.id))
        }
    }
    useEffect(() => {
        if (location.pathname.includes('/manage-subsections')) {
            setDestination('Творческий поиск')
        }
    }, [])
    return (
        
                <Container className={`d-flex gap-3 flex-column px-10`}>
                    <div className="w-100  mb-4">
                        <h3>Выберите рубрику</h3>
                    </div>
                    { !location.pathname.includes('manage-subsections') && <>
                                                                                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
                                                                                    <DropdownToggle caret>Выберите площадку</DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        {
                                                                                            destinationItems.map((item, index) => (
                                                                                                
                                                                                                <DropdownItem key={index} onClick={() => onItemClick(item.title)}>{item.title}</DropdownItem>
                                                                                                
                                                                                                
                                                                                            ))
                                                                                        }
                                                                                    </DropdownMenu>
                                                                                </Dropdown>
                                                                                <p className="mt-3">Показать рубрики по площадке: {destination}</p>
                                                                            </>
                    }
                   
                    {sections.map((item, index) => {
                        if (destination === null){
                            return (
                                <Card key={index} className="d-flex flex-row p-4 search-card search-card-hover" onClick={() => handleSectionSelect(item)}>
                                    <CardImg
                                        className="rounded-4 object-fit-cover"
                                        width="156px"
                                        height="156px"    
                                        style={{
                                        height: 156,
                                        width: 156
                                        }}
                                        src={item.preview_image_link} 
                                    />
                                    <CardBody>
                                        <CardTitle className="d-flex justify-content-between align-items-start">
                                                {item.title}
                                        </CardTitle>
                                        <CardText>{item.description}</CardText>
                                    </CardBody>
                                </Card>
                            )
                        } else {
                            return (
                                <>
                                {item.destination === destination &&
                                    <Card key={index} className="d-flex flex-row p-4 search-card search-card-hover" onClick={() => handleSectionSelect(item)}>
                                        <CardImg
                                        className="rounded-4 object-fit-cover"
                                        width="156px"
                                        height="156px"    
                                        style={{
                                            height: 156,
                                            width: 156
                                            }}
                                            src={item.preview_image_link} 
                                            />
                                        <CardBody>
                                            <CardTitle className="d-flex justify-content-between align-items-start">
                                                    {item.title}
                                            </CardTitle>
                                            <CardText>{item.description}</CardText>
                                        </CardBody>
                                    </Card>
                                }
                                </> 
                            )
                        }
                    })}
                </Container>
      
    )
}

export default SectionsList;