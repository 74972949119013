import {axiosClient} from "../common/axios";
import Api from "../api/Api";

export const getArticleDetail = async ({article_id}) => {
    try {
        const url = Api.endpoints.GET_ARTICLE_DETAIL.replace('{article_id}', article_id);

        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const getSectionById = async ({section_id}) => {
    try {
        const url = Api.endpoints.GET_SECTION_BY_ID.replace('{section_id}', section_id);
        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const deleteSection = async ({section_id}) => {
    try {
        const url = Api.endpoints.DELETE_SECTION.replace('{section_id}', section_id);
        const response = await axiosClient.post(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const deleteArticle = async ({article_id}) => {
    try {
        const url = Api.endpoints.DELETE_ARTICLE.replace('{article_id}', article_id);
        
        const response = await axiosClient.post(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const changeContentInfo = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.CHANGE_SECTION_INFO, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const changeSectionInfo = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.CHANGE_SECTION_INFO, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}


export const changeArticleInfo = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.CHANGE_ARTICLE_INFO, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const changeArticleContent = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.CHANGE_ARTICLE_CONTENT, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const createArticle = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.CREATE_ARTICLE, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const createSection = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.CREATE_SECTION, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        console.error(e)
        return {
            success: false,
            response: e.response,
        }
    }
}


export const getAllSections = async () => {
    try {
        const response = await axiosClient.get(
            Api.endpoints.GET_ALL_SECTIONS
        )
        return {
            success: true,
            data: response.data
        };
    } catch (error) {
        return {
            success: false
        };
    }
}


export const getSubsections = async (sectionId) => {
    try {
        const response = await axiosClient.get(
            Api.endpoints.GET_SUBSECTIONS.replace('{parent_id}', sectionId)
        )
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const getArticles = async (subsectionId) => {
    try {
        const response = await axiosClient.get(
            Api.endpoints.GET_ARTICLES.replace('{parent_id}', subsectionId)
        )
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}