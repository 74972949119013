import Api from "../../Api";
import axios from "axios";

export const initStudentRegistration = async (student) => {
    const data = {
        name: student.name,
        surname: student.surname,
        email: student.email,
        region: student.region,
        city: student.city,
        institution: student.institution,
        study_year: student.study_year,
        phone_number: student.phone_number,
        password: student.password,
        confirm_password: student.confirm_password,
    }


    let response = null;

    try {
        response = await axios.post(Api.endpoints.STUDENT_SIGNUP, data);
    } catch (e) {
        return {
            status: e.response?.status || 0,
            token: "",
            errors: [],
            email: e.response?.data.email,
            phoneNumber: e.response?.data.phone_number,
            name: e.response?.data.name,
            surname: e.response?.data.surname,
            region: e.response?.data.region,
            city: e.response?.data.city,
            institution: e.response?.data.institution,
            study_year: e.response?.data.studyYear,

        }
    }

    return {
        status: response.status,
        token: response.data.access_token,
        errors: Api.getErrors(response),
    }
}
