import React, {useCallback, useRef, useState } from 'react';
import EyeFillIcon from "../../assets/icons/eye-fill.svg";
import EyeFillSlashIcon from "../../assets/icons/eye-slash-fill.svg";
import {FormGroup, Input, Label} from "reactstrap";



const PasswordIcon = (
    {
        inputRef,
    }
) => {

    const [showPassword, setShowPassword] = useState(false);
    const onToggleHandler = useCallback(() => setShowPassword(!showPassword), [setShowPassword, showPassword]);

    const icon = showPassword ? EyeFillSlashIcon : EyeFillIcon;
    if (inputRef && inputRef.current) inputRef.current.type = showPassword ? "text" : "password";

    return (
        <button className={'password_eye'} onClick={onToggleHandler} type={"button"} tabIndex={-1}>
            <img src={icon} width={14} height={14} alt="password" priority/>
        </button>
    )
}

const MemorizedPasswordIcon = React.memo(PasswordIcon);

const CustomInput = (
    {
        state = "normal",
        width = "default",
        typeElem = "input",
        label,
        error,
        touched = false,
        ...props
    }
) => {
    const inputRef = useRef();

    return (
            <FormGroup floating className="position-relative">
                <Input innerRef={inputRef} {...props}/>
                <Label for={props.id}>{props.placeholder}</Label>
                {props.type === "password" &&
                    <MemorizedPasswordIcon inputRef={inputRef}/>}
            </FormGroup>
    );
};

export default React.memo(CustomInput);
