import React, {useState} from "react";
import PageHeading from "../../Components/page-heading/Page_Heading";
import { Col, Container, Row } from "reactstrap";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";
import LottiePlayer from "../../Components/player";
import { asyncLoadUserData } from "../../store/reducer/auth/userInfoReducer";
import Api from "../../api/Api";
import axios, {AxiosError, AxiosRequestConfig} from "axios";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import CustomInput from "../../Components/CustomInput/CustomInput";
const SignIn =() => {
  const firstBreadcrumb = { label: "", link: "/" };
  const secondBreadcrumb = {
    label: "Войти",
    link: "/login",
    active: true,
  };
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuth();
  const handleSubmit = async(e) => {
    e.preventDefault();
    
    const payload = {
      username: email,
      password: password
    }
    
    const config = {
      method: "post",
      url: Api.endpoints.LOGIN_USER,
      data: payload,
      headers: {"Content-Type": "multipart/form-data"},
  }

  try {
      const response = await axios(config);
      if (response.status === 200) {
          auth.updateToken(response.data.access_token);

          dispatch(asyncLoadUserData());
          navigate("/account/settings")
          window.location.reload();
      }

  } catch (e) {

      toast.error("Неправильный логин или пароль!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });  
  }
  };

  return (
    <div className="page-wrapper">
      <PageHeading
        title="Войти"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section>
          <Container>
            <Row className="align-items-center justify-content-between">
              <Col xs={12} lg={6}>
                <LottiePlayer src="https://lottie.host/cbbc0c83-044c-4cf0-ba2e-54438fcbafd8/6M8MI7snvI.json" />
              </Col>
              <Col lg={5} xs={12} className="mt-5">
                <div className="border border-light rounded-4 p-5">
                  <h2 className="mb-5">Вход</h2>
                  <Form id="contact-form" onSubmit={handleSubmit}>
                    <div className="messages"></div>
                    {/* <FormGroup floating>
                      <Input
                        type="text"
                        name="name"
                        id="form_name"
                        placeholder="Эл. Почта"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <Label for="form_name">Эл. Почта</Label>
                    </FormGroup> */}
                    <CustomInput
                      type="text"
                      name="name"
                      id="form_name"
                      placeholder="Эл. Почта"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {/* <FormGroup floating className="position-relative">
                      <Input
                        type="password"
                        name="password"
                        id="form_password"
                        placeholder="Пароль"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <Label for="form_password">Пароль</Label>
                    </FormGroup> */}
                    <CustomInput
                       type="password"
                       name="password"
                       id="form_password"
                       placeholder="Пароль"
                       onChange={(e) => setPassword(e.target.value)}
                       required
                    />
                    <div className="mt-4 mb-5">
                      <div className="remember-checkbox d-flex align-items-center justify-content-between">
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="check1"
                          />
                          <Label className="form-check-label" htmlFor="check1">
                            Запомнить меня
                          </Label>
                        </div>
                        <Link className="btn-link" to="/forgot-password">
                          Забыли пароль?
                        </Link>
                      </div>
                    </div>
                    <Button color="primary">Войти</Button>
                  </Form>
                  <div className="d-flex align-items-center mt-4">
                    <span className="text-muted me-1">
                      Еще нет аккаунта?
                    </span>
                    <Link to="/signup">Зарегистрироваться</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/*<RegisterBanner />*/}
      </div>
    </div>
  );
}

export default SignIn;
