import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import 'react-dadata/dist/react-dadata.css';
import {initParentRegistration} from "../../../api/auth/parent/parent";
import { useAuth } from "../../../context/auth";
import { setToken, setUcallerId, setUserData } from "../../../store/reducer/registerDataReducer";
import DefaultSignUpLayout from "../DefaultSignUpLayout";
import { toast } from "react-toastify";

function ParentSignUp () {
    const registerData = useSelector((state) => state.registerData);
    const auth = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            name: registerData.name,
            surname: registerData.surname,
            email: registerData.email,
            region: registerData.region,
            city: registerData.city,
            institution: registerData.institution,
            study_year: registerData.studyYear,
            phone_number: registerData.phoneNumber,
            password: registerData.password,
            confirm_password: registerData.confirmPassword,
        }
        const response = await initParentRegistration(payload)
        if (response.status === 200) {
            dispatch(setUserData(payload));
            
            if (response.status) {
                auth.updateToken(response.token);
                navigate('/account/settings')
                window.location.reload();
            }
            
        } else if(response.status === 422) {
            toast.error('Проверьте введенные данные', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 

            window.scrollTo({top: 0, behavior: "smooth"});
        } else if(response.status === 400) {
            toast.error('Пользователь с такой почтой уже существует', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }

    }

    return (
         <DefaultSignUpLayout isTeacher={false} handleSubmit={handleSubmit} />
    )
}

export default ParentSignUp;