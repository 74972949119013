import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useNavigate} from "react-router-dom"
import { loadUserOwnershipAsync } from "../../store/reducer/manage-products/user-ownership";
import DownloadIcon from "../../assets/icons/ic-download.svg";
const Materials = ({materials}) => {
    const userOwnership = useSelector((state) => state.userOwnership.products);
    const profile = useSelector((state) => state.userInfo.profile);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(loadUserOwnershipAsync(profile.userId))
    }, [profile])
    return (
        <div className="d-flex flex-column w-100">
            <h5 className='fw-bold mt-6 mb-3' >Методические материалы</h5>
            <div className='d-flex flex-column gap-2 mx-0 my-5 border rounded p-4 user-ownership-wrapper'>
                {userOwnership.map((item) => (
                    <div className="d-flex w-100 rounded border shadow-sm p-2">
                        <img src={item.preview_image_link} width={128} height={96} className="object-fit-cover me-3 rounded" alt="" />
                        <div className="d-flex justify-content-between w-100 align-items-center">
                            <p>{item.title}</p>
                            <Link to={item.media ? item.media : "#"} download={item.title} className="download-wrapper" >
                                <svg version="1.1" viewBox="0 0 14 19" xmlns="http://www.w3.org/2000/svg">
                                    
                                    <g transform="translate(-383 -213)" fill="#000">
                                    <g transform="translate(383 213.5)">
                                    <path d="m14 6h-4v-6h-6v6h-4l7 7 7-7zm-14 9v2h14v-2h-14z"/>
                                    </g>
                                    </g>
                                </svg>
                            </Link>
                            
                        </div>
                    </div>
                ))}
                
            </div>
        </div>
    )
}

export default Materials;