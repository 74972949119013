import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {FormGroup, Button} from "reactstrap";
import {useFormik} from "formik";
import {useUpdatePersonalInformationMutation} from "../../api/settings/settingsApi";
import lodash from "lodash";
import CustomInput from '../CustomInput/CustomInput';
import { AddressSuggestions } from 'react-dadata';
import { ConstructionOutlined } from '@mui/icons-material';
import { useAuth } from '../../context/auth';

const Profile = (
    {
        firstName,
        lastName,
        region,
        city,
        institution,
        study_year
        
    }
) => {

    const [errorMessage, setErrorMessage] = useState();
    const [update] = useUpdatePersonalInformationMutation();
    const profile = useSelector((state) => state.userInfo.profile);
    const {userType} = useAuth();
    
    let userTypeName = '' 
    switch (userType) {
        case 'teacher':
            userTypeName = "Преподаватель";
            break;
        case 'parent':
            userTypeName = "Родитель";
            break;
        case 'student':
            userTypeName = "Студент";
            break;
        case 'schoolStudent':
            userTypeName = "Учащийся в школе";
            break;
        case 'admin':
            userTypeName = "Администратор";
            break;
        default:
            userTypeName = "";
    }
    const formik = useFormik({
        initialValues: {
            name: profile.name,
            surname: profile.surname,
            region: profile.region,
            city: profile.city,
            institution: profile.institution,
            study_year: profile.study_year
        },
        onSubmit: values => {
            setErrorMessage("");
            update({
                first_name: values.firstName,
                last_name: values.lastName,
                region: values.region,
                city: values.city,
                institution: values.institution,
                study_year: values.study_year
            })
                .unwrap()
                .catch(err => {
                    setErrorMessage(err.data.detail);
                })
        },
    });
    const resetForm = () => formik.resetForm();
    
    let {values, errors, touched, handleBlur, handleChange} = formik;
    const valuesNotBeenChanged = lodash.isEqual(formik.initialValues, values);
    
    return (
        <form className="w-100" onSubmit={formik.handleSubmit}>
            <h5 className='fw-bold mt-6 mb-3' >Персональные данные</h5>
            <h6>Тип пользователя: {userTypeName}</h6>
            {errorMessage &&
                <p className="mb-3" content={errorMessage} color={"red"} weight={"bold"}/>}

            <div className="profile_info">
                <CustomInput
                    error={errors.name}
                    touched={touched.name}
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={e => formik.setValues({ ...formik.values, name: e.target.value })}
                    name={"name"}
                    placeholder={"Имя"}/>
                <CustomInput
                    error={errors.surname}
                    touched={touched.surname}
                    onBlur={handleBlur}
                    value={values.surname}
                    onChange={e => formik.setValues({ ...formik.values, surname: e.target.value })}
                    name={"surname"}
                    placeholder={"Фамилия"}/>
            </div>
            <CustomInput
                error={errors.institution}
                touched={touched.institution}
                onBlur={handleBlur}
                value={values.institution}
                onChange={e => formik.setValues({ ...formik.values, institution: e.target.value })}
                name={"institution"}
                placeholder={"Учебное Заведение"}/>
            {userType === "teacher" ? 
                                    <CustomInput
                                        error={errors.position}
                                        touched={touched.position}
                                        onBlur={handleBlur}
                                        value={values.position}
                                        onChange={e => formik.setValues({ ...formik.values, position: e.target.value })}
                                        name={"position"}
                                        placeholder={"Должность"}/>
                                    :
                                    <CustomInput
                                        error={errors.study_year}
                                        touched={touched.study_year}
                                        onBlur={handleBlur}
                                        value={values.study_year}
                                        onChange={e => formik.setValues({ ...formik.values, study_year: e.target.value })}
                                        name={"study_year"}
                                        placeholder={"Год Обучения"}/>
                                    
            }
            
            <FormGroup >
                <AddressSuggestions 
                    token={process.env.REACT_APP_DADATA_API} 
                    value={values.region} 
                    onChange={value => formik.setValues({ ...formik.values, region: value })}
                    inputProps={{
                        className: "form-control",
                        placeholder: "Регион"
                    }}
                />
            </FormGroup>
            <FormGroup >
                <AddressSuggestions 
                    token={process.env.REACT_APP_DADATA_API} 
                    value={values.city} 
                    onChange={value => formik.setValues({ ...formik.values, city: value })}
                    inputProps={{
                        className: "form-control",
                        placeholder: "Город"
                    }}
                />
            </FormGroup>

           

            <div className='d-flex flex-column gap-2 mx-0 my-5'>
                <div className="d-flex flex-column gap-2 mt-5">
                    {
                        !valuesNotBeenChanged &&
                        <p content={"Для применения изменений необходимо сохранить"} weight={"bold"} color={"green"}></p>
                    }
                    <div className='d-flex justify-content-around w-100'>
                        <Button className='btn-primary' disabled={valuesNotBeenChanged}>Сохранить</Button>
                        <Button className='btn-secondary' type="button" onClick={resetForm}>Отменить</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default React.memo(Profile);
