import React, {useRef} from "react";
import { Link } from "react-router-dom";

const TeachersToSchoolCard = ({title, link}) => {
    const linkRef = useRef(null);
    const handleCardClick = () => {
        window.location.href = link;
    }
    return (
    <div 
        className="bg-white p-4 rounded-4 f-icon-hover44 card-hover d-flex flex-column justify-content-center "
        style={{height: "150px"}}
        onClick={handleCardClick}
    >
        <h6 className="mb-3">{title}</h6>
        <div>
            <Link ref={linkRef} to={link} className="btn-arrow"></Link>
        </div>
    </div>
    )
}

export default TeachersToSchoolCard;