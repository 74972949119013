import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import MockBackgroundImage from "../../../assets/images/bg/background-mock.png";
import {getArticleDetail, getSectionById} from "../../../services/PedagogicalSearchService";
import { getAllSections } from "../../../services/PedagogicalSearchService";

const initialState = {
    id: 0,
    title: "",
    description: "",
    preview: MockBackgroundImage,
    section_id:0,
    subsection_id:0,
    show_description: false,
    content: null,
    destination: ""
    
}

export const loadDetailArticleAsync = createAsyncThunk(
    "loadDetailArticle",
    async (aticleId) => {
        const response = await getArticleDetail({article_id: aticleId})
        return response.data;
    }
)

export const loadSectionAsync = createAsyncThunk(
    "loadSectionAsync",
    async (sectionId) => {
        const response = await getSectionById({section_id: sectionId})
        return response.data;
    }
)

export const loadAllSectionsAsync = createAsyncThunk (
    "loadAllSectionsAsync",
    async() => {
        const response = await getAllSections();
        if (response.success) return response.data;
        else return undefined;
    }
)

const detailArticleSlice = createSlice({
    name: "detailArticle",
    initialState,
    reducers: {
        clearState: () => ({...initialState}),
        changeTitleArticle: (state, action) => {
            state.title = action.payload;
        },
        changeArticleId: (state, action) => {
            state.id = action.payload;
        },
        changeDescriptionArticle: (state, action) => {
            state.description = action.payload;
        },
        changeContent: (state, action) => {
            state.content = action.payload;
        },
        changePreview: (state, action) => {
            state.preview = action.payload;
        },
        changeSectionId: (state, action) => {
            state.section_id = action.payload;
        },
        changeSubsectionId: (state, action) => {
            state.subsection_id = action.payload;
        },
        changeShowDescription: (state, action) => {
            state.show_description = action.payload;
        },
        changeDestination: (state, action) => {
            state.destination = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadDetailArticleAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const {
                        title,
                        description,
                        content,
                        id,
                        show_description
                    } = action.payload;

                    state.id = id;
                    state.title = title;
                    state.description = description;
                    state.content = content;
                    state.show_description = show_description
                }
            })
        builder.addCase(loadSectionAsync.fulfilled, (state, action) => {
            if (action.payload) {
                if (action.payload) {
                    const {
                        title,
                        description,
                        preview_image_link,
                        id,
                        show_description
                    } = action.payload;

                    state.id = id;
                    state.title = title;
                    state.description = description;
                    state.preview = preview_image_link;
                    state.show_description = show_description
                }
            }
        })    
    }
})

export const {
    clearState,
    changeDescriptionArticle,
    changeTitleArticle,
    changeContent,
    changePreview,
    changeSectionId,
    changeSubsectionId,
    changeArticleId,
    changeShowDescription,
    changeDestination
} = detailArticleSlice.actions;

export const selectDetailArticle = (state) => state.detailArticle;
export default detailArticleSlice.reducer;