import React, { useEffect, useState } from "react";
import {Container} from "reactstrap"
import SectionItem from "../../Components/PedagogicalSearch/SectionItem";
import {useSelector, useDispatch} from "react-redux";
import {loadAllSectionsAsync} from "../../store/reducer/pedagogicalSearchReducer"
import PageHeading from "../../Components/page-heading/Page_Heading";
import {useLocation, Outlet} from "react-router-dom"
import { useIsMobile } from "../../hooks/hooks";
import PedagogicalSearch from "../../assets/images/Творческий-поиск.jpg"

const Subsections = () => {
  const firstBreadcrumb = { label: "", link: "/" };
  const secondBreadcrumb = {
    label: "Рубрики",
    link: "/",
    active: true,
  };
  
  const sections = useSelector((state) => state.pedagogicalSearch.sections);
  const [PSsections, setPSsections] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const {isMobile} = useIsMobile();
  
  useEffect(() => {
    const filteredSections = sections.filter((item) => item.destination === "Творческий поиск");
    setPSsections(filteredSections);
  }, [sections]);

  
  useEffect(() => {
    dispatch(loadAllSectionsAsync())
  }, [])
  return (
    <>
    {location.pathname === "/sections" ? <>
                                          {/* <PageHeading
                                              title="Педагогический поиск: творчество без границ"
                                              firstBreadcrumb={firstBreadcrumb}
                                              secondBreadcrumb={secondBreadcrumb}
                                          /> */}
                                        <Container className="page-wrapper mb-5">   
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column justify-content-center px-2">
                                                            <h2 className="mb-5">Педагогический поиск: творчество без границ</h2>
                                                            <p>
                                                              Путь учителя к успеху начинается с того
                                                              момента, когда приходит осознание, что
                                                              учитель – это не простой проводник
                                                              школьника к новым знаниям, а педагог,
                                                              который идет в ногу со временем, живо
                                                              интересуется новинками
                                                              образовательных методик, смело
                                                              внедряет инновационные технологии в
                                                              процесс обучения. Всё это вы найдете
                                                              здесь, на площадке «ПЕДАГОГИЧЕСКИЙ
                                                              ПОИСК: ТВОРЧЕСТВО БЕЗ ГРАНИЦ».
                                                            </p>
                                                        </div>
                                                        
                                                        {/* <img src={PedagogicalSearch} alt="" width={500} height={500} className={`${isMobile ? "d-none" : ""}`}/> */}
                                                    </div>
                                        </Container>
                                          <Container className="d-flex flex-column">
                                            <h3 className="mb-5">Ищите интересующие вас материалы по тематикам:</h3>
                                            <div className={`d-flex gap-3 flex-column pb-10 ${isMobile ? "align-items-center" : ""}`}>
                                              {PSsections.map((item, index) => (
                                                  <SectionItem item={item} index={index}/>
                                              ))}
                                            </div>  
                                          </Container>
                                        </>
      : <Outlet/>
    }
    </>
  );
}

export default Subsections;