import React, {useEffect, useState} from "react";
import {
    Container, 
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import PSAdminItems from "../../../Components/PedagogicalSearch/PSAdminItems";
import {useSelector, useDispatch} from "react-redux";
import {loadAllSectionsAsync} from "../../../store/reducer/pedagogicalSearchReducer"
import {useNavigate, useLocation, Outlet} from "react-router-dom"
import ConfirmDeleteModal from "../../../Components/Editorial/ConfirmDeleteModal"
import { destinationItems } from "../../../Components/Editorial/AddItem";

const ManageSections = () => {
    const sections = useSelector((state) => state.pedagogicalSearch);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [destination, setDestination] = useState(null);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();
    
    
    const onItemClick = (item) => {
        setDestination(item)
    }
    
    useEffect(() => {
      dispatch(loadAllSectionsAsync())
    }, [location.pathname])
    
    const onCreateClick = () => {
        navigate("add-sections")
    }
    
    return (
        
        <Container className="d-flex gap-3 flex-column px-10 mt-10">
            {location.pathname === "/editorial/manage-sections" ?
                                                                  <>
                                                                  <div>
                                                                    <div className="w-100 d-flex justify-content-between mb-4">
                                                                        <h3>Рубрики</h3>
                                                                        <Button className="btn btn-primary" onClick={onCreateClick}>Создать</Button>
                                                                    </div>
                                                                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
                                                                        <DropdownToggle caret>Выберите площадку</DropdownToggle>
                                                                        <DropdownMenu>
                                                                            {
                                                                                destinationItems.map((item, index) => (
                                                                                    
                                                                                    <DropdownItem key={index} onClick={() => onItemClick(item.title)}>{item.title}</DropdownItem>
                                                                                    
                                                                                    
                                                                                ))
                                                                            }
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                    <p className="mt-3">Показать рубрики по площадке: {destination}</p>
                                                                    
                                                                  </div>
              
                                                                      {sections.sections.map((item, index) => {
                                                                            if (destination === null){
                                                                                return (
                                                                                    <PSAdminItems key={index} item={item} index={index}/> 
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <>
                                                                                    {item.destination === destination &&
                                                                                        <PSAdminItems key={index} item={item} index={index}/>
                                                                                    }
                                                                                    </> 
                                                                                )
                                                                            }
                                                                                
                                                                      })}
                                                                    <ConfirmDeleteModal item={sections.selectedItem}/>
                                                                  </> 
                                                                : <Outlet/>
            }
            
        </Container>
    
    )
}

export default ManageSections;