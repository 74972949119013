import {axiosClient} from "../common/axios";
import Api from "../api/Api";

export const createTeacherInHistory = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.ADD_TEACHER_IN_HISTORY, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const updateTeacherInHistory = async (payload) => {
    try {

        const response = await axiosClient.put(Api.endpoints.UPDATE_TEACHER_IN_HISTORY, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const getTeachersInHistory = async () => {
    
    try {
        const response = await axiosClient.get(Api.endpoints.GET_TEACHERS_IN_HISTORY)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const getTeachersInHistoryById = async ({teacher_id}) => {
    try {
        const url = Api.endpoints.GET_TEACHER_HISTORY_BY_ID.replace('{teacher_id}', teacher_id);
        
        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const deleteTeacherInHistory = async ({teacher_id}) => {
    try {
        const url = Api.endpoints.DELETE_TEACHER_IN_HISTORY.replace('{teacher_id}', teacher_id);
        
        const response = await axiosClient.post(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}