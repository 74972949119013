import React, {useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import {Button, FormGroup, Input, Label, Container} from "reactstrap";
import {uploadFiles} from "../../../services/uploadFiles";
import {getStaticUrlFile} from "../../../common/helpers";
import InteractiveLessonsItem from '../../../Components/interactive-lessons/InteractiveLessonsItem';
import { changeCategoryInteractiveLesson, changeDescriptionInteractiveLesson, changeNameInteractiveLesson, changePreview, loadDetailInteractiveLessonAsync } from '../../../store/reducer/manage-interactive-lessons/detail-interactive-lesson';
import { updateInteractiveLesson } from '../../../services/InteractiveLessonsService';
import { toast } from "react-toastify";


const MAX_TITLE_LENGTH = 128;
const MAX_CATEGORY_LENGTH = 50;

const Title = () => {
    const detailInteractiveLesson = useSelector((state) => state.detailInteractiveLesson);
    const dispatch = useDispatch();
    
    const onChange = (event) => {
        
        const value = event.currentTarget.value || "";
        
        if (value.length > MAX_TITLE_LENGTH) return;
        
        dispatch(changeNameInteractiveLesson(value))
    }

    return (
        <FormGroup floating>
            <Input 
            placeholder={"Заголовок"} 
            value={detailInteractiveLesson.name} 
            onChange={onChange}
            id="title"
            />
            <Label for="title">
                Заголовок
            </Label>
        </FormGroup>
    )
}

const Description = () => {
    const detailInteractiveLesson = useSelector((state) => state.detailInteractiveLesson);

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";


        dispatch(changeDescriptionInteractiveLesson(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Описание"} 
            value={detailInteractiveLesson.description} 
            onChange={onChange}
            id="description"
        />
        <Label for="description">
            Описание
        </Label>
    </FormGroup>
    )
}

const Category = () => {
    const detailInteractiveLesson = useSelector((state) => state.detailInteractiveLesson);
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_CATEGORY_LENGTH) return;

        dispatch(changeCategoryInteractiveLesson(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Категория"} 
            value={detailInteractiveLesson.category} 
            onChange={onChange}
            id="category"
        />
        <Label for="category">
            Категория
        </Label>
    </FormGroup>
    )
}

const Preview = () => {
    const detailInteractiveLesson = useSelector((state) => state.detailInteractiveLesson);
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const inputRef = useRef(null);
    let preview
    const onClickPreviewHandler = () => inputRef?.current?.click();
    const onChangePreview = async (event) => {
        const files = event?.currentTarget?.files;
        
        if (files.length > 0) {
            
            setPreviewFile(files[0]);
            setFiles([files[0]]);
            
            const responseUpload = await uploadFiles({files: [files[0]]});
            preview = responseUpload?.data?.names[0] || "";
            dispatch(changePreview(getStaticUrlFile(preview)));
        }
    }
    const payload = {
        id:detailInteractiveLesson.id,
        title: detailInteractiveLesson.name,
        description: detailInteractiveLesson.description,
        preview_image_link: detailInteractiveLesson.preview_image_link,
        category: detailInteractiveLesson.category,
    }
    return (
        <div className='d-flex flex-column gap-1 w-100 mb-5'>
            <div className='d-flex justify-content-center'>
                <InteractiveLessonsItem  onEditorial={false} interactiveLesson={payload}/>
            </div>
            <Button onClick={onClickPreviewHandler} className='btn btn-primary '>Изменить изображение</Button>

            <input type={"file"}
                   accept={"image/*"}
                   ref={inputRef}
                   onChange={onChangePreview}
                   className={"d-none"}/>
        </div>
    )
}

const SaveButton = () => {

    const detailInteractiveLesson = useSelector((state) => state.detailInteractiveLesson);


    const saveInteractiveLessonInfo = async () => {

        const payload = {
            id:detailInteractiveLesson.id,
            name: detailInteractiveLesson.name,
            description: detailInteractiveLesson.description,
            preview_image_link: detailInteractiveLesson.preview_image_link,
            category: detailInteractiveLesson.category,
        }

        await updateInteractiveLesson(payload);
    }

    const onSaveHandler = async () => {
        try {
            await saveInteractiveLessonInfo();
            toast.success('Изменения сохранени!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }

    return <Button className='btn btn-primary' onClick={onSaveHandler}>Сохранить</Button>
}

const CancelButton = () => {

    const navigate = useNavigate()

    const onCancelHandler = () => navigate(0);

    return <Button onClick={onCancelHandler}>Отменить</Button>;
}


const EditInteractiveLesson = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    useEffect(() => {
        dispatch(loadDetailInteractiveLessonAsync(params.lessonId))
    }, []);
    
    return (
        <div className='w-100  mt-5'>
            <Container className='d-flex flex-column gap-3'>
                <div className="w-100 d-flex justify-content-between mb-4">
                    <h3>Изменить интерактивный урок</h3>
                    <Button className="btn" onClick={() => navigate('/editorial/manage-interactive-lessons')}>Назад</Button>
                </div>
                <Preview/>
                <Title/>
                <Description/>
                <Category/>
                <SaveButton/>
                <CancelButton/>
            </Container>
        </div>

    );
};

export default EditInteractiveLesson;