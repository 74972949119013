import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem, NavbarBrand,
  Button
} from "reactstrap";
import LottiePlayer from "../player";

function Footer() {
  const navigate = useNavigate();

  const onClick = () => {
    return
  }
  return (
    <div>
      <footer className="pt-11 pb-0 position-relative overflow-hidden">
        <Container className="z-index-1">
          <Row>
            <Col md={12} lg={4} className="pe-lg-7">
              <Link className="footer-logo" to="index">
                <img className="img-fluid" src="/images/logo.png" alt="" />
              </Link>
              <NavbarBrand className="navbar-brand logo">
                <img src="images/logo.svg" alt="" />
              </NavbarBrand>
              <p className="my-4">
                Цифровая социально-ориентированная платформа, позволяющая воплотить в массовую педагогическую практику принцип непрерывного образования всех участников образовательной экосистемы
              </p>
              {/* <ul className="media-icon list-unstyled">
                <ListGroupItem className="mb-2">
                  <Link to="mailto:prcom@asu.ru" className="h6">
                    prcom@asu.ru
                  </Link>
                </ListGroupItem>
                <ListGroupItem>
                  <Link to="tel:83852291222" className="h6">
                    +7 (3852) 291-222
                  </Link>
                </ListGroupItem>
              </ul> */}
            </Col>

            <Col md={6} lg={2} className="mt-6 mt-lg-0 ">
              <h5 className="px-2">Полезные ссылки</h5>
              <Nav className="navbar-nav list-unstyled mb-0">

                <NavItem className="">
                  <Link to="/teachers-in-history" className="nav-link">
                    Преподаватели
                  </Link>
                </NavItem>
                <NavItem className="">
                  <Link to="/explorations" className="nav-link">
                    Новости
                  </Link>
                </NavItem>
                <NavItem className="">
                  <Link to="/contact-us" className="nav-link">
                    Связаться с нами
                  </Link>
                </NavItem>
              </Nav>
            </Col>

            <Col md={6} lg={2} className="mt-6 mt-lg-0 footer-menu">
              <h5 className="px-2">Правовые ссылки</h5>
              <Nav className="navbar-nav list-unstyled mb-0">
                <NavItem className="">
                  <Link to="/faq" className="nav-link">
                    FAQ
                  </Link>
                </NavItem>

                <NavItem className="">
                  <Link to="/terms-and-conditions" className="nav-link">
                    Правила пользования
                  </Link>
                </NavItem>
              </Nav>
            </Col>
            <Col md={8} lg={4} className="mt-6 mt-lg-0">
              <h5 className="mb-4">Наш адрес</h5>
              <div className="mb-3 text-muted" style={{ fontSize: "16px" }}>
                ФГБОУ ВО «Алтайский государственный университет»
                656049, Барнаул, пр. Ленина, 61
              </div>

              {/* <div className="subscribe-form">
                <p className="mb-3 font-w-6 text-primary">
                  Подпишитесь на новости
                </p>
                <form
                  id="mc-form"
                  className="d-flex align-items-center shadow p-2 rounded bg-white justify-content-between"
                >
                  <input
                    type="email"
                    // value=""
                    name="EMAIL"
                    className="email form-control bg-light border-0 me-2 w-100"
                    id="mc-email"
                    placeholder="Введите e-mail"
                    required=""
                    style={{minWidth: '170px'}}
                  />
                  <Button
                    className="btn btn-dark btn-sm"
                    onClick={onClick}
                    name="Подписаться"
                  >Подписаться</Button>
                </form>
              </div> */}
            </Col>
          </Row>

        </Container>
        <div className="position-absolute animation-1 opacity-1">
          <LottiePlayer src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json" />
        </div>
      </footer>
    </div>
  );
}

export default Footer;
