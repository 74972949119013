import React from 'react';
import {FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import { useEmailContext } from '../../../context/ChangeEmailContext';
import { Button } from "reactstrap"
import CustomInput from '../../CustomInput/CustomInput';
import { toast } from "react-toastify";
import { useInitEmailChangeMutation } from '../../../api/settings/settingsApi';

const ValidationEmailScheme = Yup.object().shape({
    email: Yup.string().email().required(),
})

const StageDefault = () => {

    const [initEmailChange] = useInitEmailChangeMutation();

    const {
        setStage,
        currentEmail,
        setNewEmail,
        setToken,
        successChanged,
    } = useEmailContext();

    const formik = useFormik({
        validationSchema: ValidationEmailScheme,
        initialValues: {
            email: "",
        },
        onSubmit: (values, {setErrors}) => {
            const {email} = values;
            initEmailChange({email})
                .unwrap()
                .then((response) => {
                    setToken(response.token);
                    setNewEmail(email);
                    setStage("ConfirmationEmail");
                })
                .catch((error) => {
                    setErrors({email: error?.data?.detail});
                    if (error.status === 500)
                        setErrors({email: "Введите действительный и корректный адрес электронной почты"})
                });
        },
    })

    return (
        <FormikProvider value={formik}>
            <div className="d-flex flex-column mb-3">
                <p>Ваш текущий адрес электронной почты</p>
                <p className='fw-bold'>{currentEmail}</p>
            </div>

            {successChanged && toast.success('Подрубрика успешно добавлена', {
                                                position: "top-center",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            })}

            <div className='d-flex flex-column gap-2'>
                <CustomInput
                    placeholder={"Новый адрес электронной почты"}
                    name={"email"}
                    touched={formik.touched.email}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email}/>

                <div className='d-lxe flex-column gap-2'>
                    <p>Чтобы изменить свой адрес электронной почты, вам необходимо подтвердить код, отправленный на ваш предыдущий адрес электронной почты, </p>
                </div>
            </div>


            <Button type={"submit"} onClick={formik.submitForm}> Продолжить</Button>

        </FormikProvider>
    );
};

export default StageDefault;
