import Api from "../../Api";
import axios from "axios";

export const initSchoolStudentRegistration = async (schoolStudent) => {

    const data = {
        name: schoolStudent.name,
        surname: schoolStudent.surname,
        email: schoolStudent.email,
        region: schoolStudent.region,
        city: schoolStudent.city,
        institution: schoolStudent.institution,
        study_year: schoolStudent.study_year,
        phone_number: schoolStudent.phone_number,
        password: schoolStudent.password,
        confirm_password: schoolStudent.confirm_password,
    }


    let response = null;

    try {
        response = await axios.post(Api.endpoints.schoolStudent_SIGNUP, data);
    } catch (e) {
        return {
            status: e.response?.status || 0,
            token: "",
            errors: [],
            email: e.response?.data.email,
            phoneNumber: e.response?.data.phone_number,
            name: e.response?.data.name,
            surname: e.response?.data.surname,
            region: e.response?.data.region,
            city: e.response?.data.city,
            institution: e.response?.data.institution,
            study_year: e.response?.data.study_year,

        }
    }

    return {
        status: response.status,
        token: response.data.access_token,
        errors: Api.getErrors(response),
    }
}
