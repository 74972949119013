import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {createReactEditorJS} from "react-editor-js";
import {Plugins} from "../types";
import classNames from "classnames";
import {getTools} from "../helpers";

const EditorJS = createReactEditorJS();

const defaultPlugins = [
    Plugins.EMBED,
    Plugins.LIST,
    Plugins.ATTACHES,
    Plugins.IMAGE,
    Plugins.HEADER,
    Plugins.MARKED,
    Plugins.CHECK_LIST,
    Plugins.DELIMITER,
    Plugins.VIDEO
]

const defaultValues = {

}

export const TextEditor = (
    {
        containerClassName,
        initialValues = defaultValues,
        plugins = defaultPlugins,
        urlSaveContent,
        urlSaveImages,
        readOnly = false,
        width = "default",
        setRef,
    }
) => {

    const editorCore = useRef({})

    const handleInitialize = useCallback((instance) => {
        editorCore.current = instance;
        setRef && setRef(instance);
    }, [setRef])

    const classes = classNames('textEditor', {
        ['textEditor_width__unset']: width === "unset",
    }, containerClassName);

    const tools = useMemo(() => getTools(plugins), [plugins]);
    useEffect(() => {
        readOnly && Boolean(editorCore.current._editorJS.render) && void editorCore.current._editorJS.render(initialValues);
    }, [initialValues, readOnly]);

    return (
        <div className={classes}>
            <EditorJS onInitialize={handleInitialize}
                      defaultValue={initialValues}
                      readOnly={readOnly}
                      tools={tools}/>
        </div>
    );
}