import React from 'react';
import classNames from "classnames";



const HamburgerButton = (
    {
        onClick,
        className,
        ...props
    }
) => {

    const classes = classNames('HamburgerButton', className);

    const onClickHandler = () => onClick();

    return (
        <div {...props} className={classes} onClick={onClickHandler}>
            <span/>
            <span/>
            <span/>
        </div>
    );
};

export default HamburgerButton;
