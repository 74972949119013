import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import {Button, FormGroup, Input, Label, Container} from "reactstrap";
import {useMount} from "../../../hooks/hooks";
import {
    clearState,
    changeTitleProduct,
    changeProductId,
    changeContent,
    changePreview,
    changeCategoryProduct,
    changePriceProduct,
    changePriceWithDiscountProduct,
    changeShortDescriptionProduct,
    changeMediaProduct,
    loadDetailProductAsync
} from "../../../store/reducer/manage-products/detail-product";
import {uploadFiles} from "../../../services/uploadFiles";
import {getStaticUrlFile} from "../../../common/helpers";
import {TextEditor} from "../../../Components/EditorComponent/TextEditor/TextEditor";
import ProductCardHorizontal from '../../../Components/products/ProductCardHorizontal';
import ProductCardVertical from '../../../Components/products/ProductCardVertical';
import { updateProduct } from '../../../services/Products';
import { toast } from "react-toastify";
import DropZone from '../../../Components/DropZone/DropZone';
import FileItem from '../../../Components/DropZone/FileItem/FileItem';

const MAX_TITLE_LENGTH = 128;
const MAX_AUTHOR_LENGTH = 150;
const MAX_CATEGORY_LENGTH = 50;

function getFileNameFromUrl(url) {
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    return fileName;
}

const Title = () => {
    const detailProduct = useSelector((state) => state.detailProduct);
    const dispatch = useDispatch();
    
    const onChange = (event) => {
        const value = event.currentTarget.value || "";
        
        if (value.length > MAX_TITLE_LENGTH) return;
        
        dispatch(changeTitleProduct(value))
    }

    return (
        <FormGroup floating>
            <Input 
            placeholder={"Заголовок"} 
            value={detailProduct.title} 
            onChange={onChange}
            id="title"
            />
            <Label for="title">
                Заголовок
            </Label>
        </FormGroup>
    )
}

const ShortDescription = () => {
    const detailProduct = useSelector((state) => state.detailProduct);

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_AUTHOR_LENGTH) return;

        dispatch(changeShortDescriptionProduct(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Короткое описание"} 
            value={detailProduct.short_description} 
            onChange={onChange}
            id="shortDescription"
        />
        <Label for="shortDescription">
            Короткое описание
        </Label>
    </FormGroup>
    )
}

const Category = () => {
    const detailProduct = useSelector((state) => state.detailProduct);

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_CATEGORY_LENGTH) return;

        dispatch(changeCategoryProduct(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Категория"} 
            value={detailProduct.category} 
            onChange={onChange}
            id="category"
        />
        <Label for="category">
            Категория
        </Label>
    </FormGroup>
    )
}

const Price = () => {
    const dispatch = useDispatch();
    const detailProduct = useSelector((state) => state.detailProduct);
    const [isDiscounted, setIsDiscounted] = useState(false);

    const onPriceChange = (event) => {
        const value = event.currentTarget.value || "";
        // Проверка на ввод числа
        if (!isNaN(value)) {
            dispatch(changePriceProduct(value));
        }
    }

    const onPriceWithDiscountChange = (event) => {
        const value = event.currentTarget.value || "";
        // Проверка на ввод числа
        if (!isNaN(value)) {
            dispatch(changePriceWithDiscountProduct(value));
        }
    }

    const handleCheckboxChange = () => {
        setIsDiscounted(!isDiscounted);
    }

    return (
        <>
            <FormGroup floating>
                <Input 
                    id="price" 
                    placeholder={"Цена"} 
                    value={detailProduct.price}
                    type='text' 
                    onChange={onPriceChange} 
                    style={{ borderColor: isNaN(detailProduct.price) ? 'red' : '' }} 
                />
                <Label for="price">
                    Цена
                </Label>
            </FormGroup>
            <FormGroup check>
                <Input 
                    type="checkbox" 
                    onChange={handleCheckboxChange}
                />
                <Label check>
                    Со скидкой
                </Label>
            </FormGroup>
            <FormGroup floating>
                <Input 
                    id="price_with_discount" 
                    placeholder={"Цена со скидкой"} 
                    type='text' 
                    value={detailProduct.price_with_discount}
                    onChange={onPriceWithDiscountChange} 
                    disabled={!isDiscounted}
                    style={{ borderColor: isNaN(detailProduct.price_with_discount) && isDiscounted ? 'red' : '' }} 
                />
                <Label for="price_with_discount">
                    Цена со скидкой
                </Label>
                {isNaN(detailProduct.price_with_discount) && isDiscounted && (
                    <div style={{ color: 'red' }}>Введите число</div>
                )}
            </FormGroup>
        </>
    );
}

const Media = () => {
    const detailProduct = useSelector((state) => state.detailProduct);
    const dispatch = useDispatch();

    const [productfiles, setProductFiles] = useState([]);
    const [previewProductFile, setProductPreviewFile] = useState();
    
    
    const onChangeProductFiles = async (files) => {
        if (files.length > 0) {
            
            setProductPreviewFile(files[0]);
            setProductFiles([files[0]]);
            const responseUploadMedia = await uploadFiles({files: [files[0]]});
            const previewMedia = responseUploadMedia?.data?.names[0] || "";    
            dispatch(changeMediaProduct(getStaticUrlFile(previewMedia)));
        }
    }
    return (
        <div className='d-flex flex-column '>
            <p className='fw-bold'>Методические материалы</p>
            <DropZone files={productfiles} onChangeFiles={onChangeProductFiles}/>
            {detailProduct.media && productfiles.length === 0 && <FileItem fileName={getFileNameFromUrl(detailProduct.media)} link={detailProduct.media}/>}
        </div>
    )
}

const Preview = () => {
    const detailProduct = useSelector((state) => state.detailProduct);
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const inputRef = useRef(null);
    let preview;
    const onClickPreviewHandler = () => inputRef?.current?.click();
    const onChangePreview = async (event) => {
        const files = event?.currentTarget?.files;
        
        if (files.length > 0) {
            
            setPreviewFile(files[0]);
            setFiles([files[0]]);
            
            const responseUpload = await uploadFiles({files: [files[0]]});
            preview = responseUpload?.data?.names[0] || "";
            dispatch(changePreview(getStaticUrlFile(preview)));
        }
    }
    const payload = {
        id: detailProduct.id,
        title: detailProduct.title,
        creation_time: detailProduct.creation_time,
        category: detailProduct.category,
        short_description: detailProduct.short_description,
        preview_image_link: detailProduct.preview_image_link,
        price: detailProduct.price,
        price_with_discount: detailProduct.price_with_discount,
        content: detailProduct.content,
        
    }
    return (
        <div className='d-flex flex-column gap-1 w-100 mb-5'>
            <div className='d-flex justify-content-center flex-column'>
                <ProductCardHorizontal  onEditorial={false} item={payload}/>
                <ProductCardVertical  item={payload}/>
            </div>
            <Button onClick={onClickPreviewHandler} className='btn btn-primary '>Изменить изображение</Button>

            <input type={"file"}
                   accept={"image/*"}
                   ref={inputRef}
                   onChange={onChangePreview}
                   className={"d-none"}/>
        </div>
    )
}

const ArticleText = () => {
    const [mounted] = useMount();
    const [message, setMessage] = useState(undefined);

    const ref = useRef({});
    const detailProduct = useSelector((state) => state.detailProduct);


    const {content} = detailProduct

    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setMessage(undefined);
        }, 5 * 1000);

        return () => clearTimeout(timeoutId);
    }, []);

    if (!mounted || !content) return null;

    const values = Boolean(content) ? content : undefined;

    return (
        <div className="d-flex w-100 flex-column mt-4 " styles={{backGround:"#000"}}>
            <div>
                <h6>Подробное описание</h6>
                <div className="border rounded-4 mb-4">
                    <TextEditor  setRef={setRef} initialValues={values}/>
                </div>
            </div>

            <div className={"d-flex w-100 gap-3 mb-4"}>

                {message && <p className="fw-bold" styles={{color:message.color}} >{message.text}</p>}

                <div className="d-flex w-100 gap-2">
                    <SaveButton editorRef={ref} setMessage={setMessage}/>
                    <CancelButton/>
                </div>
            </div>
        </div>
    )
}

const SaveButton = ({ editorRef }) => {

    const detailProduct = useSelector((state) => state.detailProduct);


    const saveProductInfo = async () => {
        const content = await editorRef?.current?.save();
        
  
        
        const payloadProduct = {
            id: detailProduct.id,
            title: detailProduct.title,
            category: detailProduct.category,
            short_description: detailProduct.short_description,
            preview_image_link: detailProduct.preview_image_link,
            price: detailProduct.price,
            price_with_discount: detailProduct.price_with_discount,
            content: content,
            media: detailProduct.media
        }
        
        const response = await updateProduct(payloadProduct);
        if( response.success ){
            toast.success('Изменения сохранены!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });  
        }
    }

    return <Button className='btn btn-primary' onClick={saveProductInfo}>Сохранить</Button>
}

const CancelButton = () => {

    const navigate = useNavigate()

    const onCancelHandler = () => navigate(0);

    return <Button onClick={onCancelHandler}>Отменить</Button>;
}


const EditProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    
    useEffect(() => {
        dispatch(loadDetailProductAsync(params.productId))
    }, []);
    
    return (
        <div className='w-100  mt-5'>
            <Container className='d-flex flex-column gap-3'>
                <div className="w-100 d-flex justify-content-between mb-4">
                    <h3>Изменить онлайн лекторий</h3>
                    <Button className="btn" onClick={() => navigate('/editorial/manage-products')}>Назад</Button>
                </div>
                <Preview/>
                <Title/>
                <ShortDescription/>
                <Category/>
                <Price/>
                <Media/>
                <ArticleText/>
            </Container>
        </div>

    );
};

export default EditProduct;