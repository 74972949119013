import OnlineLectureIcon from "../../../assets/icons/ic-online-lecture.svg";
import ShopIcon from "../../../assets/icons/ic-shop.svg";
import InteractiveLessonsIcon from "../../../assets/icons/ic-lesson.svg";
import RequestIcon from "../../../assets/icons/ic-request.svg";
import SectionIcon from "../../../assets/icons/ic-section.svg";
import SubsectionIcon from "../../../assets/icons/ic-subsection.svg";
import ArticleIcon from "../../../assets/icons/ic-article.svg";
import EducationProgramIcon from "../../../assets/icons/ic-education-program.svg";
import TeachersSuccessIcon from "../../../assets/icons/ic-teachers-success.svg";
import SliderIcon from "../../../assets/icons/ic-slider.svg";
import ConsultIcon from "../../../assets/icons/ic-consult.svg";

export const SIDEBAR_DATA = [
  {
    name: "Слайдер",
    path: "edit-slider-images",
    icon: SliderIcon,
  },
  {
    name: "Педагогический успех",
    path: "manage-teachers-in-history",
    icon: TeachersSuccessIcon,
  },
  {
    name: "Онлайн лекторий",
    path: "manage-online-lectures",
    icon: OnlineLectureIcon,
  },
  {
    name: "Программы обучения",
    path: "manage-education-programs",
    icon: EducationProgramIcon,
  },
  {
    name: "Методические материалы",
    path: "manage-products",
    icon: ShopIcon,
  },
  {
    name: "Интерактивные уроки",
    path: "manage-interactive-lessons",
    icon: InteractiveLessonsIcon,
  },
  {
    name: "Запросы на интерактивные уроки",
    path: "manage-interactive-lessons-requests",
    icon: RequestIcon,
  },
  {
    name: "Запросы на консультацию психолога",
    path: "manage-psychological-consult",
    icon: ConsultIcon,
  },
  {
    name: "Рубрики",
    path: "manage-sections",
    icon: SectionIcon,
  },
  {
    name: "Подрубрики",
    path: "manage-subsections",
    icon: SubsectionIcon,
  },
  {
    name: "Статьи",
    path: "manage-articles",
    icon: ArticleIcon,
  },
  
];
