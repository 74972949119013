import React from 'react';
import classNames from "classnames";


const Loader = (
    {
        size,
        className,
        margin = "default",
    }
) => {

    const classes = classNames('Loader', {
        [`Loader_auto`]: margin === "auto",
    }, className);

    const style = size ? {width: `${size}px`, height: `${size}px`} : undefined;

    return (
        <div className={classes} style={style} aria-label={"Загрузка..."}/>
    );
};

export default React.memo(Loader);