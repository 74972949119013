import React from "react";
import { useNavigate } from 'react-router-dom'
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import { useIsMobile } from "../../hooks/hooks";

const SectionItem = ({item, index}) => {
    const navigate = useNavigate();
    const {isMobile} = useIsMobile();
    const handleSectionSelect = (section) => {
      navigate(`${section.id}`)
    }
    return (
        <>
          <Card 
          key={index} 
          className={
            `d-flex  p-4 search-card search-card-hover  
            ${isMobile ? 
                      "w-320 justify-content-center flex-column" 
                      : `flex-row  w-75 ${index % 2 === 0 ? "align-self-end flex-row-reverse" : ""}
            `}  
          `} 
          onClick={() => handleSectionSelect(item)}>
            <CardImg
            className="rounded-4 object-fit-cover"
            style={isMobile ? {height: 270,width: "100%"} : {height: 156,width: 156} }
            src={item.preview_image_link} 
            />
            <CardBody>
              <CardTitle className="fw-bold fs-5 mb-0">{item.title}</CardTitle>
              {item.show_description && <CardText>{item.description}</CardText>}
            </CardBody>
          </Card>
        </>
        
    )
}

export default SectionItem;
