import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {Input, Button, Container} from 'reactstrap'
import {FormikProvider} from "formik";
import {getStaticUrlFile} from "../../../common/helpers";
import {uploadFiles} from "../../../services/uploadFiles";
import DropZone from '../../../Components/DropZone/DropZone';
import { TextEditor } from '../../../Components/EditorComponent/TextEditor/TextEditor';
import { createTeacherInHistory } from '../../../services/TeachersInHistory';
import { changeName, changePosition } from '../../../store/reducer/manage-teachers-in-history/detail-teachers-in-history';
import { toast } from "react-toastify";


const Name = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changeName(value));
    }

    return (
        <Input placeholder={"ФИО"} type='text' onChange={onChange}/>
    )
}

const Position = () => {

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changePosition(value));
    }

    return <Input placeholder={"Должность"} type='text' onChange={onChange}/>
}

const AddTeacherInHistory = () => {
    
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const detailTeacherInHistory = useSelector((state) => state.detailTeacherInHistory)
    const ref = useRef({});
    const navigate = useNavigate();
    
    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);
    
    const onChangeFiles = (files) => {
        if (files.length > 0) {
            setPreviewFile(files[0]);
            setFiles([files[0]]);
        }
    }
   
    const onAddTeacherInHistory = async() => {
        const responseUpload = await uploadFiles({files: [previewFile]});
        const preview = responseUpload?.data?.names[0] || "";
        const content = await ref.current.save();
        
        const payload = {
            name: detailTeacherInHistory.name,
            position: detailTeacherInHistory.position,
            image_link: getStaticUrlFile(preview),
            content: content
        }
        
        if(payload.name === "" || payload.position === "" || payload.image_link === "" ){
            toast.warn('Проверьте, все ли поля заполнены', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return; 
        }
        
        const response = await createTeacherInHistory(payload);
        if( response.success ){
            toast.success('Статья успешно добавлена', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
            
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }
   

    return (
        
        <Container>
            <div className="w-100 d-flex justify-content-between mb-4">
                <h3>Добавить педагогический успех</h3>
                <Button className="btn" onClick={() => navigate('/editorial/manage-teachers-in-history')}>Назад</Button>
            </div>
            <FormikProvider>
                <form>
                    <div className='d-flex w-100 flex-column gap-3 p-4'>

                        <div className='d-flex w-100 flex-column gap-3 '>
                            <Name/>
                            <Position/>
                            <div className='d-flex flex-column w-100'>
                                <p className='fw-bold'>Изображение перподавателя</p>
                                <DropZone files={files} onChangeFiles={onChangeFiles}/>
                            </div>
                            <div className="d-flex w-100 flex-column mt-4 border rounded" styles={{backGround:"#000"}}>
                                <TextEditor setRef={setRef} />
                            </div>
                        </div>

                        <div className='d-flex w-100 gap-1'>
                            <Button className='btn btn-primary'  onClick={onAddTeacherInHistory}>Создать</Button>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </Container>
    );
};

export default AddTeacherInHistory;