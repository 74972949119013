import React from "react";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import MainImage from "../../assets/images/teachersToSchool/main1.jpg";
import LottiePlayer from "../player";
import Bg from "../../assets/lottie/counter.json";
import TeachersToSchoolCard from "./TeacherToSchoolCard";

const TeachersToSchool = () => {
    const cardData = [
        {
          title: "Онлайн-лекторий",
          link: "/online-lectures"
        },
        {
          title: "Методические материалы в помощь учителю",
          link: "/products"
        },
        {
          title: "Интерактивные уроки ученых АлтГУ",    
          link: "/interactive-lessons"
        },
      ];
      
  return (
    <div>
      <section className="px-lg-7 px-2 pb-0 pt-0">
        <div className="bg-light py-10 px-3 px-lg-8 rounded-4 position-relative overflow-hidden z-index-0">
          <Container>
            <Row className="align-items-end mb-3">
              <Col>
              <Fade left>
                <h2 className="mb-0">Ученые университета - школе</h2> 
                </Fade>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col  xs={12} md={8}>
              <Fade left>
                <p className="lead z-index-1">
                  Сегодня АлтГУ – один из ведущих научных и инновационно-образовательных центров Сибирского региона. Ученые университета работают над решением ряда крупных научных проблем в самых разных областях: генетики и молекулярной биологии, сельского хозяйства и биологической науки, физики и астрономии, биохимии, экологии, химии, материаловедения, математики, социальных наук, информатики, археологии и истории.
                </p>
                <p className="z-index-1"> 
                  Результаты этих исследований представлены в нашем <Link to="/online-lectures">онлайн-лектории</Link> и могут быть включены в содержание школьного образования. Мы используем ресурс наших лучших преподавателей, которые предлагают учителю инновационные методики в преподавании различных <Link to="/products">школьных предметов</Link>. Участников платформы ждут <Link to="/interactive-lessons">интерактивные уроки </Link>, которые проведут ученые АлтГУ для школьников.
                </p>
                </Fade>
                <Row className="gx-5 justify-content-center">
                  {cardData.map((data, index) => (
                    <Col
                      style={{
                        marginTop: "2.5rem",
                        "@media screen and (minWidth: 700px)": { marginTop: 0 },
                      }}
                      lg={5}
                      md={6}
                      key={index}
                    >
                      <Zoom >
                        <TeachersToSchoolCard
                          title={data.title}
                          link={data.link}
                        />
                      </Zoom>
                      
                    </Col>
                  ))}
                </Row>
                <div className="position-absolute animation-1 w-50">
                    <LottiePlayer src={Bg} />
                  </div>
              </Col>
              <Col xs={12} md={4} className="d-none d-md-block">
                <Fade right>
                  <img className="h-600 w-100 rounded object-fit-cover" src={MainImage} alt="" />
                </Fade>
              </Col>    
            </Row>
          </Container>
        </div>
      </section>
    </div>
  )
}

export default TeachersToSchool;