import React from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import { Container, Row, Col, Button } from "reactstrap";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";

function tAndC() {
  const firstBreadcrumb = { label: "", link: "/terms-and-conditions" };
  const secondBreadcrumb = {
    label: "Правила и условия",
    link: "/terms-and-conditions",
    active: true,
  };
  const data = [
    {
      title: "Описание Платформы:",
      content:
        `Платформа "Учитель будущего" представляет собой цифровую социально-ориентированную платформу, целью которой является содействие внедрению принципа непрерывного образования в педагогическую практику. Платформа предоставляет участникам образовательной экосистемы доступ к разнообразным образовательным материалам, курсам, обмену опытом и сотрудничеству с другими участниками.`,
    },
    {
      title: "Регистрация:",
      content:
        `Для доступа ко всем функциям Платформы необходимо пройти процесс регистрации. При регистрации вы обязуетесь предоставить точную и полную информацию о себе и поддерживать ее актуальность.`,
    },
    {
      title: "Конфиденциальность: ",
      content:
        `Мы ценим вашу конфиденциальность и обязуемся защищать ваши персональные данные в соответствии с нашей Политикой конфиденциальности. Пользуясь Платформой, вы соглашаетесь с условиями этой Политики.`,
    },
    {
      title: "Права и обязанности пользователей:",
      content:
       `Вы обязуетесь использовать Платформу только в законных целях и соблюдать правила общения с другими участниками. Вы несете ответственность за содержание вашего профиля и размещаемый вами контент.`,
    },
    {
      title: "Интеллектуальная собственность:",
      content:
        `Весь контент, предоставленный на Платформе, защищен законами об интеллектуальной собственности. Вы обязуетесь соблюдать авторские права и не использовать контент без соответствующих разрешений.`,
    },
    {
      title: "Изменения в Условиях:",
      content:
        `Мы оставляем за собой право вносить изменения в эти Условия время от времени. Изменения вступают в силу с момента их публикации на Платформе. Ваше дальнейшее использование Платформы после таких изменений подтверждает ваше согласие с обновленными Условиями.`,
    },
  ];
  return (
    <div className="page-wrapper">
      <Page_Heading
        title="Правила и условия"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <section>
        <Container>
          <Row>
            <Col lg="12" md="12">
              {data.map((section, index) => (
                <div key={index}>
                  <h4 className="text-dark mt-5">{section.title}</h4>
                  <p className="mb-3">{section.content}</p>
                  {section.items && section.items.length > 0 && (
                    <React.Fragment>
                      {section.items.map((item, itemIndex) => (
                        <div
                          className="d-flex align-items-center mb-3"
                          key={itemIndex}
                        >
                          <div className="bg-light rounded px-2 py-1">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-check"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </div>
                          <p className="mb-0 ms-3">{item}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                </div>
              ))}
              {/* <div className="d-flex mt-5">
                <Button color="primary" className="me-1">
                  Accept
                </Button>
                <Button color="dark">Close</Button>
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
      <RegisterBanner />
      );
    </div>
  );
}

export default tAndC;
