import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Col,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedTeacher } from "../../store/reducer/teachersInHistoryReducer";


const TeacherCard = ({ item, onEditorial }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onItemClick = (editItem) => {
    navigate(`${editItem.id}`)
  }
  const handleDeleteClick = () => {
    dispatch(setSelectedTeacher(item))
  }
  return (
          <Card className={`${onEditorial ? "" : "hover-translate"}   bg-white shadow px-3 py-3 rounded-4 d-flex justify-content-between`} style={{width: "340px"}}>
          {onEditorial && 
            <div className="mb-2 d-flex justify-content-end gap-2">
              <Button className="btn btn-link text-primary-light p-0"  onClick={() => onItemClick(item)}>Редактировать</Button>
              <Button className="btn btn-link text-danger p-0" data-bs-toggle="modal" data-bs-target="#confirmDelete" onClick={handleDeleteClick}>Удалить</Button>
            </div>
          }
            <div className="d-flex align-items-center flex-column">
              <h6 className="mb-0 me-2">
                <Link
                  className="btn-link"
                  to={`/teachers-in-history/${item.id}`}
                >
                  {item.name}
                </Link>
              </h6>
              <span className="text-muted fs-6">- {item.position}</span>
            </div>
            <div className="mt-3 mb-4">
              <CardImg
                top
                width="100%"
                height="320px"
                src={item.image_link}
                alt={item.name}
                className="rounded-4 object-fit-cover"
              />
            </div>
            
            
          </Card>
  );
};
export default TeacherCard;
