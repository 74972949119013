import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getExplorations } from "../../services/ExplorationsService";


const initialState = {
  explorations:[],
  selectedExploration: {},
}

export const loadExplorationsAsync = createAsyncThunk (
  "loadExplorationsAsync",
  async() => {
    
      const response = await getExplorations();
      if (response.success) return response.data;
      else return undefined;
  }
)

const explorationSlice = createSlice({
  name: "exploration",
  initialState,
  reducers: {
    setSelectedExploration(state, action) {
      state.selectedExploration = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadExplorationsAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.explorations = action.payload || []
        }
    })
  }     
});

export const { setSelectedExploration } = explorationSlice.actions;

export default explorationSlice.reducer;
