import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import Settings from "../Settings/Settings";
const Account = () => {
    
    return (
            <Container className="position-relative">
                <Settings/>
            </Container>
        )
}

export default Account;