import React from "react";
import {Link} from "react-router-dom"

import "../styles.css";

export default function SlideImage({ src, alt, link }) {
  return (
  <>
    {link ? 
          <Link to={link} className="cursor-pointer">
            <img src={src} alt={alt} className="slide-image" />
          </Link> 
          : 
          <img src={src} alt={alt} className="slide-image" />}
  </>
    
  )
}
