import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getAllSections, getSubsections, getArticles, getArticleDetail } from "../../services/PedagogicalSearchService";

const initialState = {
    sections:[],
    subsections:[],
    articles:[],
    selectedSection: {},
    selectedSubsection: {},
    selectedArticle: {},
    selectedItem: {}
}


export const loadAllSectionsAsync = createAsyncThunk (
    "loadAllSectionsAsync",
    async() => {
        const response = await getAllSections();
        if (response.success) return response.data;
        else return undefined;
    }
)
export const loadSubsectionsAsync = createAsyncThunk (
    "loadSubsectionsAsync",
    async(sectionId) => {
        const response = await getSubsections(sectionId);
        if (response.success) return response.data;
        else return undefined;
    }
)
export const loadArticlesAsync = createAsyncThunk (
    "loadArticlesAsync",
    async(subsectionId) => {
        const response = await getArticles(subsectionId);
        if (response.success) return response.data;
        else return undefined;
    }
)


const pedagogicalSearchSlice = createSlice({
    name: "pedagogicalSearch",
    initialState,
    reducers: {
        clearState: () => ({...initialState}),
        setSelectedSection: (state, action) => {
            state.selectedSection = action.payload;
        },
        setSelectedSubsection: (state, action) => {
            state.selectedSubsection = action.payload;
        },
        setSelectedArticle: (state, action) => {
            state.selectedArticle = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadAllSectionsAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const {
                    sections
                } = action.payload
                state.sections = action.payload || []
            }
        })    
        builder.addCase(loadSubsectionsAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const {
                    subsections
                } = action.payload
                state.subsections = action.payload || []
            }
        })    
        builder.addCase(loadArticlesAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const {
                    articles
                } = action.payload
                state.articles = action.payload || []
            }
        })    
           
    }
})

export const {
    clearState,
    setSelectedSection,
    setSelectedSubsection,
    setSelectedArticle,
    setSelectedItem
} = pedagogicalSearchSlice.actions;

export const selectPedagogicalSearch = (state) => state.pedagogicalSearch;
export default pedagogicalSearchSlice.reducer;