import React, {useEffect} from "react";
import {Container} from "reactstrap"
import ArticleItem from "../../Components/PedagogicalSearch/ArticleItem";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams, useLocation, Outlet} from 'react-router-dom';
import {loadArticlesAsync} from "../../store/reducer/pedagogicalSearchReducer"
import PageHeading from "../../Components/page-heading/Page_Heading";

const Articles = () => {
  const articles = useSelector((state) => state.pedagogicalSearch.articles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  useEffect(() => {
    if(params.subsectionId){
      dispatch(loadArticlesAsync(params.subsectionId))
    }else if(params.PaCsectionId){
      dispatch(loadArticlesAsync(params.PaCsectionId))
    }
  }, [])
  
  const firstBreadcrumb = { label: "Рубрики", link: params.subsectionId ? "/sections" : "/parents-and-childrens" };
  const secondBreadcrumb = {
    label: "Статьи",
    link: "/",
    active: true,
  };
  const thirdBreadcrumb = {
    label: "Подрубрики",
    link: `/sections/${params.sectionId}`,
    active: true,
  };
  if (location.pathname === `/sections/${params.sectionId}/${params.subsectionId}`) {
    return (
      <>
        <PageHeading
          title="Педагогический поиск: творчество без границ"
          firstBreadcrumb={firstBreadcrumb}
          thirdBreadcrumb={thirdBreadcrumb}
          secondBreadcrumb={secondBreadcrumb}
        />
        <div>
          <Container className="d-flex flex-column gap-3 pb-10" >
            {articles.map((item, index) => (
              <ArticleItem item={item} index={index} key={index} />
            ))}
          </Container>
        </div>
      </>
    )
  } else if (location.pathname === `/parents-and-childrens/${params.PaCsectionId}`) {
    return (
      <>
        <PageHeading
          title="Родители и дети: территория взаимопонимания"
          firstBreadcrumb={firstBreadcrumb}
          secondBreadcrumb={secondBreadcrumb}
        />
        <div>
          <Container className="d-flex flex-column gap-3 pb-10" >
            {articles.map((item, index) => (
              <ArticleItem item={item} index={index} key={index} />
            ))}
          </Container>
        </div>
      </>
    )
  } else {
    return (
      <Outlet />
    )
  }
}

export default Articles;