import React, {useEffect, useRef, useState} from "react";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Container, Row, Col } from "reactstrap";
import LottiePlayer from "../player";
import Bg from "../../assets/lottie/counter.json";
import Books from "../../assets/icons/Counter/books.svg"
import Desk from "../../assets/icons/Counter/desk.svg"
import HandShake from "../../assets/icons/Counter/handshake.svg"

const initialState = {
  courses: 1,
  percent: 1,
  internship: 1,
}

const maxValues = {
  percent: 78,
  internship: 2000,
  courses: 300,
}


function Counter() {
  const [statistic, setStatistic] = useState(initialState);
  const ref = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        startInterval();
      } else {
        stopInterval();
      }
    };

    const observer = new IntersectionObserver(handleObserver);
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  const startInterval = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (statistic.percent < maxValues.percent) setStatistic({
          ...statistic, percent: statistic.percent++
        })
        if (statistic.internship < maxValues.internship) setStatistic({
          ...statistic,
          internship: statistic.internship += 20
        })
        else {
          setStatistic({
            ...statistic,
            internship: maxValues.internship,
          })
        }
        if (statistic.courses < maxValues.courses) setStatistic({
          ...statistic,
          courses: statistic.courses += 2
        })

        if (statistic.percent >= maxValues.percent
            && statistic.courses >= maxValues.courses
            && statistic.internship >= maxValues.internship) {
        }
      }, 15);
    }
  }

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }

  const {courses, percent, internship} = statistic;

  return (
    <div>
      <section ref={ref}>
        <Container className="container">
          
            <Row className="align-items-center justify-content-between">
              <Col
                xs={12}
                xl={5}
                lg={6}
                className="mb-8 mb-lg-0 position-relative"
              >
                <Row className="align-items-center text-center z-index-1">
                  <Col lg={6} md={6}>
                  <Zoom>
                    <div className="counter bg-white rounded-4 p-5 py-7 shadow">
                      <span className="number count text-dark" data-count="300">
                        {courses}
                      </span>
                      <span className="text-dark">+</span>
                      <h6 className="mb-0 text-muted">учебных курсов от экспертов</h6>
                    </div>
                    </Zoom>
                  </Col>
                  <Col lg={6} md={6} className="mt-5">
                  <Zoom>
                    <div className="counter bg-white rounded-4 p-5 py-7 shadow">
                      <span className="number count text-dark" data-count="78">
                        {percent}
                      </span>
                      <span className="text-dark">%</span>
                      <h6 className="mb-0 text-muted">процент трудоустройства</h6>
                    </div>
                    </Zoom>
                    <Zoom>
                    <div className="counter bg-white rounded-4 p-5 py-7 shadow mt-7">
                      <span className="number count text-dark" data-count="2000">
                        {internship}
                      </span>
                      <span className="text-dark">+</span>
                      <h6 className="mb-0 text-muted">успешных стажировок ежегодно</h6>
                    </div>
                    </Zoom>
                  </Col>
                </Row>
                <div className="position-absolute animation-1 opacity-1">
                  <LottiePlayer src={Bg} />
                </div>
              </Col>
              <Col xs={12} xl={6} lg={6}>
                <Fade right>
                <div>
                  <h3>Современное обучение и карьерная поддержка для будущих педагогов</h3>
                  <p className="lead mb-4">
                    Современные методики, практические стажировки и помощь в трудоустройстве - наши ключевые преимущества для будущих учителей
                  </p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="me-3">
                    <img src={Books} alt=""/>
                  </div>
                  <div>
                    {/*<h6 className="mb-2">Collaborate on ideas 7x faster</h6>*/}
                    <p className="mb-0">
                      Более 300 учебных курсов по инновационным методикам преподавания от лучших российских и зарубежных экспертов.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="me-3">
                    <img src={Desk} alt=""/>
                  </div>
                  <div>
                    {/*<h6 className="mb-2">Easy ways to implement</h6>*/}
                    <p className="mb-0">
                      Возможность пройти стажировку в одной из 500 партнерских школ по всей России. Более 2 000 успешных стажировок ежегодно.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className="me-3">
                    <img src={HandShake} alt=""/>
                  </div>
                  <div>
                    {/*<h6 className="mb-2">Easy ways to implement</h6>*/}
                    <p className="mb-0">
                      Помощь в трудоустройстве от профориентации до подбора вакансий. 78% выпускников трудоустраиваются в течение года после обучения.
                    </p>
                  </div>
                </div>
                </Fade>
              </Col>
            </Row>
          
        </Container>
      </section>
    </div>
  );
}

export default Counter;
