import React, {useEffect} from "react";
import { Card, CardImg, CardBody, CardTitle, CardText, Container} from 'reactstrap';
import {useSelector, useDispatch} from "react-redux";
import {loadAllSectionsAsync, loadArticlesAsync} from "../../store/reducer/pedagogicalSearchReducer"

const SubsectionsList = ({ setShowSubsectionList}) => {
    const subsections = useSelector((state) => state.pedagogicalSearch.subsections);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(loadAllSectionsAsync())
    }, [])
    
    const handleSubsectionSelect = (subsection) => {
        localStorage.setItem('selectedEditSubsection', JSON.stringify(subsection));
        setShowSubsectionList(false)
        dispatch(loadArticlesAsync(subsection.id))
    }
    
    return (
        
                <Container className="d-flex gap-3 flex-column px-10">
                    <div className="w-100  mb-4">
                        <h3>Выберите Подрубрику</h3>
                    </div>
                    {subsections.map((item, index) => (
                        <Card key={index} className="d-flex flex-row p-4 search-card search-card-hover" onClick={() => handleSubsectionSelect(item)}>
                                <CardImg
                                className="rounded-4 object-fit-cover"
                                width="156px"
                                height="156px"    
                                style={{
                                    height: 156,
                                    width: 156
                                    }}
                                    src={item.preview_image_link} 
                                    />
                            <CardBody>
                                <CardTitle className="d-flex justify-content-between align-items-start">
                                        {item.title}
                                </CardTitle>
                                {item.show_description && <CardText>{item.description}</CardText>}
                            </CardBody>
                        </Card>
                        ))}
                </Container>
      
    )
}

export default SubsectionsList;