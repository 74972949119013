import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate} from "react-router-dom";
 
import { useSelector, useDispatch } from "react-redux";
import {
    Input, 
    Button, 
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap'
import {FormikProvider} from "formik";
import {getStaticUrlFile} from "../../../common/helpers";
import {uploadFiles} from "../../../services/uploadFiles";
import DropZone from '../../../Components/DropZone/DropZone';
import { toast } from "react-toastify";
import {
    clearState,
    changeTitleEducationProgram,
    changeDescriptionEducationProgram,
    changeEducationProgramUrl,
    changeTypeEducationProgram,
    changePreview
} from "../../../store/reducer/manage-education-programs/detail-education-program";
import { createEducationProgram } from '../../../services/EducationProgram';


export const typeItems = [
    {
        title: "Бакалавриат"
    },
    {
        title: "Магистратура"
    },
    {
        title: "Аспирантура"
    },
    {
        title: "ДПО"
    }
]

const Title = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changeTitleEducationProgram(value));
    }

    return (
        <Input placeholder={"Заголовок"} type='text' onChange={onChange}/>
    )
}

const Description = () => {

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changeDescriptionEducationProgram(value));
    }

    return <Input placeholder={"Описание"} type='text' onChange={onChange}/>
}

const ProgramUrl = () => {

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changeEducationProgramUrl(value));
    }

    return <Input placeholder={"Ссылка на программу"} type='text' onChange={onChange}/>
}


const Type = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);
    
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    
    const onItemClick = (item) => {
        dispatch(changeTypeEducationProgram(item))
    }
    
    return (
        <div className="d-flex flex-column">
            
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
                <DropdownToggle caret>Выберите уровень образования</DropdownToggle>
                <DropdownMenu>
                    {
                        typeItems.map((item) => (
                            <DropdownItem onClick={() => onItemClick(item.title)}>{item.title}</DropdownItem>
                        ))
                    }
                </DropdownMenu>
            </Dropdown>
            <p className='mt-3'>Уровень образования: <strong>{detailEducationProgram.type}</strong></p>

        </div>
    )
}
const AddEducationProgram = () => {
    
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const [previewFile, setPreviewFile] = useState();
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);

    const navigate = useNavigate();
  
    
    const onChangeFiles = (files) => {
        if (files.length > 0) {
            setPreviewFile(files[0]);
            setFiles([files[0]]);
        }
    }
   
    const onAddEducationProgram = async() => {
        const responseUpload = await uploadFiles({files: [previewFile]});
        const preview = responseUpload?.data?.names[0] || "";
        
        const payload = {
            title: detailEducationProgram.title,
            description: detailEducationProgram.description,
            type: detailEducationProgram.type,
            preview_image_link: getStaticUrlFile(preview),
            program_url: detailEducationProgram.program_url
        }
        if(payload.title === "" || payload.description === "" || payload.type === "" ){
            toast.warn('Проверьте, все ли поля заполнены', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return; 
        }
        
        const response = await createEducationProgram(payload);
        if( response.success ){
            toast.success('Программа успешно добавлена', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
            
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }
   

    return (
        
        <Container>
            <div className="w-100 d-flex justify-content-between mb-4">
                <h3>Добавить программу обучения</h3>
                <Button className="btn" onClick={() => navigate('/editorial/manage-education-programs')}>Назад</Button>
            </div>
            <FormikProvider>
                <form>
                    <div className='d-flex w-100 flex-column gap-3 p-4'>

                        <div className='d-flex w-100 flex-column gap-3 '>
                            <Title/>
                            <Description/>
                            <ProgramUrl/>
                            <Type/>
                            <div className='d-flex flex-column w-100'>
                                <p className='fw-bold'>Изображение для заголовка</p>
                                <DropZone files={files} onChangeFiles={onChangeFiles}/>
                            </div>
                        </div>

                        <div className='d-flex w-100 gap-1'>
                            <Button className='btn btn-primary'  onClick={onAddEducationProgram}>Создать</Button>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </Container>
    );
};

export default AddEducationProgram;