import React, {useEffect} from "react";
import {Container, Button} from "reactstrap"
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useLocation, Outlet} from "react-router-dom";
import { loadInteractiveLessonsAsync } from "../../../store/reducer/interactiveLessonsReducer";
import ConfirmDeleteModal from "../../../Components/Editorial/ConfirmDeleteModal";
import InteractiveLessonsItem from "../../../Components/interactive-lessons/InteractiveLessonsItem";

const ManageInteractiveLessons = () => {
    const interactiveLessons = useSelector((state) => state.interactiveLessons);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();
    useEffect(() => {
      dispatch(loadInteractiveLessonsAsync())
    }, [location.pathname])
    
    const onCreateClick = () => {
        navigate("add-interactive-lesson")
    }
    
    return (
        
        <Container className="d-flex gap-3 flex-column px-10">
            {location.pathname === "/editorial/manage-interactive-lessons" ?
                                                                        <>
                                                                        <div className="w-100 d-flex justify-content-between mb-4">
                                                                            <h3>Интерактивные уроки</h3>
                                                                            <Button className="btn btn-primary" onClick={onCreateClick}>Создать</Button>
                                                                        </div>
                                                                        <div className="d-flex flex-column gap-3">
                                                                            {interactiveLessons.interactiveLessons.map((item, index) => (
                                                                                <InteractiveLessonsItem key={index} interactiveLesson={item} onEditorial/>
                                                                            ))}
                                                                        </div>
                                                                        <ConfirmDeleteModal item={interactiveLessons.selectedInteractiveLesson}/>
                                                                        </> 
                                                                    : <Outlet/>
            }
        </Container>
      
    )
}

export default ManageInteractiveLessons;