import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { BsFillCartCheckFill } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";

import {
  Container,
  Row,
  Col,
  Nav,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth } from "../../context/auth";
import { useCart, useWishlist } from "../../hooks/hooks";

function Header() {
  const { cart, addItemToCart, removeItemFromCart } = useCart();
  const { wishlist, removeFromWishlist, } = useWishlist();  

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [wishListOpen, setWishListOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const auth = useAuth();
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleNav = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggle = () => setIsOpen(!isOpen);
  const toggleWishList = () => setWishListOpen(!wishListOpen);
  const toggleCartList = () => setCartOpen(!cartOpen);

  const navItems = [
    {
      label: "Будущий учитель",
      to: "/education",
      dropdown: false,
      items: [
        
      ],
    },
    {
      label: "Наука - школе",
      to: "/",
      dropdown: true,
      items: [
        { label: "Онлайн лекторий", to: "/online-lectures" },
        { label: "Методические материалы", to: "/products" },
        { label: "Интерактивные уроки", to: "/interactive-lessons" },
        
      ],
    },
    {
      label: "Педагогический успех",
      to: "/teachers-in-history",
      dropdown: false,
      items: [
        { label: "Преподаватели", to: "/teachers-in-history" },
      ],
    },
    {
      label: "Творческий поиск",
      to: "/sections",
      dropdown: false,
      items: [],
    },
    {
      label: "Родители и дети",
      to: "/parents-and-childrens",
      dropdown: false,
      items: [],
    },
   
  ];
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(navItems.length).fill(false)
  );

  const toggleDropdown = (index) => {
    const newArray = [...dropdownOpen];
    newArray[index] = !newArray[index];
    setDropdownOpen(newArray);
  };
  const navigate = useNavigate();

  const handleLinkClick = (to) => {
    navigate(to, { replace: true });
    window.location.reload(); // Refresh the page
  };
 
  const subtotal = wishlist.reduce((total, item) => {
    if (item && item.price_with_discount) {
      return total + item.price_with_discount;
    }
    if (item && item.price) {
      return total + item.price;
    }
    return total;
  }, 0);
  const subtotalCart = cart.reduce((total, item) => {
    if (item && item.price_with_discount) {
      return total + item.price_with_discount;
    }
    if (item && item.price) {
      return total + item.price;
    }
    return total;
  }, 0);

  
  const onLogOut = () => {
    auth.logout();
    window.location.reload();
  }
  return (
    <div className="">
      <header className="site-header">
        <div id="header-wrap">
          <Container className="container">
            <Row className="row">
              {/* <!--menu start--> */}
              <Col className="col">
                <Nav className="navbar navbar-expand-lg p-4 shadow bg-white">
                  <NavbarBrand href="/" className="navbar-brand logo">
                    <img src="/images/logo.svg" alt="" />
                  </NavbarBrand>

                  <NavbarToggler onClick={toggleNav} className="me-2" />
                  <Collapse className="navbar-collapse" isOpen={!isCollapsed}>
                    <div className="d-flex w-100 justify-content-between">
                      <Nav className="navbar-nav mx-auto">
                        {navItems.map((item, index) => (
                          <Dropdown
                            key={item.label}
                            nav
                            isOpen={dropdownOpen[index]}
                            toggle={() => toggleDropdown(index)}
                          >
                            {item.dropdown ?
                                <DropdownToggle nav caret>
                                  {item.label}
                                </DropdownToggle>
                                :
                                <Link
                                className="nav-link"
                                to={item.to}
                                onClick={() => handleLinkClick(item.to)}  
                                >
                                  {item.label}
                                </Link>

                            }

                            {item.dropdown && <DropdownMenu>
                              {item.items.map((subItem) => (
                                  <DropdownItem
                                      key={subItem.label}
                                      className=""
                                      onClick={() => handleLinkClick(subItem.to)}
                                  >
                                    {subItem.label}
                                  </DropdownItem>
                              ))}
                            </DropdownMenu>}

                          </Dropdown>
                        ))}
                      </Nav>
                      <Nav className="navbar-nav ml-auto align-items-start">
                        <div className="d-flex align-items-center flex-wrap gap-2 nav-icon-wrapper">
                          <Link
                            className="add-cart btn-link bg-white px-2 py-1 shadow-sm rounded nav-icon position-relative"
                            onClick={toggleCartList}
                          >
                            <i className="bi bi-cart fs-5"></i>
                            {cart.length !== 0 && <div className="cart-status">{cart.length}</div>}
                          </Link>

                          <Link
                            className="wishlist-btn btn-link bg-white px-2 py-1 shadow-sm rounded nav-icon position-relative"
                            onClick={toggleWishList}
                          >
                            <i className="bi bi-heart fs-5"></i>
                            {wishlist.length !== 0 && <div className="cart-status">{wishlist.length}</div>}
                          </Link>
                          <Link className="login-btn btn-link bg-white px-2 py-1 shadow-sm rounded nav-icon" to={`${!auth.isAuthenticated ? "/login" : "/account/settings" }`} >
                            <i className="bi bi-person fs-5 align-middle"></i>
                          </Link>

                          {/* <div
                            style={{ cursor: "pointer" }}
                            className="ms-2 togglerCanvas d-inline-block border-0 px-2 py-1 bg-white shadow"
                            onClick={toggle}
                          >
                            <i className="bi bi-list-nested fs-3 text-dark"></i>
                          </div> */}
                          {auth.isAuthenticated && <div
                              style={{ cursor: "pointer"}}
                              className="btn-link bg-white px-2 py-1 shadow-sm rounded nav-icon" 
                              onClick={onLogOut}
                            >
                              <i className="bi bi-box-arrow-right fs-5 text-dark"></i>
                            </div>}
                            
                        </div>
                      </Nav>
                    </div>
                  </Collapse>

                 
                </Nav>
              </Col>
            </Row>
          </Container>
        </div>
      </header>

      <Offcanvas
        className="bg-gradient-1"
        direction="end"
        isOpen={isOpen}
        toggle={toggle}
      >
        <OffcanvasHeader>
          <Button
            className="bg-transparent fs-1 ms-auto border-0"
            onClick={toggle}
          >
            <i className="bi bi-x-octagon"></i>
          </Button>
        </OffcanvasHeader>
        <OffcanvasBody className="px-md-10 px-4 py-8">
          <img
            className="img-fluid side-logo mb-3"
            src="/images/logo.svg"
            alt=""
          />
          <p className="mb-0 text-black lead">
          Цифровая социально-ориентированная платформа, позволяющая воплотить в массовую педагогическую практику принцип непрерывного образования всех участников образовательной экосистемы
          </p>
          <div className="form-info border-top border-dark pt-6 mt-6">
            <h5 className=" text-black border-bottom border-white d-inline-block">
              Информация для связи с нами
            </h5>
            <ListGroup className="mt-4 bg-transparent px-0">
              <ListGroupItem className="mb-2 h6 text-light bg-transparent px-0 border-0">
                <i className="text-black fs-4 align-middle bi bi-geo-alt me-2"></i>
                ФГБОУ ВО «Алтайский государственный университет»
                656049, Барнаул, пр. Ленина, 61
              </ListGroupItem>
              <ListGroupItem className="mb-2 h6 bg-transparent px-0 border-0">
                <i className="text-black fs-4 align-middle bi bi-telephone me-2"></i>
                <a className="text-light" href="tel:+73852291222">
                +7 (3852) 291-222
                </a>
              </ListGroupItem>
              <ListGroupItem className="h6 bg-transparent px-0 border-0">
                <i className="text-black fs-4 align-middle bi bi-envelope me-2"></i>
                <a
                  className="text-light"
                  href="mailto:prcom@asu.ru"
                >
                  prcom@asu.ru
                </a>
              </ListGroupItem>
            </ListGroup>
          </div>
          {/* <div className="border-top border-dark pt-6 mt-6">
            <h5 className="text-black border-bottom border-white">
              Follow Us:
            </h5>
            <ListGroup className="mt-4 px-0" horizontal>
              <ListGroupItem className="me-2 bg-transparent px-0">
                <Link to="/" className="text-light fs-4">
                  <i className="bi bi-facebook"></i>
                </Link>
              </ListGroupItem>

              <ListGroupItem className="me-2 bg-transparent px-2">
                <Link to="/" className="text-light fs-4">
                  <i className="bi bi-dribbble"></i>
                </Link>
              </ListGroupItem>
              <ListGroupItem className="me-2 bg-transparent px-2">
                <Link to="/" className="text-light fs-4">
                  <i className="bi bi-instagram"></i>
                </Link>
              </ListGroupItem>

              <ListGroupItem className="me-2 bg-transparent px-2">
                <Link to="/" className="text-light fs-4">
                  <i className="bi bi-twitter"></i>
                </Link>
              </ListGroupItem>

              <ListGroupItem className="me-2 bg-transparent px-2">
                <Link to="/" className="text-light fs-4">
                  <i className="bi bi-linkedin"></i>
                </Link>
              </ListGroupItem>
            </ListGroup>
          </div> */}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        className="bg-gradient-1"
        direction="end"
        isOpen={wishListOpen}
        toggle={toggleWishList}
      >
        <div>
          <Row>
            <Col xs={9} className="py-4 align-items-center">
              {" "}
              <h5 className="text-black px-4">
                Список желаемого ({wishlist?.length})
              </h5>
            </Col>
            <Col xs={3} className="align-items-center">
              {" "}
              <Button
                className=" text-black border-0 bg-transparent fs-1 ms-auto"
                onClick={toggleWishList}
              >
                <i className="bi bi-x-octagon"></i>
              </Button>
            </Col>
          </Row>
        </div>
        <OffcanvasBody className="">
          {wishlist.map((product, index) => {
            if (product) {
              return (
                <div key={index}>
                  <Row className="align-items-center my-4">
                    <Col xs={5} className="d-flex align-items-center">
                      <div
                        type="submit"
                        className="text-black border-0 bg-transparent fs-1 ms-auto mx-2"
                        onClick={() => {
                          removeFromWishlist(product.id);
                        }}
                      >
                        <AiOutlineCloseCircle />
                      </div>
                      <a>
                        <img
                          className="img-fluid"
                          src={product.preview_image_link}
                          alt="..."
                        />
                      </a>
                    </Col>
                    <Col >
                        <div className="link-title text-black fs-6">
                          {product.title}{" "}
                        </div>
                      <div className="product-meta">
                        <span className="mx-2 text-black">
                          {product.price_with_discount ? <>{product.price_with_discount.toFixed(2)}</> : <>{product.price.toFixed(2)}</>}₽
                        </span>
                        <span className="text-black">x {product.quantity}</span>
                      </div>
                      <div className="product-meta"></div>
                    </Col>
                    <Col xs={1} className="d-flex align-items-center p-0">
                      <span
                        onClick={() => addItemToCart(product)}
                        className="btn text-black fs-1 bg-transparent p-0"
                      >
                        <BsFillCartCheckFill />
                      </span>
                    </Col>
                  </Row>
                </div>
              );
            }

            return null;
          })}
          <hr className="my-5" />
          <div className="d-flex justify-content-between align-items-center mb-8">
            <span className="text-black">Всего:</span>
            <span className="text-black">{subtotal.toFixed(2)} ₽</span>
          </div>
          <Link
            to="/product-cart"
            className="btn btn-primary btn-animated mr-2"
            onClick={toggleWishList}
          >
            <i className="las la-shopping-cart mr-1"></i>Посмотреть корзину
          </Link>
          {/* <Link to="/product-checkout" className="btn btn-dark">
            <i className="las la-money-check mr-1"></i>Continue To Checkout
          </Link> */}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        className="bg-gradient-1"
        direction="end"
        isOpen={cartOpen}
        toggle={toggleCartList}
      >
        <div>
          <Row>
            <Col xs={9} className="py-4 align-item-center">
              {" "}
              <h5 className="text-black px-4">
                Ваша корзина ({cart?.length})
              </h5>
            </Col>
            <Col xs={3} className="align-item-center">
              {" "}
              <Button
                className="text-black border-0 bg-transparent fs-1 ms-auto"
                onClick={toggleCartList}
              >
                <i className="bi bi-x-octagon"></i>
              </Button>
            </Col>
          </Row>
        </div>
        <OffcanvasBody>
          {cart.map((product, index) => {
            if (product) {
              return (
                <div key={index}>
                  <Row className="align-items-center my-5">
                    <Col xs="5" className="d-flex align-items-center">
                      <div className="mr-4">
                        <Button
                          type="submit"
                          className="text-black border-0 bg-transparent mx-2 fs-1 ms-auto"
                          onClick={() => {
                            removeItemFromCart(product.id);
                          }}
                        >
                          <AiOutlineCloseCircle />
                        </Button>
                      </div>
                      <a>
                        <img
                          className="img-fluid"
                          src={product.preview_image_link}
                          alt="..."
                        />
                      </a>
                    </Col>
                    <Col >
                        <div className="link-title text-black fs-6">
                          {product.title}{" "}
                        </div>
                      <div className="product-meta">
                        <span className="mx-2 text-black font-weight-bold">
                          {product.price_with_discount ? <>{product.price_with_discount.toFixed(2)}</> : <>{product.price.toFixed(2)}</>}₽
                        </span>
                        <span className="text-black">x {product.quantity}</span>
                      </div>
                      <div className="product-meta"></div>
                    </Col>
                  </Row>
                </div>
              );
            }

            return null;
          })}
          <hr className="my-5" />
          <div className="d-flex justify-content-between align-items-center mb-8">
            <span className="text-black">Всего:</span>
            <span className="text-black">{subtotalCart.toFixed(2)} ₽</span>
          </div>
          <Link
            to="/product-cart"
            className="btn btn-primary btn-animated mr-2"
            onClick={toggleCartList}
          >
            <i className="las la-shopping-cart mr-1"></i>Показать корзину
          </Link>
          {/* <Link to="/product-checkout" className="btn btn-dark">
            <i className="las la-money-check mr-1"></i>Continue To Checkout
          </Link> */}
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
}

export default Header;
