import React from "react";
import {useLocation, Outlet} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams} from 'react-router-dom'

const ParentsAndChildrensItem = ({item}) => {
    const navigate = useNavigate();
    const params = useParams();
    
    
    const handlePaCSectionSelect = (PaCsection) => {
        if(PaCsection.title === "Консультация психолога") {
            navigate('psychological-consult')
        }else {
            navigate(`${PaCsection.id}`)
        }
    }
    
    return (
        <div className="PaCcard" onClick={() => handlePaCSectionSelect(item)}>
            <img src={item.preview_image_link} alt={item.title} className="PaCcard-image "/>
            <p className="PaCcard-text">{item.title}</p>
        </div>
    )
}

export default ParentsAndChildrensItem;