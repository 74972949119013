import axios, {AxiosError, AxiosResponse} from "axios";
import Api from "../Api";

export const initPhoneVerification = async (phoneNumber) => {
    const data = {phone_number: phoneNumber};
    return await axios.post(Api.endpoints.INIT_PHONE_VERIFICATION, data)
        .then((response) => ({
            status: response.status === 200,
            uid: response.data?.ucaller_id,
        }))
        .catch((error) => ({
            status: false,
            uid: "",
            response: error.response,
        }));
}

export const completePhoneVerification = async (phoneNumber, phoneCode) => {
    const data = {
        phone_number: phoneNumber,
        phone_code: phoneCode,
    }
    return await axios.post(Api.endpoints.COMPLETE_PHONE_VERIFICATION, data)
        .then(response => ({
            status: response.status === 200,
            token: response.data?.token,
        }))
        .catch((error) => ({
            status: false,
            token: "",
            response: error.response,
        }))
}


export const setNewPassword = async ( phoneNumber, token, password, confirmPassword,) => {
    const data = {
        phone_number: phoneNumber,
        unique_token: token,
        password: password,
        confirm_password: confirmPassword,
    }
    return await axios.post(Api.endpoints.RESET_PASSWORD, data)
        .then((response) => ({
            status: response.status === 200,
        }))
        .catch((error) => ({
            status: false,
            response: error.response,
            errors: error,
        }))

}
