import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom"
import {loadAllSectionsAsync, loadSubsectionsAsync, loadArticlesAsync} from "../../store/reducer/pedagogicalSearchReducer"
import { deleteArticle, deleteSection } from "../../services/PedagogicalSearchService";
import { deleteExploration } from "../../services/ExplorationsService";
import { loadExplorationsAsync } from "../../store/reducer/explorationReducer";
import { deleteTeacherInHistory } from "../../services/TeachersInHistory";
import { loadTeachersInHistoryAsync } from "../../store/reducer/teachersInHistoryReducer";
import { deleteOnlineLecture } from "../../services/OnlineLecturesService";
import { loadOnlineLecturesAsync } from "../../store/reducer/onlineLecturesReducer";
import { loadProductsAsync } from "../../store/reducer/productReducer";
import { deleteProduct } from "../../services/Products";
import { deleteInteractiveLesson } from "../../services/InteractiveLessonsService";
import { loadInteractiveLessonsAsync } from "../../store/reducer/interactiveLessonsReducer";
import { deleteEducationProgram } from "../../services/EducationProgram";
import { loadEducationProgramsAsync } from "../../store/reducer/educationProgramsReducer";

const ConfirmDeleteModal = ({item}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    
    const onConfirmDeleteSection = async () => {
        await deleteSection({section_id: item.id})
        dispatch(loadAllSectionsAsync())
        if (item.parent_id){
            dispatch(loadSubsectionsAsync(item.parent_id))
        }
    }
    const onConfirmDeleteArticle = async () => {
        await deleteArticle({article_id: item.id})
        dispatch(loadArticlesAsync(item.parent_id))
    }
    const onConfirmDeleteExploration = async () => {
        await deleteExploration({exploration_id: item.exploration_id})
        dispatch(loadExplorationsAsync())
    }
    
    const onConfirmDeleteTeachersInhistory = async () => {
        await deleteTeacherInHistory({teacher_id: item.id})
        dispatch(loadTeachersInHistoryAsync())
    }

    const onConfirmDeleteOnlineLecture = async (lecture) => {
        await deleteOnlineLecture(lecture.id)
        dispatch(loadOnlineLecturesAsync())
    }
    const onConfirmDeleteProduct = async () => {
        await deleteProduct({product_id:item.id})
        dispatch(loadProductsAsync())
    }
    const onConfirmDeleteInteractiveLesson = async () => {
        await deleteInteractiveLesson({lesson_id: item.id})
        dispatch(loadInteractiveLessonsAsync())
    }
    
    const onConfirmDeleteEducationProgram = async () => {
        await deleteEducationProgram({program_id: item.id})
        dispatch(loadEducationProgramsAsync())
    }
    return (
        
        <div class="modal fade" id="confirmDelete" tabindex="-1" aria-labelledby="confirmDeleteLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="confirmDeleteLabel">Вы уверены, что хотите удалить?</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div class="modal-footer">
                        {location.pathname.includes("manage-explorations") && 
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={onConfirmDeleteExploration}>Удалить</button>}
                        
                        {location.pathname.includes("manage-teachers-in-history") &&
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={onConfirmDeleteTeachersInhistory}>Удалить</button>}
                         
                        {location.pathname.includes("manage-sections") &&
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={onConfirmDeleteSection}>Удалить</button>}
                         
                        {location.pathname.includes("manage-subsections")&& 
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={onConfirmDeleteSection}>Удалить</button>}
                         
                        {location.pathname.includes("manage-articles") &&
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={onConfirmDeleteArticle}>Удалить</button>}
                         
                        {location.pathname.includes("manage-online-lectures") &&
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={() => onConfirmDeleteOnlineLecture(item)}>Удалить</button>}
                         
                        {location.pathname.includes("manage-interactive-lessons") &&
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={() => onConfirmDeleteInteractiveLesson(item)}>Удалить</button>}
                         
                        {location.pathname.includes("manage-products") &&
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={onConfirmDeleteProduct}>Удалить</button>}
                         
                        {location.pathname.includes("manage-education-programs") &&
                         <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={() =>  onConfirmDeleteEducationProgram(item)}>Удалить</button>}
                        
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отменить</button>
                    </div>
                    </div>
                </div>
        </div>
    )
}

export default ConfirmDeleteModal;