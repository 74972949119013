import React from 'react';
import FileIcon from "../../../assets/icons/ic-file.svg";

const FileItem = ({fileName, link}) => {
    return (
        <li className="file">
            <div className="fileIcon">
                <img src={FileIcon} alt={"загрузка файлов"} width={20} height={20}/>
            </div>
            {link ? <a href={link} className="fileName">{fileName}</a> : <p view={"p3"} color={"primary"} className="fileName">{fileName}</p>}
        </li>
    );
};

export default React.memo(FileItem);
