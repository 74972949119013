import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import MockBackgroundImage from "../../../assets/images/bg/background-mock.png";
import { getExplorations, getExplorationDetail } from "../../../services/ExplorationsService";

const initialState = {
    exploration_id:0,
    title: "",
    description: "",
    preview_image_link: MockBackgroundImage,
    category: "",
    content: null,
    creation_time: "",
    
}

export const loadDetailExplorationAsync = createAsyncThunk(
    "loadDetailExplorationAsync",
    async (explorationId) => {
        const response = await getExplorationDetail({exploration_id: explorationId})
        return response.data;
    }
)


export const loadExplorationsAsync = createAsyncThunk (
    "loadExplorationsAsync",
    async() => {
        const response = await getExplorations();
        if (response.success) return response.data;
        else return undefined;
    }
  )

const detailExplorationSlice = createSlice({
    name: "detailExploration",
    initialState,
    reducers: {
        clearState: () => ({...initialState}),
        changeTitleExploration: (state, action) => {
            state.title = action.payload;
        },
        changeExplorationId: (state, action) => {
            state.explorations_id = action.payload;
        },
        changeDescriptionExploration: (state, action) => {
            state.description = action.payload;
        },
        changeContent: (state, action) => {
            state.content = action.payload;
        },
        changePreview: (state, action) => {
            state.preview_image_link = action.payload;
        },
        changeCategoryExploration: (state, action) => {
            state.category = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadDetailExplorationAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const {
                        title,
                        description,
                        content,
                        exploration_id,
                        preview_image_link,
                        category,
                        creation_time
                    } = action.payload;

                    state.exploration_id = exploration_id;
                    state.title = title;
                    state.description = description;
                    state.content = content;
                    state.preview_image_link = preview_image_link;
                    state.category = category;
                    state.creation_time = creation_time;
                }
            })  
    }
})

export const {
    clearState,
    changeDescriptionExploration,
    changeTitleExploration,
    changeContent,
    changePreview,
    changeExplorationId,
    changeCategoryExploration
} = detailExplorationSlice.actions;

export const selectDetailExploration = (state) => state.detailExploration;
export default detailExplorationSlice.reducer;