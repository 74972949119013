import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from 'react-router-dom'
import { setSelectedSubsection } from "../../store/reducer/pedagogicalSearchReducer";
import { Card, CardImg, CardBody, CardTitle, CardText, Col } from 'reactstrap';

const SubsectionItem = ({item, index}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleSubsectionSelect = (subsection) => {
        navigate(`${subsection.id}`)
      }
    return (
            <Card key={index} className="d-flex flex-column p-4 search-card search-card-hover w-320 justify-content-center" onClick={() => handleSubsectionSelect(item)}>
                <CardImg className="object-fit-cover" height={"270px"} src={item.preview_image_link} />
                <CardBody>
                    <CardTitle className="fw-bold fs-5 mb-0">{item.title}</CardTitle>
                    {item.show_description && <CardText>{item.description}</CardText>}
                </CardBody>
            </Card>
    )
}

export default SubsectionItem;