import React, { useEffect, useState } from "react";
import {Container} from "reactstrap"
import {useSelector, useDispatch} from "react-redux";
import {loadAllSectionsAsync} from "../../store/reducer/pedagogicalSearchReducer"
import {useLocation, Outlet} from "react-router-dom"
import ParentsAndChildrensItem from "../../Components/ParentsAndChildrens/ParentsAndChildrensItem";
import ParentsAndClidrensImage from "../../assets/images/Родители-и-дети2.jpg"
import { useIsMobile } from "../../hooks/hooks";
const ParentsAndChildrens = () => {
    const firstBreadcrumb = { label: "", link: "/" };
    const secondBreadcrumb = {
        label: "Родители и дети",
        link: "/",
        active: true,
    };
    const { isMobile } = useIsMobile();
    const sections = useSelector((state) => state.pedagogicalSearch.sections);
    const [PaCsections, setPaCsections] = useState([]);
    const location = useLocation();
    const dispatch = useDispatch();
    
    useEffect(() => {
        const filteredSections = sections.filter((item) => item.destination === "Родители и дети");
        setPaCsections(filteredSections);
      }, [sections]);
    
    useEffect(() => {
        dispatch(loadAllSectionsAsync())
      }, [])
    
    return (
        <>
            {location.pathname === "/parents-and-childrens" ? <>
                                                {/* <PageHeading
                                                    title="Родители и дети: территория взаимопонимания"
                                                    firstBreadcrumb={firstBreadcrumb}
                                                    secondBreadcrumb={secondBreadcrumb}
                                                /> */}
                                                <Container className="page-wrapper mb-5">
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column justify-content-center px-2">
                                                            <h2 className="mb-5">Родители и дети: территория взаимопонимания</h2>
                                                            <p>
                                                                В результате знакомства с
                                                                представленными на площадке "Родители и дети: территория 
                                                                взаимопонимания" материалами Вы
                                                                сможете помочь ребенку стать успешным
                                                                в учебе, принятым сверстниками,
                                                                наладить контакт с учителями, научить
                                                                правильно вести себя в конфликтных
                                                                ситуациях, а также узнаете, как строить
                                                                общение со своим ребенком, чтобы
                                                                слушать и слышать друг друга, как
                                                                помочь ему адаптироваться к школьной
                                                                среде, получите ответы на вопросы,
                                                                почему ребенок получает плохие оценки,
                                                                каковы психологические особенности
                                                                возрастного развития вашего ребенка,
                                                                как защитить ребенка от ситуаций,
                                                                опасных для его физического и
                                                                психического здоровья, и многое другое. 
                                                            </p>
                                                        </div>
                                                        
                                                        <img src={ParentsAndClidrensImage} alt="" width={500} height={500} className="d-none d-md-block"/>
                                                    </div>
                                                </Container>
                                                <Container className="d-flex flex-column">
                                                    <h3 className="mb-5">Найдите интересующие вас материалы по тематикам:</h3>
                                                    <div className="d-flex gap-3 justify-content-center flex-wrap pb-10">
                                                        {PaCsections.map((item, index) => (
                                                            <ParentsAndChildrensItem key={index} item={item}/>
                                                            ))}
                                                    </div>   
                                                </Container>
                                                </>
            : <Outlet/>
            }
            </>
    )
}

export default ParentsAndChildrens;