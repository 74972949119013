import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getPsychologicalConsults, getUserPsychologicalConsults } from "../../services/psychologicalConsult";

const initialState = {
  psychologicalConsult: [],
  selectedPsychologicalConsult: ''
}

export const loadPsychologicalConsultAsync = createAsyncThunk (
  "loadInteractiveLessonsAsync",
  async() => {
      const response = await getPsychologicalConsults();
      if (response.success) return response.data;
      else return undefined;
  }
)


export const loadUserPsychologicalConsultAsync = createAsyncThunk (
  "loadUserInteractiveLessonsAsync",
  async(userId) => {
      const response = await getUserPsychologicalConsults({user_id: userId});
      if (response.success) return response.data;
      else return undefined;
  }
)
const psychologicalConsultSlice = createSlice({
  name: "psychologicalConsult",
  initialState,
  reducers: {
    setSelectedPsychologicalConsult(state, action) {
      state.selectedPsychologicalConsult = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadPsychologicalConsultAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.psychologicalConsult = action.payload || []
        }
    })
    builder.addCase(loadUserPsychologicalConsultAsync.fulfilled, (state, action) => {
      if (action.payload) {
          state.psychologicalConsult = action.payload || []
      }
  })
  }  
});

export const { 
  setSelectedPsychologicalConsult,

} = psychologicalConsultSlice.actions;

export default psychologicalConsultSlice.reducer;
