import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEducationPrograms } from "../../services/EducationProgram";


const initialState = {
  educationPrograms: [],
  bachelor:[],
  master: [],
  graduated: [],
  ATP: [],
  selectedEducationProgram: {},
}

export const loadEducationProgramsAsync = createAsyncThunk (
  "loadEducationProgramsAsync",
  async() => {
      const response = await getEducationPrograms();
      if (response.success) return response.data;
      else return undefined;
  }
)

const educationProgamSlice = createSlice({
  name: "educationProgam",
  initialState,
  reducers: {
    setSelectedEducationProgram(state, action) {
      state.selectedEducationProgram = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadEducationProgramsAsync.fulfilled, (state, action) => {
        if (action.payload) {
            state.educationPrograms = action.payload || []
            
            state.bachelor = action.payload.filter((program) => program.type === "Бакалавриат") || []
            state.master = action.payload.filter((program) => program.type === "Магистратура") || []
            state.graduated = action.payload.filter((program) => program.type === "Аспирантура") || []
            state.ATP = action.payload.filter((program) => program.type === "ДПО") || []
        }
    })
  }     
});

export const { setSelectedEducationProgram } = educationProgamSlice.actions;

export default educationProgamSlice.reducer;
