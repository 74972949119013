import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import MockBackgroundImage from "../../../assets/images/bg/background-mock.png";
import {getTeachersInHistoryById} from "../../../services/TeachersInHistory"

const initialState = {
    id: 0,
    name: "",
    position: "",
    image_link: MockBackgroundImage,
    content: null
}

export const loadDetailTeacherInHistoryAsync = createAsyncThunk(
    "loadDetailTeacherInHistoryAsync",
    async (teacherId) => {
        const response = await getTeachersInHistoryById({teacher_id: teacherId})
        return response.data;
    }
)

const detailTeacherHistorylSlice = createSlice({
    name: "detailTeacherHistory",
    initialState,
    reducers: {
        changeName(state, action) {
            state.name = action.payload;
        },
        changePosition(state, action) {
            state.position = action.payload;
        },
        changeImageLink(state, action) {
            state.image_link = action.payload;
        },
        changeContent(state, action) {
            state.content = action.payload;
        },
        
    },
    extraReducers: builder => {
        builder
            .addCase(loadDetailTeacherInHistoryAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const {
                        id,
                        name,
                        position,
                        image_link,
                        content
                    } = action.payload;
                        
                    state.id = id;
                    state.name = name;
                    state.position = position;
                    state.image_link = image_link;
                    state.content = content;
                    
                }
            })  
    }
});

export const { 
    changeName,
    changePosition,
    changeImageLink,
    changeContent
 } = detailTeacherHistorylSlice.actions;

export default detailTeacherHistorylSlice.reducer;
