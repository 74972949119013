import React, {createContext, useContext, useState} from 'react';

const initialContextState = {
    currentStage: "Default",
    emailConfirmCode: "",
    newEmail: "",
    token: "",
    currentEmail: "",
    successChanged: false,

    setCurrentEmail: (() => undefined),
    setEmailConfirmCode: (() => undefined),
    setNewEmail: (() => undefined),
    setStage: (() => undefined),
    setSuccessChanged: (() => undefined),
    setToken: (() => undefined),
}

const EmailContext = createContext(initialContextState);



const EmailProvider = (
    {
        email,
        phoneNumber,
        children
    }
) => {
    const [currentStage, setStage] = useState("Default");
    const [newEmail, setNewEmail] = useState("");
    const [emailConfirmCode, setEmailConfirmCode] = useState("");
    const [token, setToken] = useState("");
    const [successChanged, setSuccessChanged] = useState(false);
    const [currentEmail, setCurrentEmail] = useState(email);

    const value = {
        currentStage,
        setStage,
        newEmail,
        setNewEmail,
        emailConfirmCode,
        setEmailConfirmCode,
        token,
        setToken,
        successChanged,
        setSuccessChanged,
        currentEmail,
        setCurrentEmail,
    }

    return (
        <EmailContext.Provider value={value}>
            {children}
        </EmailContext.Provider>
    );
};

export const useEmailContext = () => useContext(EmailContext);

export default EmailProvider;
