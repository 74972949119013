import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    fliterCategory: [], 
    filterMinPrice: null,
    filterMaxPrice: null,
    filterOrder: null,
    filterPage: 1,
    filterCount: 12,
}

const productsFilterSlice = createSlice({
  name: "productsFilter",
  initialState,
  reducers: {
    clearState: () => ({...initialState}),
    changeFilterMinMaxPrice: (state, action) => {
        state.filterMinPrice = action.payload[0]
        state.filterMaxPrice = action.payload[1]
    },
    clearFilterCategory: (state) => {
      state.fliterCategory = []
    },
    changeFilterOrder: (state, action) => {
      state.filterOrder = action.payload
    },
    changeFilterCount: (state, action) => {
      state.filterCount = action.payload
    },
    changeFilterPage: (state, action) => {
      state.filterPage = action.payload
    },
    changeFilterCategory: (state, action) => {
        const category = action.payload;
        if(state.fliterCategory.includes(category)) {
            state.fliterCategory = state.fliterCategory.filter(x => x !== category);
        }else {
            state.fliterCategory = [...state.fliterCategory, category]
        }
    },

  },
  
});

export const {
    clearState,
    changeFilterCategory,
    changeFilterMinMaxPrice,
    clearFilterCategory,
    changeFilterOrder,
    changeFilterCount,
    changeFilterPage
} = productsFilterSlice.actions;

export default productsFilterSlice.reducer;
