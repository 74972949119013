import {axiosClient} from "../common/axios";
import Api from "../api/Api"

export const createInteractiveLesson = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.ADD_INTERACTIVE_LESSON, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const updateInteractiveLesson = async (payload) => {
    try {

        const response = await axiosClient.put(Api.endpoints.UPDATE_INTERACTIVE_LESSON, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const getInteractiveLessons = async () => {
    
    try {
        const response = await axiosClient.get(Api.endpoints.GET_INTERACTIVE_LESSONS)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const getInteractiveLessonById = async ({lesson_id}) => {
    try {
        const url = Api.endpoints.GET_INTERACTIVE_LESSON_BY_ID.replace('{lesson_id}', lesson_id);
        
        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}


export const deleteInteractiveLesson = async ({lesson_id}) => {
    try {
        const url = Api.endpoints.DELETE_INTERACTIVE_LESSON.replace('{lesson_id}', lesson_id);
        const response = await axiosClient.post(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const addInteractiveLessonRequest = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.ADD_INTERACTIVE_LESSON_REQUEST, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}
export const getInteractiveLessonsRequests = async () => {
    try {
        const response = await axiosClient.get(Api.endpoints.GET_INTERACTIVE_LESSONS_REQUESTS)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const updateInteractiveLessonRequestStatus = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.UPDATE_INTERACTIVE_LESSON_REQUEST_STATUS, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }

}

export const getUserInteractiveLessonsRequests = async ({user_id}) => {
    try {
        const url = Api.endpoints.GET_USER_INTERACTIVE_LESSONS_REQUESTS.replace('{user_id}', user_id.user_id);
        
        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}