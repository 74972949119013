import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadInteractiveLessonsRequestsAsync } from "../../../store/reducer/interactiveLessonsRequestsReducer";
import InteractiveLessonsRequestItem from "../../../Components/interactive-lessons/InteractiveLessonsRequestItem";

const ManageInteractiveLessonsRequests = () => {
    const dispatch = useDispatch();
    const interactiveLessonsRequests = useSelector((state) => state.interactiveLessonsRequests);
    let location = useLocation();
    
    useEffect(() => {
        dispatch(loadInteractiveLessonsRequestsAsync())
    }, [location.pathname])
  
    return(
        <div className="d-flex flex-wrap p-10 gap-3 justify-content-center">
            {
                interactiveLessonsRequests.interactiveLessonsRequests.length != 0 && interactiveLessonsRequests.interactiveLessonsRequests.map((lessonRequest, index) => (
                    <InteractiveLessonsRequestItem lessonRequest={lessonRequest} key={index} onEditorial/>
                ))
            }
        </div>
        
    )
}

export default ManageInteractiveLessonsRequests;