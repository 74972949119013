import React, { useEffect } from "react";
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {Container} from "reactstrap"
import PageHeading from "../../Components/page-heading/Page_Heading";
import ContactForm from "../contact/ContactForm";
import { addPsychologicalConsult } from "../../services/psychologicalConsult";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import { 
    clearState
  } from "../../store/reducer/manage-contact-us/contact-us-form";
  
const PsychologicalConsult = () => {
    const params = useParams();
    const location = useLocation();
    const contactUs = useSelector((state) => state.contactUsForm);
    const profile = useSelector((state) => state.userInfo.profile);
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const firstBreadcrumb = { label: "Родители и дети", link: "/parents-and-childrens" };
    const secondBreadcrumb = {
        label: "Консультация психолога",
        link: "/",
        active: true,
    };

    
    const handleSubmit = async() => {
        if(!isAuthenticated) {
            navigate('/login')
            toast.error('Войдите, чтобы оставить заявку', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return  
          }
        const payload = {
            user_id: profile.userId,
            full_name: contactUs.fullName,
            email: contactUs.email,
            phone_number: contactUs.phone_number,
            message: contactUs.message,
            status: "Ожидает"
        }
        
        
        const response = await addPsychologicalConsult(payload)
        if( response.success ){
            toast.success('Запрос на интерактивный урок отправлен', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(clearState())
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }
    return (
        <>
            <PageHeading
            title="Родители и дети: территория взаимопонимания"
            firstBreadcrumb={firstBreadcrumb}
            secondBreadcrumb={secondBreadcrumb}
            />
            <div>
            <Container className="d-flex flex-column pb-10" >
                <ContactForm handleSubmit={handleSubmit}/>
            </Container>
            </div>
        </>
    )
}

export default PsychologicalConsult;