import React, {useEffect} from "react";
import {Container} from "reactstrap"
import ArticleItem from "../../Components/PedagogicalSearch/ArticleItem";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams} from 'react-router-dom'
;
import {loadDetailArticleAsync} from "../../store/reducer/manage-sections/detail-article"
import PageHeading from "../../Components/page-heading/Page_Heading";
import {useMount} from '../../hooks/hooks';
import { TextEditor } from "../../Components/EditorComponent/TextEditor/TextEditor";

const Title = ({title}) =>
    <h4 className="fw-bold">{title}</h4>

export const Preview = ({ title, image,}) => {
    return (
        <div className="d-flex w-100 flex-column gap-3 my-5 mx-0">
            <Title title={title}/>
        </div>
    )
}

const TextArticle = ({content}) => {

    const [mounted] = useMount();

    return (
        <div className="d-flex w-100 flex-column pb-3" width={"full"} direction={"column"} >
            {mounted && <TextEditor readOnly initialValues={content}/>}
        </div>
    )
}

const ArticleDetail = () => {
 
  
  const article = useSelector((state) => state.detailArticle);
  const dispatch = useDispatch();
  const params = useParams();
  const [mounted] = useMount();
  
  useEffect(() => {
    dispatch(loadDetailArticleAsync(params.articleId))
  }, [])
  
  const {content} = article
  if (!mounted || !content) return null;

  const values = Boolean(content) ? content : undefined;
  
  const firstBreadcrumb = { label: "Рубрики", link: params.subsectionId ? "/sections" : "/parents-and-childrens" };
  const secondBreadcrumb = {
    label: "Статья",
    link: "/",
    active: true,
  };
  const thirdBreadcrumb = {
    label: "Подрубрики",
    link: `/sections/${params.sectionId}`,
    active: true,
  };
  const fourthBreadcrumb = {
    label: "Статьи",
    link: params.subsectionId ? `/sections/${params.sectionId}/${params.subsectionId}` : `/parents-and-childrens/${params.PaCsectionId}`,
    active: true,
  }
  return (
    <>
    {params.PaCsectionId ? 
    <PageHeading
      title="Родители и дети: территория взаимопонимания"
      firstBreadcrumb={firstBreadcrumb}
      secondBreadcrumb={secondBreadcrumb}
      fourthBreadcrumb= {fourthBreadcrumb}
    />
    :
    <PageHeading
      title="Педагогический поиск: творчество без границ"
      firstBreadcrumb={firstBreadcrumb}
      thirdBreadcrumb={thirdBreadcrumb}
      secondBreadcrumb={secondBreadcrumb}
      fourthBreadcrumb= {fourthBreadcrumb}
    />
    }
    
      <div>
        <Container className="d-flex flex-column ">
            <Preview
                title={article.title}
                // image={detailArticle.preview}
            />
            <TextArticle content={values}/>
        </Container>
      </div>
    </>
  );
}

export default ArticleDetail;