import {axiosClient} from "../common/axios";
import Api from "../api/Api";

export const createEducationProgram = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.ADD_EDUCATION_PROGRAM, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const updateEducationProgram = async (payload) => {
    try {

        const response = await axiosClient.post(Api.endpoints.UPDATE_EDUCATION_PROGRAM, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const getEducationPrograms = async () => {
    
    try {
        const response = await axiosClient.get(Api.endpoints.GET_EDUCATION_PROGRAMS)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const getEducationProgramById = async ({program_id}) => {
    try {
        const url = Api.endpoints.GET_EDUCATION_PROGRAM_BY_ID.replace('{program_id}', program_id);
        
        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const deleteEducationProgram = async ({program_id}) => {
    try {
        const url = Api.endpoints.DELETE_EDUCATION_PROGRAM.replace('{program_id}', program_id);
        const response = await axiosClient.post(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}