import React, {useEffect, useRef} from "react";
import { useSelector} from "react-redux";
import {Link, useNavigate, useLocation, useParams} from "react-router-dom";
import { 
    Container, 
    Row, 
    Col,
    Card, 
    CardBody, 
    CardTitle,
} from "reactstrap";

import LottiePlayer from "../../Components/player";
import MainImage from "../../assets/images/graduation-two-color.svg";
import HeroBg from "../../assets/lottie/heroBanner1.json";
import ProgramSection from "../../Components/education/ProgramSection";
import { useDispatch } from "react-redux";
import { loadEducationProgramsAsync } from "../../store/reducer/educationProgramsReducer";

const Education = () => {
    const educationPrograms = useSelector((state) => state.educationPrograms);
    const dispatch = useDispatch();
    const bachelorRef = useRef(null);
    const masterRef = useRef(null);
    const graduatedRef = useRef(null);
    const ATPRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        dispatch(loadEducationProgramsAsync())
    }, [])
    
    useEffect(() => {
        const { hash } = location;
        if (hash === '#bachelor' && bachelorRef.current) {
            
            bachelorRef.current.scrollIntoView({ behavior: 'smooth' });
          } else if (hash === '#master' && masterRef.current) {
            
            masterRef.current.scrollIntoView({ behavior: 'smooth' });
          } else if (hash === '#graduated' && graduatedRef.current) {
            
            graduatedRef.current.scrollIntoView({ behavior: 'smooth' });
          } else if (hash === '#ATP' && ATPRef.current) {
            
            ATPRef.current.scrollIntoView({ behavior: 'smooth' });
          }
      }, [location]);
    
    const contestCards = [
        {
            title:"Олимпиады «Покори университет»",
            icon:"",
            url:"https://school.asu.ru/scholar/pokori/"
        },
        {
            title:"Проекты АлтГУ",
            icon:"",
            url:"https://school.asu.ru/scholar/pr/"
        },
        {
            title:"Мероприятия для школьников ",
            icon:"",
            url:"https://school.asu.ru/scholar/events/"
        },
    ]
    
    const bachelor = {
        title: "Учитель будущего начинается сегодня",
        subtitle: "Программы бакалавриата",
        heading1: "Приглашаем на направления педагогического образования!",
        text1: "Здесь высоко ценят тех, кто увлеченно изучает предметы, волнуется о будущем образования, с радостью делится знаниями, умеет заинтересовать слушателей и чутко относится к людям.",
        text2: "После окончания вуза вы сможете преподавать в различных учебных заведениях - от обычных школ до элитных лицеев и колледжей. Также откроется возможность работать репетитором, вести факультативы или открыть собственную образовательную организацию. Выпускники могут продолжить обучение в магистратуре и аспирантуре. Кроме отличного образования, наш вуз предлагает встречи с известными педагогами и учеными, лекции талантливых преподавателей, общение со старшекурсниками, изучение актуальных дисциплин, насыщенную студенческую жизнь, возможность стажировок за рубежом и получение дополнительных квалификаций.",
        text3: "Приходите - и вы раскроете свой педагогический талант!",
    };
    
    const master = {
        title: "От учителя настоящего к учителю будущего",
        subtitle: "Программы магистратуры",
        text1: "Поступайте в магистратуру АлтГУ на программы педагогического образования",
        text2: "Здесь вы научитесь мыслить как инноватор, находить передовые решения для сферы образования и внедрять их. Также вы освоите современные подходы в управлении школами, приобретете опыт работы в элитных учебных заведениях и научитесь руководить исследованиями учеников. Среди перспектив для выпускников - создание авторских образовательных проектов, повышение качества и расширение спектра образовательных услуг, открытие других типов частных образовательных организаций. Также есть возможность продолжить обучение в аспирантуре по педагогике и психологии.",
        text3: "Магистратура АлтГУ станет большим шагом в успешную карьеру лидера инновационного образования!",
    };
    
    const graduated = {
        title: "Учитель как исследователь",
        subtitle: "Программы аспирантуры",
        text1: "Хотите внести вклад в науку и стать высококвалифицированным профессионалом? Поступайте в аспирантуру нашего университета!",
        text2: "Здесь вы сможете вести научные исследования под руководством опытных ученых, осваивать передовые методы и технологии в своей области, публиковаться в авторитетных изданиях и апробировать результаты на международных конференциях. Также у вас будет возможность изучать лучший зарубежный опыт благодаря стажировкам и академическим обменам, а также получить углубленные знания по выбранному научному направлению.",
        text3: "Начните работу над диссертацией уже сейчас, чтобы совершить рывок в академической карьере!",
    };
    
    const ATP = {
        title: `Пространство системы ДПО: тренды и перспективы ${currentYear}`,
        subtitle: "Программы ДПО для будущих учителей",
        text1: "Студенческие годы - это время поиска себя, приобретения новых навыков и раскрытия своего потенциала. Поступив в наш университет, вы уже сделали важный шаг в будущую профессию. Но на этом возможности для самореализации не заканчиваются!",
        text2: "Мы предлагаем вам получить дополнительную квалификацию педагога - и расширить свои карьерные перспективы. Это позволит после выпуска прийти работать в школу или реализовать себя в сфере образования на следующих этапах профессионального пути. Занятия ведут опытные наставники - действующие учителя и преподаватели. Обучение сфокусировано на практических навыках, чтобы вы смогли сразу приступить к работе.",
        text3: "Не упустите возможность приобрести востребованную квалификацию уже во время учебы! Записывайтесь на курсы - и расширяйте свои карьерные перспективы!",
    };
    
    return(
        <div className="page-content">
            <section className="px-lg-7 px-2">
                
                <Container>
                <h3 className="mb-6">Поступай в АлтГУ на педагогические направления и специальности!</h3>
                <div className="d-flex">
                    <div>
                        <p className="lead">
                        Университет обладает уникальными возможностям для профессионального становления элитных педагогов, которые успешно владеют:
                        </p>
                        <ul>
                            <li>фундаментальной предметной подготовкой;</li>
                            <li>проектно-исследовательским стилем мышления и деятельности;</li>
                            <li>междисциплинарными компетенциями;</li>
                            <li>навыками «цифрового учителя»;</li>
                            <li>инновационными образовательными практиками;</li>
                            <li>опытом воспитания будущей интеллектуальной элиты общества;</li>
                        </ul>
                        <p>Мы ждем тебя на образовательных программах довузовского педагогического образования, бакалавриата, специалитета, магистратуры, аспирантуры, профессиональной переподготовки и повышения квалификации. </p>
                        <div className="position-absolute animation-1">
                            <LottiePlayer src={HeroBg} />
                        </div>
                    </div>
                    <img 
                    src={MainImage} 
                    width={600} 
                    height={500} 
                    alt=""
                    className="object-fit-cover d-none d-lg-block"
                     />
                </div>
                </Container>
                
            </section>
            <section className="px-lg-7 px-2">
                    <Container>
                        <div className="position-relative">
                            <h3 className="mb-6">Федеральная инновационная площадка <br/>«Новые модели педагогического образования в университете»</h3>
                            <p className="lead">
                            Цель инновационного образовательного проекта, определенная ФИП, – разработка и апробация новых моделей системы подготовки педагогических кадров в университете, основанной на идеях целостности, непрерывности, опережающего развития, индивидуализации, направленной на удовлетворение потребностей региона в высококвалифицированных педагогах новой эпохи, лидерах образования как инновационном ресурсе развития образовательной отрасли.
                            </p>
                            
                                <Link to="https://www.asu.ru/univer_about/program/priority/fip/" target="_blank" className="btn btn-primary">Подробнее</Link>
                                {/* <Link to="#bachelor"className="btn btn-primary">Подробнее</Link> */}
                            
                            <div className="position-absolute animation-1">
                                <LottiePlayer src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json" />
                            </div>
                        </div>
                    </Container>
            </section>
            
            <section className="px-lg-7 px-2">
                    <Container>
                        <div className="position-relative">
                            <h3 className="mb-6">Олимпиады, конкурсы, проекты</h3>
                            <Row>
                            {contestCards.map((card, index) => (
                                <Col lg={4} key={index} className="mb-5">
                                    <Card className="h-100 lectures-card card-hover">
                                    <CardBody className="d-flex flex-column justify-content-between">
                                      <Link to={card.url} target="_blank">
                                        <div className="d-flex mb-direction-column py-1">
                                            <div className="d-flex flex-column justify-content-between ">
                                            <CardTitle tag="h5">
                                                {card.title}
                                            </CardTitle>
                                            <div>
                                                <Link to={card.url} className="btn-arrow"></Link>
                                            </div>  
                                            </div>
                                        </div>
                                      </Link>
                                      
                                    </CardBody>
                                    
                                  </Card>
                                </Col>
                                ))}
                            </Row>
                            <div className="position-absolute animation-1">
                                <LottiePlayer src="https://lottie.host/59ba3e9a-bef6-400b-adbb-0eb8c20c9f65/WPBRmjAinD.json" />
                            </div>
                        </div>
                    </Container>
            </section>
            
            <ProgramSection 
                title={bachelor.title} 
                subtitle={bachelor.subtitle}
                text1={bachelor.text1}
                text2={bachelor.text2}
                text3={bachelor.text3}
                programs={educationPrograms.bachelor}
                
                innerRef={bachelorRef}
            />
            
            <ProgramSection 
                title={master.title} 
                subtitle={master.subtitle} 
                text1={master.text1}
                text2={master.text2}
                text3={master.text3}
                programs={educationPrograms.master}
                
                innerRef={masterRef}
            />
            
            <ProgramSection 
                title={graduated.title} 
                subtitle={graduated.subtitle}
                text1={graduated.text1} 
                text2={graduated.text2} 
                text3={graduated.text3} 
                programs={educationPrograms.graduated}
                
                innerRef={graduatedRef}
            />
            
            <ProgramSection 
                title={ATP.title} 
                subtitle={ATP.subtitle}
                text1={ATP.text1}
                text2={ATP.text2}
                text3={ATP.text3}
                programs={educationPrograms.ATP}
                
                innerRef={ATPRef}
            />
        </div>    
    )
}

export default Education;