import {axiosClient} from "../common/axios";
import Api from "../api/Api";

export const createOnlineLecture = async (payload) => {
    try {
        const response = await axiosClient.post(Api.endpoints.ADD_ONLINE_LECTURE, payload);
        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const updateOnlineLecture = async (payload) => {
    try {

        const response = await axiosClient.put(Api.endpoints.UPDATE_ONLINE_LECTURE, payload);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const getOnlineLectures = async () => {
    
    try {
        const response = await axiosClient.get(Api.endpoints.GET_ONLINE_LECTURES)
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return {
            success: false
        };
    }
}

export const getOnlineLectureById = async ({lecture_id}) => {
    try {
        const url = Api.endpoints.GET_ONLINE_LECTURE_BY_ID.replace('{lecture_id}', lecture_id);
        
        const response = await axiosClient.get(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}

export const deleteOnlineLecture = async (lecture_id) => {
    try {
        const url = Api.endpoints.DELETE_ONLINE_LECTURE.replace('{lecture_id}', lecture_id);
        const response = await axiosClient.delete(url);

        return {
            success: response.status === 200,
            data: response.data,
            response: response,
        }
    } catch (e) {
        return {
            success: false,
            response: e.response,
        }
    }
}