import React, { useState, useEffect } from "react";
import PageHeading from "../../Components/page-heading/Page_Heading";
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  changeFilterOrder,
  changeFilterCount,
  changeFilterPage
} from "../../store/reducer/manage-products/productsFilter";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../Components/pagination";
import SideBar from "../../Components/sidebar/SideBar";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";
import ProductCardVertical from "../../Components/products/ProductCardVertical"
import ProductCardHorizontal from "../../Components/products/ProductCardHorizontal";
import { loadProductsAsync } from "../../store/reducer/productReducer";
import { useIsMobile } from "../../hooks/hooks";

const ProductGrid = () => {
  const firstBreadcrumb = { label: "", link: "/product-grid" };
  const secondBreadcrumb = {
    label: "Методические материалы",
    link: "/product-grid",
    active: true,
  };
  const { isMobile } = useIsMobile();
  const params = useParams();
  const dispatch = useDispatch();
  const productsFilter = useSelector((state) => state.productsFilter);
  const products = useSelector((state) => state.products.products);
  const [ showType, setShowType ] = useState("grid")
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [activePage, setActivePage] = useState(1);
  const pageSize = 6;
  

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    dispatch(changeFilterPage(productsFilter.filterPage + 1))
    dispatch(changeFilterCount(productsFilter.filterCount + 12))
    
    const payload = {
      category: productsFilter.fliterCategory,
      min_price: productsFilter.filterMinPrice,
      max_price: productsFilter.filterMaxPrice,
      order: productsFilter.filterOrder,
      page: productsFilter.filterPage,
      count: productsFilter.filterCount
    }

    window.scroll(0,0)
    dispatch(loadProductsAsync(payload));
  };
  const totalPages = Math.ceil(products.length / pageSize);
  
  const onSortClick = (order) => {
    dispatch(changeFilterOrder(order))
    const payload = {
      category: productsFilter.fliterCategory,
      min_price: productsFilter.filterMinPrice,
      max_price: productsFilter.filterMaxPrice,
      order: order
    }
    window.scroll(0,0)
    dispatch(loadProductsAsync(payload));
  }
  
  return (
    <>
    {!params.productId ? <>
      <div className="page-wrapper">
      <PageHeading
        title="Методические материалы"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />

      <div className="page-content py-2">
        <Container>
          <Row className="mb-5">
            <Col>
              <Card className="border p-3 rounded">
                <Row className="align-items-end justify-content-end">
                  {/* <Col md="5" className="mb-3 mb-md-0">
                    <CardText tag="span" className="text-muted">
                      Показано {productsToShow.length} из {filteredProducts.length}
                    </CardText>
                  </Col> */}
                  <Col
                    className="d-flex align-items-center justify-content-md-end"
                  >
                    <div className="me-3">
                      <Button
                        onClick={()=>setShowType("grid")}
                        className={`${showType === "grid" ? "active text-primary" : "text-dark "}  border-0  me-2 bg-transparent`}
                      >
                        <i className="fs-3 bi bi-grid-3x2"></i>
                      </Button>
                      {!isMobile && <Button 
                                      onClick={()=>setShowType("list")}
                                      className={`${showType === "list" ? "active text-primary" : "text-dark "} border-0  me-2 bg-transparent`}
                                    >
                                      <i className="fs-3 bi bi-card-list"></i>
                                    </Button>
                      }
                      
                    </div>
                    <div className="sort-filter ms-3 d-flex align-items-center">
                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle
                          caret
                          className="bg-transparent text-dark  ms-3"
                        >
                          Сортировать
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => onSortClick("creation_time DESC")}>Новое</DropdownItem>
                          <DropdownItem onClick={() => onSortClick("Price")}>Сначала дешевые</DropdownItem>
                          <DropdownItem onClick={() => onSortClick("PRice DESC")}>Сначала дорогие</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={9} md={12} className="order-lg-1">
              <Row className={`${showType === "grid" && "d-flex flex-wrap justify-content-center"} text-center `}>
              <>
                  {
                    showType === "grid" ? <>
                    {products.map((product, index) => (
                  <ProductCardVertical key={index} item={product}/>
                ))}</>
                                        : <>
                                        {products.map((product, index) => (
                  <ProductCardHorizontal key={index} item={product}/>
                ))}
                                        </>
                  }
                  </>
                
                
                {/* <Row
                  className="mt-5"
                  style={{ display: "inline-grid", justifyContent: "center" }}
                >
                  <CustomPagination
                    activepage={activePage}
                    totalpages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </Row> */}
              </Row>
            </Col>
            <Col lg={3} md={12}>
              <SideBar />
            </Col>
          </Row>
        </Container>
        <RegisterBanner />
      </div>
    </div>
    </>
    : <Outlet/>
    }
    </>
    
  );
}

export default ProductGrid;
