import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import {
    Button, 
    FormGroup, 
    Input, 
    Label, 
    Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {useMount} from "../../../hooks/hooks";
import {
    clearState,
    changeTitleEducationProgram,
    changeDescriptionEducationProgram,
    changeEducationProgramUrl,
    changeTypeEducationProgram,
    changePreview,
    loadDetailEducationProgramAsync
} from "../../../store/reducer/manage-education-programs/detail-education-program";
import {uploadFiles} from "../../../services/uploadFiles";
import {getStaticUrlFile} from "../../../common/helpers";
import { typeItems } from './AddEducationProgram';
import { updateEducationProgram } from '../../../services/EducationProgram';
import EducationItem from '../../../Components/education/EducationItem';
import { toast } from "react-toastify";
const Title = () => {
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);
    
    const dispatch = useDispatch();
    
    const onChange = (event) => {
        const value = event.currentTarget.value || "";
        dispatch(changeTitleEducationProgram(value))
    }

    return (
        <FormGroup floating>
            <Input 
            placeholder={"Заголовок"} 
            value={detailEducationProgram.title} 
            onChange={onChange}
            id="title"
            />
            <Label for="title">
                Заголовок
            </Label>
        </FormGroup>
    )
}

const Description = () => {
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";
        dispatch(changeDescriptionEducationProgram(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Описание"} 
            value={detailEducationProgram.description} 
            onChange={onChange}
            id="description"
        />
        <Label for="description">
            Описание
        </Label>
    </FormGroup>
    )
}

const ProgramUrl = () => {

    const dispatch = useDispatch();
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);

    const onChange = (event) => {
        const value = event.currentTarget.value || "";
        dispatch(changeEducationProgramUrl(value));
    }

    
    return (
        <FormGroup floating>
            <Input 
                placeholder={"Ссылка на программу"} 
                value={detailEducationProgram.program_url} 
                onChange={onChange}
                id="program_url"
            />
            <Label for="program_url">
                Ссылка на программу
            </Label>
        </FormGroup>
    )
}


const Type = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);
    
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    
    const onItemClick = (item) => {
        dispatch(changeTypeEducationProgram(item))
    }
    
    return (
        <div className="d-flex flex-column">
            
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
                <DropdownToggle caret>Выберите уровень образования</DropdownToggle>
                <DropdownMenu>
                    {
                        typeItems.map((item) => (
                            <DropdownItem onClick={() => onItemClick(item.title)}>{item.title}</DropdownItem>
                        ))
                    }
                </DropdownMenu>
            </Dropdown>
            <p className='mt-2'>Уровень образования: {detailEducationProgram.type}</p>

        </div>
    )
}

const Preview = () => {
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);
    
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    let preview
    const onClickPreviewHandler = () => inputRef?.current?.click();
    const onChangePreview = async (event) => {
        const files = event?.currentTarget?.files;
        
        if (files.length > 0) {
            
            setPreviewFile(files[0]);
            setFiles([files[0]]);
            
            const responseUpload = await uploadFiles({files: [files[0]]});
            preview = responseUpload?.data?.names[0] || "";
            dispatch(changePreview(getStaticUrlFile(preview)));
        }
    }
    const payload = {
        id: detailEducationProgram.id,
        title: detailEducationProgram.title,
        description: detailEducationProgram.description,
        preview_image_link: detailEducationProgram.preview_image_link,
        program_url: detailEducationProgram.program_url,
        creation_time: detailEducationProgram.creation_time,
        type: detailEducationProgram.type
    }
    return (
        <div className='d-flex flex-column gap-1 w-100 mb-5'>
            <div className='d-flex justify-content-center'>
                <EducationItem  onEditorial={false} program={payload}/>
            </div>
            <Button onClick={onClickPreviewHandler} className='btn btn-primary '>Изменить изображение</Button>

            <input type={"file"}
                   accept={"image/*"}
                   ref={inputRef}
                   onChange={onChangePreview}
                   className={"d-none"}/>
        </div>
    )
}


const SaveButton = () => {
    const detailEducationProgram = useSelector((state) => state.detailEducationProgram);
    const dispatch = useDispatch();
    
    const saveEducationProgramInfo = async () => {
        let previewImage = detailEducationProgram.preview_image_link;
    
        const payload = {
            id: detailEducationProgram.id,
            title: detailEducationProgram.title,
            description: detailEducationProgram.description,
            type: detailEducationProgram.type,
            preview_image_link: getStaticUrlFile(previewImage),
            program_url: detailEducationProgram.program_url
        }
        await updateEducationProgram(payload);
    }

    const onSaveHandler = async () => {
        try {
            await saveEducationProgramInfo();
            toast.success('Изменения сохранены!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
                  
        } catch (e) {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }
    }

    return <Button className='btn btn-primary' onClick={onSaveHandler}>Сохранить</Button>
}

const CancelButton = () => {

    const navigate = useNavigate()

    const onCancelHandler = () => navigate(0);

    return <Button onClick={onCancelHandler}>Отменить</Button>;
}


const EditEducationProgram = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const onBackClick = () => {
        navigate(-1)
    }
    useEffect(() => {
        dispatch(loadDetailEducationProgramAsync(params.programId))
    }, []);
    
    return (
        <div className='w-100  mt-5'>
            <Container className='d-flex flex-column gap-3'>
                <div className="w-100 d-flex justify-content-between mb-4">
                    <h3>Изменить Программу обучения</h3>
                    <Button className="btn" onClick={() => navigate('/editorial/manage-education-programs')}>Назад</Button>
                </div>
                <Preview/>
                <Title/>
                <Description/>
                <ProgramUrl/>
                <Type/>
                <div className='d-flex w-100 gap-1'>
                    <SaveButton/>
                    <CancelButton/>
                </div>
            </Container>
        </div>

    );
};

export default EditEducationProgram;