import React, {useState, useRef, useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {Input, Button, Container, FormGroup, Label} from 'reactstrap'
import {FormikProvider} from "formik";
import {getStaticUrlFile} from "../../../common/helpers";
import {uploadFiles} from "../../../services/uploadFiles";
import DropZone from '../../../Components/DropZone/DropZone';
import { TextEditor } from '../../../Components/EditorComponent/TextEditor/TextEditor';
import {
    changeTitleProduct,
    changeCategoryProduct,
    changePriceProduct,
    changePriceWithDiscountProduct,
    changeShortDescriptionProduct
} from "../../../store/reducer/manage-products/detail-product";
import { toast } from "react-toastify";
import { createProduct } from '../../../services/Products';

const MAX_TITLE_LENGTH = 128;
const MAX_DESCRIPTION_LENGTH = 250;
const MAX_CATEGORY_LENGTH = 50;

const Title = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_TITLE_LENGTH) return;

        dispatch(changeTitleProduct(value));
    }

    return (
        <FormGroup floating>
            <Input id ="title"placeholder={"Заголовок"} type='text' onChange={onChange}/>
            <Label for="title">
                Заголовок
            </Label>
        </FormGroup>
    )
}

const ShortDescription = () => {

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_DESCRIPTION_LENGTH) return;

        dispatch(changeShortDescriptionProduct(value));
    }

    return (
        <FormGroup floating>
            <Input id="shortDescription" placeholder={"Короткое описание"} type='text' onChange={onChange}/>
            <Label for="shortDescription">
                Короткое описание
            </Label>
        </FormGroup>
        )
}

const Category = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_CATEGORY_LENGTH) return;

        dispatch(changeCategoryProduct(value));
    }
    
    return (
        <FormGroup floating>
            <Input id="category" placeholder={"Категория"} type='text' onChange={onChange}/>
            <Label for="category">
            Категория
            </Label>
        </FormGroup>
        )
}

const Price = () => {
    const dispatch = useDispatch();
    const detailProduct = useSelector((state) => state.detailProduct);
    const [isDiscounted, setIsDiscounted] = useState(false);

    const onPriceChange = (event) => {
        const value = event.currentTarget.value || "";
        if (!isNaN(value)) {
            dispatch(changePriceProduct(value));
        }
    }

    const onPriceWithDiscountChange = (event) => {
        const value = event.currentTarget.value || "";
        if (!isNaN(value)) {
            dispatch(changePriceWithDiscountProduct(value));
        }
    }

    const handleCheckboxChange = () => {
        setIsDiscounted(!isDiscounted);
    }

    return (
        <>
            <FormGroup floating>
                <Input 
                    id="price" 
                    placeholder={"Цена"} 
                    type='text' 
                    onChange={onPriceChange} 
                    style={{ borderColor: isNaN(detailProduct.price) ? 'red' : '' }}
                />
                <Label for="price">
                    Цена
                </Label>
            </FormGroup>
            <FormGroup check>
                <Input 
                    type="checkbox" 
                    onChange={handleCheckboxChange}
                />
                <Label check>
                    Со скидкой
                </Label>
            </FormGroup>
            <FormGroup floating>
                <Input 
                    id="price_with_discount" 
                    placeholder={"Цена со скидкой"} 
                    type='text' 
                    onChange={onPriceWithDiscountChange} 
                    disabled={!isDiscounted} // Делаем второй input недоступным при отсутствии скидки
                    style={{ borderColor: isNaN(detailProduct.price_with_discount) && isDiscounted ? 'red' : '' }} // Подсветка красным при ошибке
                />
                <Label for="price_with_discount">
                    Цена со скидкой
                </Label>
                {isNaN(detailProduct.price_with_discount) && isDiscounted && (
                    <div style={{ color: 'red' }}>Введите число</div> // Текстовое сообщение при ошибке ввода
                )}
            </FormGroup>
        </>
    );
}


const AddProduct = () => {
    
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const [productfiles, setProductFiles] = useState([]);
    const [previewProductFile, setProductPreviewFile] = useState();
    const detailProduct = useSelector((state) => state.detailProduct);
    const dispatch = useDispatch();
    const ref = useRef({});
    const navigate = useNavigate();
    
    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);
    
    const onChangeFiles = (files) => {
        if (files.length > 0) {
            setPreviewFile(files[0]);
            setFiles([files[0]]);
        }
    }
    const onChangeProductFiles = (files) => {
        if (files.length > 0) {
            setProductPreviewFile(files[0]);
            setProductFiles([files[0]]);
        }
    }
   
    const onAddProduct = async() => {
        const responseUpload = await uploadFiles({files: [previewFile]});
        const preview = responseUpload?.data?.names[0] || "";
        
        const responseUploadMedia = await uploadFiles({files: [previewProductFile]});
        const previewMedia = responseUploadMedia?.data?.names[0] || "";
        
        const content = await ref.current.save();
        
        const payload = {
            title: detailProduct.title,
            short_description: detailProduct.short_description,
            category: detailProduct.category,
            preview_image_link: getStaticUrlFile(preview),
            content: content,
            price: detailProduct.price,
            price_with_discount: detailProduct.price_with_discount,
            media: getStaticUrlFile(previewMedia)
        }
        if(payload.title === "" || payload.price === "" || payload.category === "" || payload.short_description === ""){
            toast.warn('Проверьте, все ли поля заполнены', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return; 
        }
        
        const response = await createProduct(payload);
        if( response.success ){
            toast.success('Продукт успешно добавлен', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
            
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
        
    }
   

    return (
        
        <Container>
            <div className="w-100 d-flex justify-content-between mb-4">
                <h3>Добавить продукт в методические материалы</h3>
                <Button className="btn" onClick={() => navigate('/editorial/manage-products')}>Назад</Button>
            </div>
            <FormikProvider>
                <form>
                    <div className='d-flex w-100 flex-column gap-3 p-4'>

                        <div className='d-flex w-100 flex-column gap-3 '>
                            <Title/>
                            <ShortDescription/>
                            <Category/>
                            <Price/>
                            <div className='d-flex flex-row justify-content-around'>
                                <div className='d-flex flex-column '>
                                    <p className='fw-bold'>Изображение для заголовка</p>
                                    <DropZone files={files} onChangeFiles={onChangeFiles}/>
                                </div>
                                <div className='d-flex flex-column '>
                                    <p className='fw-bold'>Методические материалы</p>
                                    <DropZone files={productfiles} onChangeFiles={onChangeProductFiles}/>
                                </div>
                            </div>
                            <div>
                                <h6>Подробное описание</h6>
                                <div className="d-flex w-100 flex-column mt-4 border rounded" styles={{backGround:"#000"}}>
                                    <TextEditor setRef={setRef} />
                                </div>
                            </div>
                        </div>

                        <div className='d-flex w-100 gap-1'>
                            <Button className='btn btn-primary'  onClick={onAddProduct}>Создать</Button>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </Container>
    );
};

export default AddProduct;