import React, {useState, useEffect} from "react";
import SectionsList from "../../../Components/PedagogicalSearch/SectionsList";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate,useLocation, Outlet} from 'react-router-dom';
import {Container, Button} from 'reactstrap';
import PSAdminItems from "../../../Components/PedagogicalSearch/PSAdminItems";
import ConfirmDeleteModal from "../../../Components/Editorial/ConfirmDeleteModal"

const ManageSubsections = () => {
    const [showSectionList, setShowSectionList] = useState(true)
    const subsections = useSelector((state) => state.pedagogicalSearch);
    const navigate = useNavigate();
    let location = useLocation();
    
    const onCreateClick = () =>{
        navigate("add-subsections")
    }
    return (
        
        <Container className="d-flex gap-3 flex-column px-10 mt-10">
            {location.pathname === "/editorial/manage-subsections" ?
            <>
        {!showSectionList &&
                                <>
                                <div className="w-100 d-flex justify-content-between mb-4">
                                    <h3>Подрубрики</h3>
                                    <Button className="btn btn-primary" onClick={onCreateClick}>Создать</Button>
                                </div>
                                {subsections.subsections.map((item, index) => (
                                    <PSAdminItems item={item} index={index}/>
                                    ))}
                                <ConfirmDeleteModal item={subsections.selectedItem}/>
                                </>
            }
        {showSectionList && <SectionsList showSectionList={showSectionList} setShowSectionList={setShowSectionList}/>}  
                                                                    </> 
                                                                : <Outlet/>
            }
    </Container>
         
    )
}

export default ManageSubsections;