import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getProductById } from "../../../services/Products";
import MockBackgroundImage from "../../../assets/images/bg/background-mock.png";

const initialState = {
    id: 0,
    title: '',
    creation_time: '',
    category: '',
    short_description: '',
    preview_image_link: MockBackgroundImage,
    price: null,
    price_with_discount: null,
    content: null,
    media: null
}

export const loadDetailProductAsync = createAsyncThunk(
    "loadDetailProductAsync",
    async (productId) => {
        const response = await getProductById({product_id: productId})
        return response.data;
    }
)

const detailProductSlice = createSlice({
  name: "detailProduct",
  initialState,
  reducers: {
    clearState: (state) => {
        state.id = 0
        state.title = ''
        state.category = ''
        state.short_description = ''
        state.preview_image_link = MockBackgroundImage
        state.price = null
        state.price_with_discount = null
        state.content = null
        state.media = null
    },
    changeTitleProduct: (state, action) => {
        state.title = action.payload;
    },
    changeProductId: (state, action) => {
        state.id = action.payload;
    },
    changeContent: (state, action) => {
        state.content = action.payload;
    },
    changePreview: (state, action) => {
        state.preview_image_link = action.payload;
    },
    changeCategoryProduct: (state, action) => {
        state.category = action.payload;
    },
    changePriceProduct: (state, action) => {
        state.price = action.payload;
    },
    changePriceWithDiscountProduct: (state, action) => {
        state.price_with_discount = action.payload;
    },
    changeShortDescriptionProduct: (state, action) => {
        state.short_description = action.payload;
    },
    changeMediaProduct: (state, action) => {
        state.media = action.payload;
    },
    resetFilters(state) {
      state.filters = initialState;
    },
  },
  extraReducers: builder => {
    builder
        .addCase(loadDetailProductAsync.fulfilled, (state, action) => {
            if (action.payload) {
                const {
                    id,
                    title,
                    creation_time,
                    category,
                    short_description,
                    preview_image_link,
                    price,
                    price_with_discount,
                    content,
                    media
                } = action.payload;

                state.id = id;
                state.title = title;
                state.short_description = short_description;
                state.content = content;
                state.preview_image_link = preview_image_link;
                state.category = category;
                state.creation_time = creation_time;
                state.price = price;
                state.price_with_discount = price_with_discount;
                state.media = media;
            }
        })  
    }
});

export const {
    clearState,
    changeTitleProduct,
    changeProductId,
    changeContent,
    changePreview,
    changeCategoryProduct,
    changePriceProduct,
    changePriceWithDiscountProduct,
    changeShortDescriptionProduct,
    changeMediaProduct
} = detailProductSlice.actions;

export default detailProductSlice.reducer;
