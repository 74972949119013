import React from "react";
import '@dotlottie/player-component';

function DotLottiePlayer({ src }) {
  return (
    
    <dotlottie-player
    src={src}
    className="player"
    speed={1}
    style={{ width: "auto", height: "auto" }}
    loop
    background="transparent"
    autoplay
    >
    </dotlottie-player>
  );
}

export default DotLottiePlayer;
