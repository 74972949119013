import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {   
    setFullName,
    setEmail,
    setPhoneNumber,
    setLessonDate,
    setMessage } from "../../store/reducer/manage-interactive-lessons/interactive-lesson-form";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
    Col
  } from 'reactstrap';
import { addInteractiveLessonRequest } from "../../services/InteractiveLessonsService";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth";


const InteractiveLessonsForm = ({ isOpen, toggle, className, backdrop, keyboard }) => {
    const dispatch = useDispatch();
    const interactiveLessonData = useSelector((state) => state.interactiveLessonsForm);
    const interactiveLessons = useSelector((state) => state.interactiveLessons);
    const profile = useSelector((state) => state.userInfo.profile);
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const now = new Date();
    const minDate =  now.getFullYear() + "-" + ("0"+(now.getMonth()+1)).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      if(!isAuthenticated) {
        navigate('/login')
        toast.error('Войдите, чтобы оставить заявку', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        return  
      }
      const payload = {
        full_name: interactiveLessonData.fullName,
        user_id: profile.userId,
        email: interactiveLessonData.email,
        phone_number: interactiveLessonData.phone_number,
        lesson_date: interactiveLessonData.lesson_date,
        message: interactiveLessonData.message,
        lesson_link: interactiveLessonData.lesson_link,
        status: "Ожидает"
      }
      const response = await addInteractiveLessonRequest(payload);
      if( response.success ){
          toast.success('Запрос на интерактивный урок отправлен', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
          toggle()
          
      }
      else {
          toast.error('Что-то пошло не так', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });  
      }
    };
    //ФИО почта телефон, когда хотите провести, блок для комментария
    return(
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className={className}
            backdrop={backdrop}
            keyboard={keyboard}
            
        >
            <ModalHeader toggle={toggle}>Оставьте заявку на проведение интерактивного урока:</ModalHeader>
            <h6 className="pt-2 px-3">{interactiveLessons.selectedInteractiveLesson.name}</h6>
            <ModalBody>
                <Form id="contact-form" className="row p-2" onSubmit={handleSubmit}>
                    <div className="messages"></div>
                    <FormGroup className="col-md-12">
                        <Label className="font-w-6">ФИО</Label>
                        <Input
                            type="text"
                            name="fullName"
                            value={interactiveLessonData.fullName}
                            onChange={(e) => dispatch(setFullName(e.target.value))}
                            placeholder="ФИО"
                            required
                            data-error="Обязательное поле"
                        />
                        <div className="help-block with-errors"></div>
                    </FormGroup>
                    <FormGroup className="form-group col-md-12">
                        <Label className="font-w-6">Эл. почта</Label>
                        <Input
                            type="email"
                            name="email"
                            value={interactiveLessonData.email}
                            onChange={(e) => dispatch(setEmail(e.target.value))}
                            placeholder="Эл. почта"
                            required
                            data-error="Обязательное поле"
                        />
                        <div className="help-block with-errors"></div>
                    </FormGroup>
                    <FormGroup className="form-group col-md-12">
                        <Label className="font-w-6">Номер телефона</Label>
                        <Input
                            type="tel"
                            name="phone"
                            value={interactiveLessonData.phoneNumber}
                            onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
                            placeholder="Номер телефона"
                            required
                            data-error="Обязательное поле"
                        />
                        <div className="help-block with-errors"></div>
                    </FormGroup>
                    <FormGroup className="form-group col-md-12">
                        <Label className="font-w-6">Когда вы хотите провести?</Label>
                        <Input
                            type="date"
                            name="message"
                            value={interactiveLessonData.lessonDate}
                            onChange={(e)=>{dispatch(setLessonDate(e.target.value))}}
                            className="form-control rounded-4 h-auto"
                            placeholder="Когда вы хотите провести?"
                            min={minDate}
                            rows="4"
                            required
                            data-error="Обязательное поле"
                        />
                        <div className="help-block with-errors"></div>
                    </FormGroup>
                    <FormGroup className="form-group col-md-12">
                        <Label className="font-w-6">Комментарий</Label>
                        <Input
                            type="textarea"
                            name="message"
                            value={interactiveLessonData.message}
                            onChange={(e) => dispatch(setMessage(e.target.value))}
                            className="form-control rounded-4 h-auto"
                            placeholder="Комментарий"
                            rows="4"
                        />
                        <div className="help-block with-errors"></div>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>
               Оставить заявку
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
                Отмена
            </Button>
            </ModalFooter>
        </Modal>
    )
}

export default InteractiveLessonsForm;