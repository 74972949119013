import React, { useEffect, useState, useRef } from "react";
import {useSelector, useDispatch} from "react-redux";
import {Container, Button, Card, CardImg, CardBody, FormGroup, Input, Label} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {updateSliderImagesOrder, loadSliderImagesAsync} from '../../../store/reducer/sliderReducer';
import {uploadFiles} from "../../../services/uploadFiles";
import {getStaticUrlFile} from "../../../common/helpers";
import {addSliderImage, deleteSliderImage, updateSliderImages} from "../../../services/MainSliderService";
 
import {
    changeImage,
    changeLink,
    changeOrder
} from "../../../store/reducer/manage-main-slider/detail-main-slider"

const EditSliderImages = () => {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const sliderImages = useSelector((state) => state.slider.sliderImages);
    const detailSlider = useSelector((state) => state.detailSlider);
    
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [imageFile, setImageFile] = useState();
    const inputRef = useRef(null);
    let image
    
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(sliderImages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const updatedSliderImagesWithOrder = items.map((item, index) => ({
            ...item,
            order: index + 1 
        }));
        updateSliderImages(updatedSliderImagesWithOrder);
        dispatch(updateSliderImagesOrder(updatedSliderImagesWithOrder));
    };
    
    const onAddImageClick = async() => {
        const payload = {
            preview_image_link: detailSlider.preview_image_link,
            page_link: detailSlider.page_link,
            order: sliderImages.length + 1
        };
        await addSliderImage(payload);
        dispatch(loadSliderImagesAsync());
    }
    
    const onClickImageHandler = () => inputRef?.current?.click();
    const onChangeImage = async (event) => {
        const files = event?.currentTarget?.files;
        
        if (files.length > 0) {
            
            setImageFile(files[0]);
            setFiles([files[0]]);
            
            const responseUpload = await uploadFiles({files: [files[0]]});
            image = responseUpload?.data?.names[0] || "";
            dispatch(changeImage(getStaticUrlFile(image)));
        }
        
    }
    
    const onChangeLink = (index, newLink) => {
        const updatedSliderImages = sliderImages.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    page_link: newLink
                };
            }
            return item;
        });
        dispatch(updateSliderImagesOrder(updatedSliderImages));
    };
    const onDeleteImage = async(image_id) => {
        await deleteSliderImage({image_id: image_id});
        dispatch(loadSliderImagesAsync());
    }
    
    const onSave = async() => {
        const response = await updateSliderImages(sliderImages);
        if (response.success) {
            setShowSuccessMessage(true);
        }else {
            setShowErrorMessage(true);
        }
        setTimeout(() => {
            setShowSuccessMessage(false);
            setShowErrorMessage(false);
        }, 3000);
    }
    
    useEffect(() => {
        dispatch(loadSliderImagesAsync());
    }, []);
    
    return (
        <>
            <Container className="d-flex gap-3 flex-column px-10">
                <div className="w-100 d-flex justify-content-between mb-4">
                    <h3>Слайдер</h3>
                    <Button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addSliderImage">Добавить изображение</Button>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="sliderImages">
                        {(provided) => (
                            <div
                                className="d-flex flex-column flex-wrap gap-3"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {sliderImages.map((item, index) => (
                                    <Draggable key={index} draggableId={String(index)} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Card className="d-flex flex-column p-4 search-card">
                                                    <div className="d-flex flex-row justify-content-between mb-2">
                                                        <div className="">{index+1}/{sliderImages.length}</div>
                                                        <Button className="btn btn-link text-danger p-0" onClick={() => onDeleteImage(item.id)}>Удалить</Button>
                                                    </div>
                                                    <div className="d-flex justify-content-between" style={{maxHeight: "400px"}}>
                                                        {item.preview_image_link && (
                                                            <CardImg
                                                                className="rounded-4 object-fit-cover"
                                                                src={item.preview_image_link}
                                                            />
                                                        )}
                                                    </div>
                                                    <CardBody className="d-flex justify-content-end flex-column">
                                                        <div className="d-flex ">
                                                            <FormGroup floating className="w-100">
                                                                <Input
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Ссылка"
                                                                    id={`form_link_${index}`}
                                                                    className="form-control"
                                                                    value={item.page_link}
                                                                    onChange={(e) => onChangeLink(index, e.target.value)}
                                                                    required

                                                                />
                                                                <Label for={`form_link_${index}`}>
                                                                    Ссылка на любую страницу(не обязательно)
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className={`d-flex align-items-center ${showSuccessMessage || showErrorMessage ? "justify-content-between" : "justify-content-end"}`}>
                                                            {showSuccessMessage && <div className="text-success">Успешно сохранено</div>}
                                                            {showErrorMessage && <div className="text-danger">Что-то пошло не так</div>}
                                                            <Button className="align-self-end btn-primary" onClick={onSave}>Сохранить</Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
            
            <div class="modal fade" id="addSliderImage" tabIndex="-1" aria-labelledby="addSliderImage" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="addSliderImageLabel">Добавить изображение в Слайдер</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body d-flex flex-column gap-3">
                            <CardImg
                                className="rounded-4 object-fit-cover"
                                src={detailSlider.preview_image_link} 
                            />
                            <Button onClick={onClickImageHandler} className='btn btn-primary w-100'>Изменить изображение</Button>
                            <input type={"file"}
                                    accept={"image/*"}
                                    ref={inputRef}
                                    onChange={onChangeImage}
                                    className={"d-none"}/>
                            <FormGroup floating>
                                <Input
                                    type="text"
                                    name="name"
                                    placeholder="Ссылка"
                                    id="form_link"
                                    className="form-control"
                                    value={detailSlider.page_link}
                                    onChange={(e) => dispatch(changeLink(e.target.value))}
                                    required

                                />
                                <Label for="form_link">
                                    Ссылка на любую страницу(не обязательно)
                                </Label>
                            </FormGroup>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={onAddImageClick}>Добавить</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отменить</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditSliderImages;