import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import MockBackgroundImage from "../../../assets/images/bg/background-mock.png";
import { getInteractiveLessonById } from "../../../services/InteractiveLessonsService";

const initialState = {
    id:0,
    name: "",
    preview_image_link:MockBackgroundImage,
    creation_time: "",
    description:"",
    category:"",
}

export const loadDetailInteractiveLessonAsync = createAsyncThunk(
    "loadDetailInteractiveLessonAsync",
    async (lessonId) => {
        const response = await getInteractiveLessonById({lesson_id: lessonId})
        return response.data;
    }
)

const detailINteractiveLessonSlice = createSlice({
    name: "detailINteractiveLesson",
    initialState,
    reducers: {
        clearState: () => ({...initialState}),
        changeNameInteractiveLesson: (state, action) => {
            state.name = action.payload;
        },
        changeInteractiveLessonId: (state, action) => {
            state.id = action.payload;
        },
        changeDescriptionInteractiveLesson: (state, action) => {
            state.description = action.payload;
        },
        changePreview: (state, action) => {
            state.preview_image_link = action.payload;
        },
        changeCategoryInteractiveLesson: (state, action) => {
            state.category = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadDetailInteractiveLessonAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const {
                        id,
                        name,
                        preview_image_link,
                        creation_time,
                        description,
                        category,
                    } = action.payload;

                    state.id = id;
                    state.preview_image_link = preview_image_link;
                    state.category = category;
                    state.creation_time = creation_time;
                    state.description = description;
                    state.name = name;
                }
            })  
    }
})

export const {
    clearState,
    changeNameInteractiveLesson,
    changeInteractiveLessonId,
    changeDescriptionInteractiveLesson,
    changePreview,
    changeCategoryInteractiveLesson
} = detailINteractiveLessonSlice.actions;

export default detailINteractiveLessonSlice.reducer;