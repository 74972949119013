import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PageHeading from "../../Components/page-heading/Page_Heading";
import StudentSignUp from "../../Components/SignUp/StudentSignUp/StudentSignUp";
import TeacherSignUp from "../../Components/SignUp/TeacherSignUp/TeacherSignUp";
import ParentSignUp from "../../Components/SignUp/ParentSignUp/ParentSignUp";
import SchoolStudentSignUp from "../../Components/SignUp/SchoolStudentSignUp/SchoolStudentSignUp";
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { clearState } from "../../store/reducer/registerDataReducer";
import { Link } from "react-router-dom";
import ParentIcon from "../../assets/icons/ic-parent.svg";
import StudentIcon from "../../assets/icons/ic-student.svg";
import SchoolStudentIcon from "../../assets/icons/ic-backpack.svg";
import TeacherIcon from "../../assets/icons/ic-teacher.svg"

const registerItems = [
  {
    id: "teacher",
    icon: TeacherIcon,
    title: "Преподаватель",
  },
  {
    id: "student",
    icon: StudentIcon,
    title: "Студент"
  },
  {
    id: "schoolStudent",
    icon: SchoolStudentIcon,
    title: "Учащийся в школе"
  },
  {
    id: "parent",
    icon: ParentIcon,
    title: "Родитель"
  },
]

function SignUp() {
  const dispatch = useDispatch();
  const [formType, setFormType] = useState();
  
  const handleSelect = (type) => {
    setFormType(type);
  }
  const handleBack = () => {
    setFormType('')
    dispatch(clearState())
  }
  const firstBreadcrumb = { label: "", link: "/signup" };
  const secondBreadcrumb = {
    label: "Зарегистрироваться",
    link: "/signup",
    active: true,
  };

  return (
    <div className="page-wrapper">
      <PageHeading
        title=""
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} xs={12}>
                <div className="border border-light rounded-4 p-5">
                {formType && <Link to={''} onClick={handleBack} className="btn btn-arrow-no-hover" style={{transform: "rotate(180deg)",}}></Link>}
                  <h2 className="mb-5 mt-3 text-center">
                    {!formType && "Регистрация"}
                    {formType === "student" && "Регистрация студента"}
                    {formType === "teacher" && "Регистрация преподавателя"}
                    {formType === "parent" && "Регистрация родителя"}
                    {formType === "schoolStudent" && "Регистрация ученика"}
                  </h2>
                  {!formType && <Col>
                    <h3 className="mb-5 text-center">Кто вы?</h3>
                    <div className="row gap-3 justify-content-center ">
                      { registerItems.map((item) => (
                        <div className="d-flex flex-column align-items-center border rounded col-5 shadow-sm p-5  register-item-hover" onClick={() => handleSelect(item.id)}>
                        <img src={item.icon} width={64} height={64} alt={`иконка-${item.title}`}></img>
                        <p className="m-0 fs-5">{item.title}</p>
                      </div>
                      ))}
                      
                      {/* <Button onClick={() => handleSelect("student")} className="w-100 btn-primary ">Студент</Button>
                      <Button onClick={() => handleSelect("teacher")} className="w-100 btn-primary ">Преподаватель</Button>
                      <Button onClick={() => handleSelect("parent")} className="w-100 btn-primary ">Родитель</Button>
                      <Button onClick={() => handleSelect("schoolStudent")} className="w-100 btn-primary ">Учащийся в школе</Button> */}
                    </div>
                  </Col>}
                  {formType === "student" && <StudentSignUp />}
                  {formType === "teacher" && <TeacherSignUp />}
                  {formType === "parent" && <ParentSignUp />}
                  {formType === "schoolStudent" && <SchoolStudentSignUp />}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default SignUp;
