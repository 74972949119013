import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import {Button, FormGroup, Input, Label, Container} from "reactstrap";
import {useMount} from "../../../hooks/hooks";
import {
    changeName,
    changePosition,
    changeImageLink,
    changeContent
} from "../../../store/reducer/manage-teachers-in-history/detail-teachers-in-history"
import {uploadFiles} from "../../../services/uploadFiles";
import {getStaticUrlFile} from "../../../common/helpers";
import {TextEditor} from "../../../Components/EditorComponent/TextEditor/TextEditor";
import {updateTeacherInHistory} from "../../../services/TeachersInHistory";
import {loadDetailTeacherInHistoryAsync} from "../../../store/reducer/manage-teachers-in-history/detail-teachers-in-history"
import TeacherCard from "../../../Components/Teachers/TeacherCard";
import { toast } from "react-toastify";


const Name = () => {
    const detailTeacherInHistory = useSelector((state) => state.detailTeacherInHistory);
    const dispatch = useDispatch();
    
    const onChange = (event) => {
        const value = event.currentTarget.value || "";
        dispatch(changeName(value))
    }

    return (
        <FormGroup floating>
            <Input 
            placeholder={"ФИО"} 
            value={detailTeacherInHistory.name} 
            onChange={onChange}
            id="name"
            />
            <Label for="name">
            ФИО
            </Label>
        </FormGroup>
    )
}

const Position = () => {
    const detailTeacherInHistory = useSelector((state) => state.detailTeacherInHistory);
    const dispatch = useDispatch();
    const onChange = (event) => {
        const value = event.currentTarget.value || "";
        dispatch(changePosition(value));
    }

    return (
        <FormGroup floating>
        <Input 
            placeholder={"Должность"} 
            value={detailTeacherInHistory.position} 
            onChange={onChange}
            id="position"
        />
        <Label for="position">
            Должность
        </Label>
    </FormGroup>
    )
}


const Preview = () => {
    const detailTeacherInHistory = useSelector((state) => state.detailTeacherInHistory);
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    let preview
    const onClickPreviewHandler = () => inputRef?.current?.click();
    const onChangePreview = async (event) => {
        const files = event?.currentTarget?.files;
        
        if (files.length > 0) {
            
            setPreviewFile(files[0]);
            setFiles([files[0]]);
            
            const responseUpload = await uploadFiles({files: [files[0]]});
            preview = responseUpload?.data?.names[0] || "";
            dispatch(changeImageLink(getStaticUrlFile(preview)));
        }
    }
    const payload = {
        id:detailTeacherInHistory.id,
        name: detailTeacherInHistory.name,
        position: detailTeacherInHistory.position,
        image_link: detailTeacherInHistory.image_link,
        content: detailTeacherInHistory.content,
    }
    return (
        <div className='d-flex flex-column gap-1 w-100 mb-5'>
            <div className='d-flex justify-content-center'>
                <TeacherCard  onEditorial={false} item={payload}/>
            </div>
            <Button onClick={onClickPreviewHandler} className='btn btn-primary '>Изменить изображение</Button>

            <input type={"file"}
                   accept={"image/*"}
                   ref={inputRef}
                   onChange={onChangePreview}
                   className={"d-none"}/>
        </div>
    )
}

const ArticleText = () => {
    const [mounted] = useMount();
    const [message, setMessage] = useState(undefined);

    const ref = useRef({});
    const detailTeacherInHistory = useSelector((state) => state.detailTeacherInHistory);


    const {content} = detailTeacherInHistory

    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setMessage(undefined);
        }, 5 * 1000);

        return () => clearTimeout(timeoutId);
    }, []);

    if (!mounted || !content) return null;

    const values = Boolean(content) ? content : undefined;

    return (
        <div className="d-flex w-100 flex-column mt-4 " styles={{backGround:"#000"}}>
            <div className="border rounded-4 mb-4">
                <TextEditor  setRef={setRef} initialValues={values}/>
            </div>

            <div className={"d-flex w-100 gap-3 mb-4"}>

                {message && <p className="fw-bold" styles={{color:message.color}} >{message.text}</p>}

                <div className="d-flex w-100 gap-2">
                    <SaveButton editorRef={ref} setMessage={setMessage}/>
                    <CancelButton/>
                </div>
            </div>
        </div>
    )
}

const SaveButton = ({editorRef, setMessage}) => {

    const detailTeacherInHistory = useSelector((state) => state.detailTeacherInHistory);

    const saveTeacherInHistoryInfo = async () => {
        const content = await editorRef?.current?.save();
        
        const payload = {
            id: detailTeacherInHistory.id,
            name: detailTeacherInHistory.name,
            position: detailTeacherInHistory.position,
            image_link: detailTeacherInHistory.image_link,
            content:content
        }

        await updateTeacherInHistory(payload);
    }


    const onSaveHandler = async () => {
        
        try {
            await saveTeacherInHistoryInfo();
            toast.success('Изменения сохранени!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }

    return <Button className='btn btn-primary' onClick={onSaveHandler}>Сохранить</Button>
}

const CancelButton = () => {

    const navigate = useNavigate()

    const onCancelHandler = () => navigate(0);

    return <Button onClick={onCancelHandler}>Отменить</Button>;
}


const EditTeacherInHistory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const onBackClick = () => {
        navigate(-1)
    }
    useEffect(() => {
        dispatch(loadDetailTeacherInHistoryAsync(params.teachersInHistoryId))
    }, []);
    
    return (
        <div className='w-100  mt-5'>
            <Container className='d-flex flex-column gap-3'>
                <div className="w-100 d-flex justify-content-between mb-4">
                    <h3>Изменить педагогический успех</h3>
                    <Button className="btn" onClick={() => navigate('/editorial/manage-teachers-in-history')}>Назад</Button>
                </div>
                <Preview/>
                <Name/>
                <Position/>
                <ArticleText/>
            </Container>
        </div>

    );
};

export default EditTeacherInHistory;