import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeading from "../../Components/page-heading/Page_Heading";
import {useParams} from 'react-router-dom'
import {
    Container,
} from "reactstrap";
import {useIsMobile, useMount} from '../../hooks/hooks';
import { TextEditor } from "../../Components/EditorComponent/TextEditor/TextEditor";
import { loadDetailOnlineLectureAsync } from "../../store/reducer/mange-online-lectures/detail-online-lecture";
  
const TextArticle = ({content}) => {

    const [mounted] = useMount();

    return (
        <div className="d-flex w-100 flex-column pb-3" width={"full"} direction={"column"} >
            {mounted && <TextEditor readOnly initialValues={content}/>}
        </div>
    )
}  
  
const Lecture = () => {
    const detailOnlineLecture = useSelector((state) => state.detailOnlineLecture)
    const params = useParams();
    const [mounted] = useMount();
    const dispatch = useDispatch();
    const { isMobile } = useIsMobile();
    useEffect(() => {
        dispatch(loadDetailOnlineLectureAsync(params.lectureId))
    }, [])
    
    const {content} = detailOnlineLecture
    if (!mounted || !content) return null;

    const values = Boolean(content) ? content : undefined;
    
    const firstBreadcrumb = { label: "Онлайн лекции", link: "/online-lectures" };
    const secondBreadcrumb = {
      label: detailOnlineLecture.title,
      link: "/product-single",
      active: true,
    };
    return (
        <div className="page-wrapper">
            <PageHeading
            title=""
            firstBreadcrumb={firstBreadcrumb}
            secondBreadcrumb={secondBreadcrumb}
            />
            <div className="page-content">
                <section>
                    <Container className={`${isMobile ? "mb-10" : ""}`}>
                        <h4 className="mb-4">{detailOnlineLecture.title}</h4>
                        <div className="ratio ratio-16x9">
                            
                        <TextArticle content={values}/>
                        </div>
                            
                    </Container>
                </section>
            </div>
        </div>
    )
}

export default Lecture;