import React from "react";
import Page_Heading from "../../Components/page-heading/Page_Heading";
import Accordion from "../../Components/faq/Accordian";
import { Col, Container, Row, Card } from "reactstrap";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";

function FaqPage() {
  const firstBreadcrumb = { label: "", link: "/faq" };
  const secondBreadcrumb = {
    label: "F.A.Q",
    link: "/faq",
    active: true,
  };
  const accordionItems = [
    {
      id: 1,
      title: 'Что такое "Учитель будущего" и для кого он предназначен?',
      content:
        '"Учитель будущего" - это цифровая платформа, созданная для современных педагогов, учеников и всех участников образовательного процесса. Она обеспечивает доступ к образовательным ресурсам, инструментам и сообществу для продвижения концепции непрерывного образования. Это место, где каждый может найти поддержку, знания и возможности для постоянного развития.',
    },
    {
      id: 2,
      title: 'Какие возможности предоставляет платформа для участников?',
      content:
        `"Учитель будущего" предлагает широкий спектр возможностей:

        Доступ к образовательным курсам и тренингам для педагогов по различным предметам и методикам обучения.
        Создание персонализированных образовательных программ для учеников с учетом их потребностей и интересов.
        Возможность общения и обмена опытом с коллегами из разных уголков мира.
        Проведение вебинаров, онлайн-конференций и мастер-классов по актуальным темам в образовании.
        Инструменты для оценки и мониторинга прогресса обучения как учеников, так и педагогов.`,
    },
    {
      id: 3,
      title: 'Как я могу присоединиться к сообществу "Учитель будущего"?',
      content:
        `Присоединиться к нашему сообществу просто:

        Зарегистрируйтесь на платформе, используя свои данные.
        Пройдите быструю аутентификацию и заполните профиль с указанием ваших образовательных интересов и специализаций.
        Получите доступ к ресурсам и начните взаимодействие с другими участниками, обмениваясь опытом и знаниями.`,
    },
    {
      id: 4,
      title: 'Как "Учитель будущего" помогает педагогам адаптироваться к изменяющимся образовательным требованиям?',
      content:
        `"Учитель будущего" стремится обеспечить педагогов всем необходимым для успешной адаптации к современным образовательным требованиям:

        Мы предоставляем доступ к инновационным методикам обучения и передовым педагогическим практикам.
        Наши курсы и тренинги охватывают широкий спектр тем, включая цифровые технологии, межкультурное образование, и развитие критического мышления.
        "Учитель будущего" поддерживает обмен опытом и коллаборацию между педагогами, что позволяет учиться на примере лучших практик.
        Мы регулярно обновляем наш контент и ресурсы, чтобы отражать последние тенденции и требования в образовании, помогая педагогам быть на шаг впереди.`,
    },
    {
      id: 5,
      title: "Как мне получить помощь, если у меня возникли вопросы или проблемы с использованием платформы?",
      content:
        "Если у вас возникли вопросы или проблемы, связанные с использованием платформы, вы всегда можете обратиться в нашу службу поддержки. Мы предоставляем различные каналы обратной связи, включая электронную почту, и телефонную поддержку, чтобы помочь вам решить любые трудности. Мы готовы оказать вам помощь и поддержку в любое время.",
    },
  ];
  return (
    <div>
      <Page_Heading
        title="Часто задаваемые вопросы"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section className="pb-10">
          <Container>
            <Row>
              <Col>
                <div className="bg-light-2 p-lg-8 p-4 rounded-4">
                  <Accordion accordionItems={accordionItems} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <RegisterBanner />
      </div>
    </div>
  );
}

export default FaqPage;
