import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {Input, Button, Container} from 'reactstrap'
import {FormikProvider} from "formik";
import {getStaticUrlFile} from "../../../common/helpers";
import {uploadFiles} from "../../../services/uploadFiles";
import DropZone from '../../../Components/DropZone/DropZone';
import { toast } from "react-toastify";
import { 
    changeCategoryInteractiveLesson, 
    changeDescriptionInteractiveLesson, 
    changeNameInteractiveLesson 
} from '../../../store/reducer/manage-interactive-lessons/detail-interactive-lesson';
import { createInteractiveLesson } from '../../../services/InteractiveLessonsService';

const MAX_TITLE_LENGTH = 128;
const MAX_CATEGORY_LENGTH = 50;

const Title = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_TITLE_LENGTH) return;

        dispatch(changeNameInteractiveLesson(value));
    }

    return (
        <Input placeholder={"Заголовок"} type='text' onChange={onChange}/>
    )
}

const Description = () => {

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changeDescriptionInteractiveLesson(value));
    }

    return <Input placeholder={"Описание"} type='text' onChange={onChange}/>
}

const Category = () => {
    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        if (value.length > MAX_CATEGORY_LENGTH) return;

        dispatch(changeCategoryInteractiveLesson(value));
    }
    
    return <Input placeholder={"Категория"} type='text' onChange={onChange}/>
}

const AddInteractiveLesson = () => {
    
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const detailInteractiveLesson = useSelector((state) => state.detailInteractiveLesson);
    const navigate = useNavigate();
    
    const onChangeFiles = (files) => {
        if (files.length > 0) {
            setPreviewFile(files[0]);
            setFiles([files[0]]);
        }
    }
   
    const onAddIntreractiveLesson = async() => {
        const responseUpload = await uploadFiles({files: [previewFile]});
        const preview = responseUpload?.data?.names[0] || "";
        
        const payload = {
            name: detailInteractiveLesson.name,
            description: detailInteractiveLesson.description,
            category: detailInteractiveLesson.category,
            preview_image_link: getStaticUrlFile(preview),
        }
        if(payload.title === "" || payload.description === "" || payload.category === "" ){
            toast.warn('Проверьте, все ли поля заполнены', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return; 
        }
        
        const response = await createInteractiveLesson(payload);
        if( response.success ){
            toast.success('Интерактивный урок успешно добавлен', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            
            
        }
        else {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });  
        }
    }
   

    return (
        
        <Container>
            <div className="w-100 d-flex justify-content-between mb-4">
                <h3>Добавить интерактивный урок</h3>
                <Button className="btn" onClick={() => navigate('/editorial/manage-interactive-lessons')}>Назад</Button>
            </div>
            <FormikProvider>
                <form>
                    <div className='d-flex w-100 flex-column gap-3 p-4'>

                        <div className='d-flex w-100 flex-column gap-3 '>
                            <Title/>
                            <Description/>
                            <Category/>
                            <div className='d-flex flex-column w-100'>
                                <p className='fw-bold'>Изображение для заголовка</p>
                                <DropZone files={files} onChangeFiles={onChangeFiles}/>
                            </div>
                        </div>

                        <div className='d-flex w-100 gap-1'>
                            <Button className='btn btn-primary'  onClick={onAddIntreractiveLesson}>Создать</Button>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </Container>
    );
};

export default AddInteractiveLesson;