import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation} from "react-router-dom"
import PageHeading from "../../Components/page-heading/Page_Heading";
import { Container, Row } from "reactstrap";
import OnlineLecturesItem from "../../Components/OnlineLectures/OnlineLecturesItem"
import { loadOnlineLecturesAsync } from "../../store/reducer/onlineLecturesReducer";

function OnlineLectures() {
    const onlineLecturesList = useSelector((state) => state.onlineLectures.onlineLectures);
    const dispatch = useDispatch();
    const location = useLocation();
    
    useEffect(() => {
      dispatch(loadOnlineLecturesAsync())
    }, [])
    
    const firstBreadcrumb = { label: "", link: "/team" };
    const secondBreadcrumb = {
        label: "Онлайн лекторий",
        link: "/",
        active: true,
    };

    return (
      <>
      {location.pathname === "/online-lectures" ? 
                                                  <>
                                                      <PageHeading
                                                          title="Онлайн лекторий"
                                                          firstBreadcrumb={firstBreadcrumb}
                                                          secondBreadcrumb={secondBreadcrumb}
                                                      />
                                                      <div className="page-content">
                                                      <section>
                                                        <Container>
                                                          <Row>
                                                            {onlineLecturesList.map((lecture, index) => (
                                                              <OnlineLecturesItem key={index} lecture={lecture} />
                                                            ))}
                                                          </Row>
                                                        </Container>
                                                      </section>
                                                      </div>
                                                  </>
                                                  : <Outlet/>
      }
      
      </>
    )
}

export default OnlineLectures;