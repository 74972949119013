import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { 
  Card, 
  CardBody, 
  CardTitle, 
  CardText,
  Col, 
  Button 
} from "reactstrap";
import { setSelectedEducationProgram } from "../../store/reducer/educationProgramsReducer";
import { useDispatch } from "react-redux";
  
  
const EducationItem = ({program, onEditorial}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onItemClick = (editItem) => {
    navigate(`${program.id}`)
  }
  const handleDeleteClick = () => {
    dispatch(setSelectedEducationProgram(program))
  }
  return(
      <Col>
    <Card className="h-100 lectures-card mb-5">
      {onEditorial && 
        <div className="mb-2 d-flex justify-content-end gap-2">
          <Button className="btn btn-link text-primary-light p-0"  onClick={() => onItemClick(program)}>Редактировать</Button>
          <Button className="btn btn-link text-danger p-0" data-bs-toggle="modal" data-bs-target="#confirmDelete" onClick={handleDeleteClick}>Удалить</Button>
        </div>
      }
      <CardBody className="d-flex flex-column justify-content-center">
        <Link to={!onEditorial ? program.program_url : ""} target="_blank">
          <div className="d-flex mb-direction-column">
          <img 
              className="rounded object-fit-cover"
              width="146"
              height="146"
              src={program.preview_image_link} 
              alt="" 
              />
              <div className="d-flex flex-column justify-content-center ps-3">
              <CardTitle tag="h5">
                  {program.title}  
              </CardTitle>
              <CardText className="mb-4 text-secondary">
                  {program.description}
              </CardText>
              </div>
          </div>
        </Link>
      </CardBody>
    </Card>
  </Col>
  )
}

export default EducationItem;