import React, {useEffect} from "react";
import {Container} from "reactstrap"
import SubsectionItem from "../../Components/PedagogicalSearch/SubsectionItem";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams, useLocation, Outlet} from 'react-router-dom'
import {loadSubsectionsAsync} from "../../store/reducer/pedagogicalSearchReducer"
import PageHeading from "../../Components/page-heading/Page_Heading";

const Sections = () => {
  const firstBreadcrumb = { label: "Рубрики", link: "/sections" };
  const secondBreadcrumb = {
    label: "Подрубрики",
    link: "/",
    active: true,
  };
  const subsections = useSelector((state) => state.pedagogicalSearch.subsections);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  useEffect(() => {
    dispatch(loadSubsectionsAsync(params.sectionId))
  }, [params])
  
  return (
    <>
    {location.pathname === `/sections/${params.sectionId}` ? <>
                          <PageHeading
                              title="Педагогический поиск: творчество без границ"
                              firstBreadcrumb={firstBreadcrumb}
                              secondBreadcrumb={secondBreadcrumb}
                            />
                          <div>
                            <Container className="d-flex gap-3 pb-10 flex-wrap justify-content-center">
                              {subsections.map((item, index) => (
                                <SubsectionItem item={item} index={index}/>
                                ))}
                            </Container>
                          </div>
                          </> 
                       : <Outlet/>
    }
    
    </>
  );
}

export default Sections;