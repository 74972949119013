import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone'
import UploadIcon from "../../assets/icons/ic-upload.svg";
import FileItem from "./FileItem/FileItem";
import Loader from "../Loader";
import classNames from "classnames";

const MAX_SIZE_FILE = 100000000;

const Loading = () => {
    return (
        <div className='upload_zone_loader'>
            <Loader className='upload_zone_loader__spinner'/>
        </div>
    )
}

const DropZone = ({isLoading, onChangeFiles, files = []}) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxSize: MAX_SIZE_FILE,
    });

    React.useEffect(() => {
        onChangeFiles(acceptedFiles);
    }, [acceptedFiles]);
    
    const filesComponent = useMemo(() => files.map((file, idx) => (
        <FileItem fileName={file.name} key={idx}/>
    )), [files]);

    const classes = classNames('upload_zone', isLoading && "dropzone_loading");

    return (
        <div className="dropZone border rounded">
            <div {...getRootProps()}>
                {!isLoading && <input {...getInputProps()} />}
                <div className={classes}>
                    {isLoading && <Loading/>}

                    <img src={UploadIcon} alt={"Зона загрузки"}/>
                    <p>Выберите или перетащите файлы сюда</p>
                    <p>Максимальный размер каждого файла составляет 100 Мб.</p>
                </div>
            </div>
            <aside>
                <ul className="files">{filesComponent}</ul>
            </aside>
        </div>
    );
};

export default DropZone;
