import React, { useEffect } from "react"
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import { Container } from "reactstrap";
import SideBar from "../../Components/Editorial/EditorialSidebar/index";
import InfoArticleEdit from "../../Components/Editorial/InfoArticleEdit";
import CreateArticle from "../../Components/Editorial/AddItem";
import AccessComponent from "./PedagogicalSearch/AccessComponent";
import { useAuth } from "../../context/auth";

const AdminPage = () => {
    const {userType} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if(userType !== "admin") {
            navigate("/")
        }
    },[userType])
    
    return (
        <>
            <div className="d-flex mt-5">
                <SideBar>
                {location.pathname === "/editorial" ? <Container className="d-flex gap-3 flex-column px-10" style={{marginTop: "20rem", marginLeft: "auto"}}>
                                                    <div className="w-100 d-flex justify-content-between mb-4">
                                                        <h3>Добро пожаловать!</h3>
                                                    </div>
                                                    
                                                </Container>
                                              : <Outlet/>
                }
                </SideBar>
            </div>
        </>
        
    )
}

export default AdminPage;