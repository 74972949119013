import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {Button, Col, Form, FormGroup, Input, Label, Row, FormText} from "reactstrap";
import {Link} from "react-router-dom";
import { AddressSuggestions, PartySuggestions  } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import { 
    setName,
    setSurname,
    setEmail,
    setRegion,
    setCity,
    setInstitution,
    setPosition,
    setStudyYear,
    setPhoneNumber,
    setPassword,
    setConfirmPassword 
} from "../../store/reducer/registerDataReducer";

import CustomInput from "../CustomInput/CustomInput";

const DefaultSignUpLayout = ({ isTeacher, handleSubmit}) => {
    const dispatch = useDispatch();
    const registerData = useSelector((state) => state.registerData);
    const [showPassword, setShowPassword] = useState(false);
    
    const handleRegionChange = (e)=> {
        dispatch(setRegion(e))
    }
    
    const handleCityChange = (e) => {
        dispatch(setCity(e))
    }
    

    return (
        <>
            <Form id="contact-form" onSubmit={handleSubmit}>
                <div className="messages"></div>
                <Row>
                    <Col md={6}>
                        <CustomInput
                            type="text"
                            name="name"
                            placeholder="Имя"
                            id="form_name"
                            className="form-control"
                            value={registerData.name}
                            onChange={(e) => dispatch(setName(e.target.value))}
                            required
                        />
                        {' '}
                    </Col>
                    <Col md={6}>
                            <CustomInput
                                type="text"
                                name="form_lastName"
                                placeholder="Фамилия"
                                id="form_lastName"
                                className="form-control"
                                value={registerData.surname}
                                onChange={(e) => dispatch(setSurname(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                            <CustomInput
                                autoComplete="off"
                                type="email"
                                name="email"
                                placeholder="Эл. Почта"
                                id="form_email"
                                className="form-control"
                                value={registerData.email}
                                onChange={(e) => dispatch(setEmail(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                    <Col md={6}>
                            <CustomInput
                                autoComplete="off"
                                type="tel"
                                name="phone"
                                placeholder="Номер телефона"
                                id="form_phone"
                                className="form-control"
                                value={registerData.phone}
                                onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                            <CustomInput
                                autoComplete="off"
                                type="password"
                                name="password"
                                placeholder="Пароль"
                                id="form_password"
                                className="foprm-control"
                                value={registerData.password}
                                onChange={(e) => dispatch(setPassword(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                    <Col md={6}>
                            <CustomInput
                                type="password"
                                name="confirmPassword"
                                placeholder="Подтвердить пароль"
                                id="form_password1"
                                className="form-control"
                                value={registerData.confirmPassword}
                                onChange={(e) => dispatch(setConfirmPassword(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup >
                            <AddressSuggestions 
                                token={process.env.REACT_APP_DADATA_API} 
                                value={registerData.region} 
                                onChange={(e)=>handleRegionChange(e.value)} 
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Регион"
                                }}
                            />
                        </FormGroup>
                        {' '}
                    </Col>
                    <Col md={6}>
                        <FormGroup >
                            <AddressSuggestions 
                                token={process.env.REACT_APP_DADATA_API} 
                                value={registerData.city} 
                                onChange={(e)=>handleCityChange(e.value)}
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Город"
                                }}
                            />
                        </FormGroup>
                        {' '}
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                            <CustomInput
                             autoComplete="off"
                                type="text"
                                name="institution"
                                placeholder="Учебное заведение"
                                id="institution"
                                className="foprm-control"
                                value={registerData.institution}
                                onChange={(e) => dispatch(setInstitution(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                    {
                        isTeacher ? 
                        <Col md={6}>
                            <CustomInput
                             autoComplete="off"
                                type="text"
                                name="position"
                                placeholder="Должность"
                                id="position"
                                className="foprm-control"
                                value={registerData.position}
                                onChange={(e) => dispatch(setPosition(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                        :   
                    <Col md={6}>
                            <CustomInput
                             autoComplete="off"
                                type="number"
                                name="studyYear"
                                placeholder="Год обучения"
                                id="studyYear"
                                className="foprm-control"
                                value={registerData.studyYear}
                                onChange={(e) => dispatch(setStudyYear(e.target.value))}
                                required
                            />
                        {' '}
                    </Col>
                    }
                    
                </Row>

                {/* <Row className="text-center mt-4">
                    <Col md={12}>
                        <FormGroup className="remember-checkbox clearfix mb-4">
                            <div className="form-check">
                                <Input
                                    type="checkbox"
                                    className="form-check-input float-none"
                                    id="customCheck1"
                                />
                                <Label
                                    className="form-check-label px-1"
                                    for="customCheck1"
                                >
                                    Я согласен с условиями использования и политикой конфиденциальности
                                </Label>
                            </div>
                        </FormGroup>
                    </Col>
                </Row> */}
                <Row className="text-center">
                    <Col>
                        <Button color="primary">Зарегистрироваться</Button>
                        <span className="mt-4 d-block">
                          Уже есть аккаунт?
                          <Link to="/login">
                            <i>Войти!</i>
                          </Link>
                        </span>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DefaultSignUpLayout;