import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService";
import { useAuth } from "../../context/auth";

const IsAuthenticated = ({children}) => {
    const location = useLocation();
    const { isAuthenticated } = useAuth();
    if(isAuthenticated) {
        return <Navigate to="/account" state={{from: location}} />
    }
    return children;
}

export default IsAuthenticated;