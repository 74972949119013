import {createApi} from "@reduxjs/toolkit/dist/query/react";
import Api from "../Api";
import { baseQueryAuth } from "../../services/ApiService";

export const settingsApi = createApi({
    reducerPath: "settingsApi",
    baseQuery: baseQueryAuth,
    endpoints: (builder) => ({
        getSettingsData: builder.query({
            query: () => ({
                url: Api.endpoints.USER_INFO,
                method: "GET",
            }),
        }),
        updatePersonalInformation: builder.mutation({
            query: (personalInformation) => ({
                url: Api.endpoints.USER_CHANGE_PERSONAL_INFO,
                method: "POST",
                body: personalInformation,
            })
        }),
        initEmailChange: builder.mutation({
            query: (body) => ({
                url: Api.endpoints.EMAIL_CHANGE_INIT,
                method: "POST",
                body: body,
            }),
        }),
        continueEmailChange: builder.mutation({
            query: (body) => ({
                url: Api.endpoints.EMAIL_CHANGE_CONTINUE,
                method: "POST",
                body: body,
            }),
        }),
        completeEmailChange: builder.mutation ({
            query: (body) => ({
                url: Api.endpoints.EMAIL_CHANGE_COMPLETE,
                method: "POST",
                body: body,
            }),
        }),
        initChangePassword: builder.mutation({
            query: () => ({
                url: Api.endpoints.PASSWORD_CHANGE_INIT,
                method: "POST",
            }),
        }),
        completeChangePassword: builder.mutation({
            query: (body) => ({
                url: Api.endpoints.PASSWORD_CHANGE_COMPLETE,
                method: "POST",
                body: body,
            }),
        }),
    }),
});

export const {

    useUpdatePersonalInformationMutation,
    useGetSettingsDataQuery,

    useInitEmailChangeMutation,
    useContinueEmailChangeMutation,
    useCompleteEmailChangeMutation,

    useInitChangePasswordMutation,
    useCompleteChangePasswordMutation,


} = settingsApi;