import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fullName:"",
    email:"",
    phone_number:"",
    message:"",
    status: ""
}

const contactUsFormSlice = createSlice({
  name: "contactUsForm",
  initialState,
  reducers: {
    clearState() {
      return initialState;  
    },
    setFullName(state, action) {
      state.fullName = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPhoneNumber(state, action) {
      state.phone_number = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
  },
});

export const { 
  clearState,
  setFullName,
  setEmail,
  setPhoneNumber,
  setMessage,
} = contactUsFormSlice.actions;

export default contactUsFormSlice.reducer;
