import { createSlice } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";

const initialState = {
    name: '',
    surname: '',
    email: '',
    region: '',
    city: '',
    institution: '',
    position: '',
    studyYear: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
}

const registerDataSlice = createSlice({
    name: "registerData",
    initialState,
    reducers: {
        clearState() {
            return initialState;  
        },
        setUserData: (action) => {
            return {...action.payload};
        },
        setName(state, action) {
            state.name = action.payload;
        },
        setSurname(state, action) {
            state.surname = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setRegion(state, action) {
            state.region = action.payload;
        },
        setCity(state, action) {
            state.city = action.payload;
        },
        setInstitution(state, action) {
            state.institution = action.payload;
        },
        setPosition(state, action) {
            state.position = action.payload;
        },
        setStudyYear(state, action) {
            state.studyYear = action.payload;
        },
        setPhoneNumber(state, action) {
            state.phoneNumber = action.payload;
        },
        setPassword(state, action) {
            state.password = action.payload;
        },
        setConfirmPassword(state, action) {
            state.confirmPassword = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUcallerId: (state, action) => {
            state.ucallerId = action.payload;
        },
    },
});

export const {
    clearState,
    setUserData,
    setName,
    setSurname,
    setEmail,
    setRegion,
    setCity,
    setInstitution,
    setPosition,
    setStudyYear,
    setPhoneNumber,
    setPassword,
    setConfirmPassword,
    setToken,
    setUcallerId
} = registerDataSlice.actions;

export default registerDataSlice.reducer;
