import {errorParser} from "../api/errorParser/errorParser";
import {AxiosResponse} from "axios";
import {completePhoneVerification, initPhoneVerification, setNewPassword} from "../api/helpers/restorePassword";
import {decodeToken} from "../api/auth/decodeToken";

export class ApiEndpoints {
    //Analytics
    HOST = process.env.REACT_APP_PUBLIC_API_BASE;
    // HOST = "http://localhost";
    
    // UCHITEL_API = this.HOST + ":8000"; 
    UCHITEL_API = this.HOST + "/uchitel"; 
    SCHOOLSTUDENT_SIGNUP = this.UCHITEL_API + "/complete-registration/school_student";
    PARENT_SIGNUP = this.UCHITEL_API + "/complete-registration/parent";
    STUDENT_SIGNUP = this.UCHITEL_API + "/complete-registration/student";
    TEACHER_SIGNUP = this.UCHITEL_API + "/complete-registration/teacher";
    LOGIN_USER = this.UCHITEL_API + "/login";
    DECODE_TOKEN = this.UCHITEL_API + "/decode-token";
    USER_INFO = this.UCHITEL_API + "/user/info";
    INIT_PHONE_VERIFICATION = this.UCHITEL_API + "/init-phone-verification";
    COMPLETE_PHONE_VERIFICATION = this.UCHITEL_API + "/complete-phone-verification";
    RESET_PASSWORD = this.UCHITEL_API + "/reset-password";
    USER_CHANGE_PERSONAL_INFO = this.UCHITEL_API + "/user/change/personal-info";
    PHONE_CHANGE_INIT = this.UCHITEL_API + "/phone-change/init";
    PHONE_CHANGE_CONTINUE = this.UCHITEL_API + "/phone-change/continue";
    PHONE_CHANGE_COMPLETE = this.UCHITEL_API + "/phone-change/complete";
    EMAIL_CHANGE_INIT = this.UCHITEL_API + "/email-change/init";
    EMAIL_CHANGE_CONTINUE = this.UCHITEL_API + "/email-change/continue";
    EMAIL_CHANGE_COMPLETE = this.UCHITEL_API + "/email-change/complete";
    PASSWORD_CHANGE_INIT = this.UCHITEL_API + "/password-change/init";
    PASSWORD_CHANGE_COMPLETE = this.UCHITEL_API + "/password-change/complete";
    RECOVERY_PASSWORD_BY_EMAIL_COMPLETE = this.UCHITEL_API + "/password-recover-by-email/complete";
    REPEAT_PHONE_CODE = this.UCHITEL_API + "/repeat-phone-call";
    CHECK_RECOVERY_TOKEN_BY_EMAIL = this.UCHITEL_API + "/password-recover-by-email/check";
    UPLOAD_FILES = this.UCHITEL_API + "/upload-files";
    
    //PEDAGOGICAL_SEARCH
    GET_ARTICLE_DETAIL = this.UCHITEL_API + "/get_article/{article_id}";
    GET_ALL_SECTIONS = this.UCHITEL_API + "/get-sections";
    GET_SUBSECTIONS = this.UCHITEL_API + "/get-sections/{parent_id}";
    GET_ARTICLES = this.UCHITEL_API + "/get_articles/{parent_id}";
    CREATE_ARTICLE = this.UCHITEL_API + "/add-article";
    CREATE_SECTION = this.UCHITEL_API + "/add-section";
    DELETE_SECTION = this.UCHITEL_API + "/delete-section/{section_id}";
    DELETE_ARTICLE = this.UCHITEL_API + "/delete-article/{article_id}";
    CHANGE_SECTION_INFO = this.UCHITEL_API + "/section/change-info";
    CHANGE_ARTICLE_INFO = this.UCHITEL_API + "/article/change-info";
    CHANGE_ARTICLE_CONTENT = this.UCHITEL_API + "/article/change-content";
    GET_SECTION_BY_ID = this.UCHITEL_API + "/get-section/{section_id}";
    
    //SLIDER
    ADD_SLIDER_IMAGE = this.UCHITEL_API + "/add-slider-image";
    GET_SLIDER_IMAGES = this.UCHITEL_API + "/get-slider-images";
    DELETE_SLIDER_IMAGE = this.UCHITEL_API + "/delete-slider-image/{image_id}";
    UPDATE_SLIDER_IMAGES = this.UCHITEL_API + "/update-slider-images";
    
    //TEACHERS_IN_HISTORY
    ADD_TEACHER_IN_HISTORY = this.UCHITEL_API + "/add-teacher-in-history";
    GET_TEACHERS_IN_HISTORY = this.UCHITEL_API + "/get-teachers-in-history";
    DELETE_TEACHER_IN_HISTORY = this.UCHITEL_API + "/delete-teacher-in-history/{teacher_id}";
    UPDATE_TEACHER_IN_HISTORY = this.UCHITEL_API + "/update-teacher-in-history";
    GET_TEACHER_HISTORY_BY_ID = this.UCHITEL_API + "/get-teacher-in-history/{teacher_id}";
    
    //ONLINE_LECTURES
    ADD_ONLINE_LECTURE = this.UCHITEL_API + "/add-online-lecture";
    GET_ONLINE_LECTURES = this.UCHITEL_API + "/get-online-lectures";
    DELETE_ONLINE_LECTURE = this.UCHITEL_API + "/delete-online-lectures/{lecture_id}";
    UPDATE_ONLINE_LECTURE = this.UCHITEL_API + "/update-online-lecture";
    GET_ONLINE_LECTURE_BY_ID = this.UCHITEL_API + "/get-online-lecture/{lecture_id}";
    
    //INTERACTIVE_LESSONS
    ADD_INTERACTIVE_LESSON = this.UCHITEL_API + "/add-interactive-lesson";
    GET_INTERACTIVE_LESSONS = this.UCHITEL_API + "/get-interactive-lessons";
    DELETE_INTERACTIVE_LESSON = this.UCHITEL_API + "/delete-interactive-lesson/{lesson_id}";
    UPDATE_INTERACTIVE_LESSON = this.UCHITEL_API + "/update-interactive-lesson";
    GET_INTERACTIVE_LESSON_BY_ID = this.UCHITEL_API + "/get-interactive-lesson/{lesson_id}";
    ADD_INTERACTIVE_LESSON_REQUEST = this.UCHITEL_API  + "/add-interactive-lesson-request";
    GET_INTERACTIVE_LESSONS_REQUESTS = this.UCHITEL_API  + "/get-interactive-lessons-requests";
    GET_USER_INTERACTIVE_LESSONS_REQUESTS = this.UCHITEL_API  + "/get-user-interactive-lessons-requests/{user_id}";
    UPDATE_INTERACTIVE_LESSON_REQUEST_STATUS = this.UCHITEL_API  + "/update-interactive-lesson-request-status";
    
    //PRODUCTS
    ADD_PRODUCT = this.UCHITEL_API + "/add-product";
    GET_PRODUCTS = this.UCHITEL_API + "/get-all-products";
    DELETE_PRODUCT = this.UCHITEL_API + "/delete-product/{product_id}";
    UPDATE_PRODUCT = this.UCHITEL_API + "/update-product";
    GET_PRODUCT_BY_ID = this.UCHITEL_API + "/get-product/{product_id}";
    GET_FILTER_DATA = this.UCHITEL_API + "/get-filter-data";
    GET_USER_PRODUCTS = this.UCHITEL_API + "/get-user-ownership/{user_id}";
    CREATE_TRANSACTION = this.UCHITEL_API + "/create-checkout-session";
    GET_TRANSACTION_STATUS_BY_ID = this.UCHITEL_API + "/get-transaction-status/{transaction_id}";
    
    //PSYCHOLOGICAL_CONSULT
    ADD_PSYCHOLOGICAL_CONSULT = this.UCHITEL_API  + "/add-psychological-consult";
    GET_PSYCHOLOGICAL_CONSULTS = this.UCHITEL_API  + "/get-psychological-consults";
    GET_USER_PSYCHOLOGICAL_CONSULTS = this.UCHITEL_API  + "/get-user-psychological-consults/{user_id}";
    UPDATE_PSYCHOLOGICAL_CONSULT_STATUS = this.UCHITEL_API  + "/update-psychological-consult-status";
    
    //EDUCATION_PROGRAM
    ADD_EDUCATION_PROGRAM = this.UCHITEL_API + "/add-education-program";
    GET_EDUCATION_PROGRAMS = this.UCHITEL_API + "/get-education-programs";
    DELETE_EDUCATION_PROGRAM = this.UCHITEL_API + "/delete-education-program/{program_id}";
    UPDATE_EDUCATION_PROGRAM = this.UCHITEL_API + "/education-program/change-info";
    GET_EDUCATION_PROGRAM_BY_ID = this.UCHITEL_API + "/get-education-program/{program_id}";

}

export class Api {
    endpoints = new ApiEndpoints();

    async decodeToken(token) {
        return await decodeToken(token);
    }

    async initPhoneVerification(phone) {
        return await initPhoneVerification(phone);
    }

    async completePhoneVerification(phoneNumber, phoneCode) {
        return await completePhoneVerification(phoneNumber, phoneCode);
    }

    async setNewPassword(phoneNumber, token, password, confirmPassword) {
        return await setNewPassword(phoneNumber, token, password, confirmPassword);
    }

    getErrors(errors) {
        return errorParser(errors);
    }
}

export default new Api();
