import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from 'react-router-dom'
import { setSelectedArticle } from "../../store/reducer/pedagogicalSearchReducer";
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';

const SubsectionItem = ({item, index}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleArticleSelect = (article) => {
        navigate(`${article.id}`)
      }
    return (
        <>
            <Card key={index} className=" w-100 d-flex rounded-4 flex-row p-4 search-card search-card-hover" onClick={() => handleArticleSelect(item)}>
                <CardBody>
                    <CardTitle className="fw-bold fs-5 mb-0">{item.title}</CardTitle>
                    {item.show_description && <CardText>{item.description}</CardText>}
                </CardBody>
            </Card>
        </>
    )
}

export default SubsectionItem;