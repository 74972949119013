import React from "react";
import PageHeading from "../../Components/page-heading/Page_Heading";
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Input,
  Button,
  Label
} from "reactstrap";
import { Link } from "react-router-dom";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";

function ForgetPassword() {
  const firstBreadcrumb = { label: "", link: "/forgot-password" };
  const secondBreadcrumb = {
    label: "Забыли пароль?",
    link: "/forgot-password",
    active: true,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="page-wrapper">
      <PageHeading
        title=""
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section>
          <Container>
            <Row className="d-flex justify-content-center text-center">
              <Col lg={6}>
                <div>
                  <div className="mb-5">
                    <h2>Забыли пароль?</h2>
                    <p>Введите почту, чтобы поменять пароль.</p>
                  </div>

                  <Form
                    className="px-sm-10"
                    id="contact-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="messages"></div>
                    <FormGroup className="mb-3" floating>
                      <Input
                        type="email"
                        name="email"
                        id="form_email"
                        placeholder="Эл. Почта"
                        required
                      />
                      <Label for={"form_email"}>Эл. Почта</Label>
                    </FormGroup>
                    <Button color="primary" block>
                      Сменить пароль
                    </Button>
                  </Form>
                  <div className="mt-4">
                    {" "}
                    <Link className="btn-link" to="/login">
                      Вернуться на страницу входа
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <RegisterBanner />
      </div>
    </div>
  );
}

export default ForgetPassword;
