import React from "react";
import { useDispatch } from "react-redux";

import Page_Heading from "../../Components/page-heading/Page_Heading";
import { Col, Container, Row } from "reactstrap";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import MapSection from "./MapSection";
import { 
  clearState
} from "../../store/reducer/manage-contact-us/contact-us-form";

function Contatct() {
  const dispatch = useDispatch();
  const firstBreadcrumb = { label: "", link: "/register-banner-us" };
  const secondBreadcrumb = {
    label: "Связаться с нами",
    link: "/register-banner-us",
    active: true,
  };
  const contactInfo = [
    {
      region: "Алтайский край",
      address: "Барнаул, пр. Ленина, 61",
      phone: "+7 (3852) 291-222",
      email: "prcom@asu.ru",
    },
  ];
  
  const handleSubmit = () => {
    dispatch(clearState())
    return
  }
  return (
    <div className="page-wrapper">
      <Page_Heading
        title="Связаться с нами"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <section>
        <Container>
          <Row className="align-item-center">
            <Col lg={6} className=" mb-6 mb-lg-0">
              <ContactInfo contactInfo={contactInfo} />
            </Col>
            <Col lg={6} className="col-12 ps-lg-10">
              <ContactForm handleSubmit={handleSubmit}/>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="overflow-hidden pt-0">
        <Container fluid className="px-0">
          <Row>
            <Col>
              <MapSection />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Contatct;
