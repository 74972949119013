import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button} from "reactstrap";
import { loadPsychologicalConsultAsync } from "../../store/reducer/psychologicalConsultReducer";
import { updatePsychologicalConsultStatus } from "../../services/psychologicalConsult";

const PsychologicalConsultItem = ({consultRequest, onEditorial}) => {
    const dispatch = useDispatch();
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, поэтому добавляем 1
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    const onUpdateStatus = (status, id) => {
        const payload = {
            id: id,
            status: status,
        }
        updatePsychologicalConsultStatus(payload)
        dispatch(loadPsychologicalConsultAsync())
    }
    return (
        <div className="p-4 border rounded d-flex flex-column justify-content-between" style={{maxWidth: "450px"}}>
                        <div>
                            <div className="d-flex justify-content-end">
                                {consultRequest.status === "Ожидает" && <div className="text-warning">{consultRequest.status}</div>}
                                {consultRequest.status === "Готово" && <div className="text-success">{consultRequest.status}</div>}
                                {consultRequest.status === "Отклонено" && <div className="text-danger">{consultRequest.status}</div>}
                            </div>
                            {onEditorial && <h5>
                                                {consultRequest.full_name}
                                            </h5>
                            }
                            {onEditorial && <>
                                                <p>
                                                   <b>Эл.почта:</b> {consultRequest.email}
                                                </p>
                                                <p>
                                                    <b>Номер телефона:</b> {consultRequest.phone_number}
                                                </p>
                                            </>
                            }
                            <p className="">
                                <b>Дата обращения:</b> {consultRequest.creation_time && formatDate(consultRequest.creation_time)}
                            </p>
                            <p>
                                <b>Сообщение:</b> {consultRequest.message}
                            </p>
                        </div>
                        {onEditorial && <div className="d-flex flex-column gap-2">
                                            {consultRequest.status !== "Готово" && <Button onClick={() => onUpdateStatus("Готово", consultRequest.id)} size="sm" color="success">Готово</Button>}
                                            {consultRequest.status !== "Ожидает" && <Button onClick={() => onUpdateStatus("Ожидает", consultRequest.id)} size="sm" color="warning">Ожидает</Button>}
                                            {consultRequest.status !== "Отклонено" && <Button onClick={() => onUpdateStatus("Отклонено", consultRequest.id)} size="sm" color="danger">Отклонить</Button>}
                                        </div>
                        }
                        
                    </div>
    )
}

export default PsychologicalConsultItem