import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom'
import {Button, 
    Col, 
    Form, 
    FormGroup, 
    Input, 
    Label, 
    Row, 
    Container, 
    Card, 
    CardImg, 
    CardBody, 
    CardTitle, 
    CardText,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {useMount} from "../../hooks/hooks";
import {
    changeDescriptionArticle,
    changePreview,
    changeTitleArticle,
    changeArticleId,
    loadSectionAsync,
    changeShowDescription,
    changeDestination
} from "../../store/reducer/manage-sections/detail-article";
import {loadDetailArticleAsync} from "../../store/reducer/manage-sections/detail-article";
import {getSectionById} from "../../services/PedagogicalSearchService"
import {uploadFiles} from "../../services/uploadFiles";
import {
    changeArticleContent,
    changeArticleInfo,
    changeSectionInfo
} from "../../services/PedagogicalSearchService";
import {getStaticUrlFile} from "../../common/helpers";
import {TextEditor} from "../EditorComponent/TextEditor/TextEditor";
import { toast } from "react-toastify";
import { destinationItems } from './AddItem';


const MAX_TITLE_LENGTH = 128;

const Title = () => {
    const article = useSelector((state) => state.detailArticle);
    const dispatch = useDispatch();
    
    const onChange = (event) => {
        
        const value = event.currentTarget.value || "";
        
        if (value.length > MAX_TITLE_LENGTH) return;
        
        dispatch(changeTitleArticle(value))
    }

    return (
        <FormGroup floating>
            <Input 
            placeholder={"Заголовок"} 
            value={article.title} 
            onChange={onChange}
            id="title"
            />
            <Label for="title">
                Заголовок
            </Label>
        </FormGroup>
    )
}

const Description = () => {
    const article = useSelector((state) => state.detailArticle);

    const dispatch = useDispatch();

    const onChange = (event) => {
        const value = event.currentTarget.value || "";

        dispatch(changeDescriptionArticle(value));
        
    }
    const handleCheckboxChange = () => {
        dispatch(changeShowDescription(!article.show_description))
        if(article.show_description) dispatch(changeDescriptionArticle(''))
    }
    return (
        <>
            <FormGroup check>
                        <Input 
                            type="checkbox" 
                            onChange={handleCheckboxChange}
                            />
                        <Label check>
                            Добавить описание
                        </Label>
                </FormGroup>
            <FormGroup floating>
            <Input 
                placeholder={"Описание"} 
                value={article.description} 
                onChange={onChange}
                id="description"
                disabled={!article.show_description}
            />
            <Label for="description">
                Описание
            </Label>
            </FormGroup>
        </>
    )
}


const Destination = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const article = useSelector((state) => state.detailArticle);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    
    const onItemClick = (item) => {
        dispatch(changeDestination(item))
    }
    
    return (
        <div className="d-flex flex-column">
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
            <DropdownToggle caret>Выберите площадку</DropdownToggle>
            <DropdownMenu>
                {
                    destinationItems.map((item) => (
                        <DropdownItem onClick={() => onItemClick(item.title)}>{item.title}</DropdownItem>
                    ))
                }
            </DropdownMenu>
        </Dropdown>
        <p className='mt-2'>Площадка: {article.destination}</p>
        </div>
    )
}


const Preview = ({isArticle}) => {
    const article = useSelector((state) => state.detailArticle);
    const [files, setFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState();
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    let preview
    const onClickPreviewHandler = () => inputRef?.current?.click();
    const onChangePreview = async (event) => {
        const files = event?.currentTarget?.files;
        
        if (files.length > 0) {
            
            setPreviewFile(files[0]);
            setFiles([files[0]]);
            
            const responseUpload = await uploadFiles({files: [files[0]]});
            preview = responseUpload?.data?.names[0] || "";
            dispatch(changePreview(getStaticUrlFile(preview)));
        }
        
    }
    
    return (
        <div className='d-flex flex-column gap-1 w-100 mb-5'>
            <Card className="d-flex flex-row p-4 search-card mb-3">
                <div className="d-flex justify-content-between">
                {!isArticle && <CardImg
                                                className="rounded-4 object-fit-cover"
                                                style={{
                                                    height: 156,
                                                    width: 156
                                                    }}
                                                    src={article.preview} 
                                            />
                }
                </div>
                
                <CardBody>
                    <CardTitle className="d-flex justify-content-between align-items-start">
                        <div>
                            {article.title}
                        </div>
                    </CardTitle>
                    {article.show_description && <CardText>{article.description}</CardText>}
                </CardBody>
            </Card>
            {!isArticle && <Button onClick={onClickPreviewHandler} className='btn btn-primary '>Изменить изображение</Button>
            }          

            <input type={"file"}
                   accept={"image/*"}
                   ref={inputRef}
                   onChange={onChangePreview}
                   className={"d-none"}/>
        </div>
    )
}

const ArticleText = () => {
    const [mounted] = useMount();
    const [message, setMessage] = useState(undefined);

    const ref = useRef({});
    const article = useSelector((state) => state.detailArticle);


    const {content} = article

    const setRef = useCallback((instance) => {
        ref.current = instance;
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setMessage(undefined);
        }, 5 * 1000);

        return () => clearTimeout(timeoutId);
    }, []);

    if (!mounted || !content) return null;

    const values = Boolean(content) ? content : undefined;

    return (
        <div className="d-flex w-100 flex-column mt-4 " styles={{backGround:"#000"}}>
            <div className="border rounded-4 mb-4">
                <TextEditor  setRef={setRef} initialValues={values}/>
            </div>

            <div className={"d-flex w-100 gap-3 mb-4"}>

                {message && <p className="fw-bold" styles={{color:message.color}} >{message.text}</p>}

                <div className="d-flex w-100 gap-2">
                    <SaveButton editorRef={ref} setMessage={setMessage}/>
                    <CancelButton/>
                </div>
            </div>
        </div>
    )
}

const SaveButton = ({editorRef, setMessage}) => {

    const article = useSelector((state) => state.detailArticle);


    const saveArticleInfo = async () => {

        const payloadArticleInfo = {
            id: article.id,
            title: article.title,
            description: article.description,
            show_description: article.show_description
        }

        await changeArticleInfo(payloadArticleInfo);
    }

    const saveContentInfo = async () => {

        const content = await editorRef?.current?.save();

        const payload = {
            article_id: article.id,
            new_content: content,
        }

        await changeArticleContent(payload);
    }

    const onSaveHandler = async () => {
        try {
            await saveArticleInfo();
            await saveContentInfo();
            toast.success('Изменения сохранены!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
                  
        } catch (e) {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }
    }

    return <Button className='btn btn-primary' onClick={onSaveHandler}>Сохранить</Button>
}

const SaveSectionButton = () => {
    
    const article = useSelector((state) => state.detailArticle);


    const saveArticleInfo = async () => {

        let previewImage = article.preview;
        const payloadSectionInfo = {
            id: article.id,
            title: article.title,
            description: article.description,
            preview_image_link: getStaticUrlFile(previewImage),
            show_description: article.show_description,
            destination: article.destination
        }

        await changeSectionInfo(payloadSectionInfo);
    }

   

    const onSaveHandler = async () => {
        try {
            await saveArticleInfo();
            toast.success('Изменения сохранены!', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            toast.error('Что-то пошло не так', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return <Button className='btn btn-primary'  onClick={onSaveHandler}>Сохранить</Button>
}

const CancelButton = () => {

    const navigate = useNavigate()

    const onCancelHandler = () => navigate(0);

    return <Button onClick={onCancelHandler}>Отменить</Button>;
}


const InfoArticleEdit = () => {

    const article = useSelector((state) => state.detailArticle);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const onBackClick = () => {
        navigate(-1)
    }
    const [isArticle, setIsArticle] = useState(false)
    
    useEffect(() => {
        if(params.articleId) {
            dispatch(loadDetailArticleAsync(params.articleId))
            setIsArticle(true)
        } else {
            dispatch(loadSectionAsync(params.sectionId || params.subsectionId))
            setIsArticle(false)
        }
    }, [params])
    
    return (
        <div className='w-100  mt-5'>
            <Container className='d-flex flex-column gap-3'>
                <div className='w-100 mb-5'>
                    <Button className='btn' onClick={onBackClick}>Назад</Button>
                </div>
                <Preview isArticle={isArticle}/>
                <Title/>
                <Description/>
                {/* <HorizontalDivider/> */}
                {article.content && <ArticleText/>}
                {!article.content &&  
                                    <>
                                        <Destination/>
                                        <div className='d-flex w-100 gap-1'>
                                            <SaveSectionButton/>
                                            <CancelButton/>
                                        </div>
                                    </>
                }
            </Container>
        </div>

    );
};

export default InfoArticleEdit;