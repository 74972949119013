import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import MockBackgroundImage from "../../../assets/images/bg/background-mock.png";
import { getEducationProgramById } from "../../../services/EducationProgram";

const initialState = {
    id:0,
    title: "",
    description: "",
    preview_image_link: MockBackgroundImage,
    program_url: "",
    creation_time: "",
    type: ""
}

export const loadDetailEducationProgramAsync = createAsyncThunk(
    "loadDetailEducationProgramAsync",
    async (programId) => {
        const response = await getEducationProgramById({program_id: programId})
        return response.data;
    }
)

const detailEducationProgramSlice = createSlice({
    name: "detailEducationProgram",
    initialState,
    reducers: {
        clearState: () => ({...initialState}),
        changeTitleEducationProgram: (state, action) => {
            state.title = action.payload;
        },
        changeEducationProgramId: (state, action) => {
            state.id = action.payload;
        },
        changeDescriptionEducationProgram: (state, action) => {
            state.description = action.payload;
        },
        changeEducationProgramUrl: (state, action) => {
            state.program_url = action.payload;
        },
        changePreview: (state, action) => {
            state.preview_image_link = action.payload;
        },
        changeTypeEducationProgram: (state, action) => {
            state.type = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadDetailEducationProgramAsync.fulfilled, (state, action) => {
                if (action.payload) {
                    const {
                        id,
                        preview_image_link,
                        creation_time,
                        title,
                        description,
                        program_url,
                        type
                    } = action.payload;

                    state.id = id;
                    state.preview_image_link = preview_image_link;
                    state.creation_time = creation_time;
                    state.title = title;
                    state.description = description;
                    state.program_url = program_url;
                    state.type = type;
                }
            })  
    }
})

export const {
    clearState,
    changeTitleEducationProgram,
    changeEducationProgramId,
    changeDescriptionEducationProgram,
    changeEducationProgramUrl,
    changeTypeEducationProgram,
    changePreview
} = detailEducationProgramSlice.actions;

export default detailEducationProgramSlice.reducer;