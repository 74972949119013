import React from "react";
import { Link } from "react-router-dom";

const CardComponent = ({ title, description, backgroundColor, icon, link }) => {
  const handleCardClick = () => {
    window.location.href = link;
}
  return (
    <div className="feature-card bg-white p-6 rounded-4 f-icon-hover card-hover" onClick={handleCardClick}>
      <div
        className="mb-4 rounded f-icon-shape-sm"
        style={{ backgroundColor: backgroundColor }}
      >
          <img src={icon} alt=""/>
      </div>
      <div>
        <h5 className="mb-3">{title}</h5>
        <p className="mb-4">{description}</p>
        <Link to={link} className="btn-arrow"></Link>
      </div>
    </div>
  );
};
export default CardComponent;
