import React from "react";
import Fade from 'react-reveal/Fade';
import Feature from "../../Components/feature/Feature";
import Counter from "../../Components/counter/Counter";
import RegisterBanner from "../../Components/register-banner/RegisterBanner";
import HeroBanner1 from "../../Components/heroBanner/HeroBanner";
import Teachers from "../../Components/Teachers/TeachersGroup";
import TeachersToSchool from "../../Components/TeachersToSchool/TeachersToSchool";
const Index = () => {
  return (
    <div>
      <HeroBanner1 />
      <div className="page-content">
        <Feature />
        <Counter />
        <TeachersToSchool/>
        <Fade>
          <Teachers/>
        </Fade>
        <Fade bottom>
          <RegisterBanner />
        </Fade>
      </div>
    </div>
  );
}

export default Index;
