import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { 
  setFullName,
  setEmail,
  setPhoneNumber,
  setMessage,
} from "../../store/reducer/manage-contact-us/contact-us-form";

const ContactForm = ({handleSubmit}) => {
  const contactUs = useSelector((state) => state.contactUsForm);
  const dispatch = useDispatch();
  
  return (
    <div className="bg-white shadow p-5 rounded-4">
      <Form id="contact-form" className="row">
        <div className="messages"></div>
        <FormGroup className="col-md-12">
          <Label className="font-w-6">ФИО</Label>
          <Input
            type="text"
            name="firstName"
            value={contactUs.fullName}
            onChange={(e) => dispatch(setFullName(e.target.value))}
            placeholder="ФИО"
            required
            data-error="Обязательное поле"
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Email </Label>
          <Input
            type="email"
            name="email"
            value={contactUs.email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
            placeholder="Email"
            required
            data-error="Обязательное поле"
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Номер телефона</Label>
          <Input
            type="tel"
            name="phone"
            value={contactUs.phone_number}
            onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
            placeholder="Номер телефона"
            required
            data-error="Обязательное поле"
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <FormGroup className="form-group col-md-12">
          <Label className="font-w-6">Сообщение</Label>
          <Input
            type="textarea"
            name="message"
            value={contactUs.message}
            onChange={(e) => dispatch(setMessage(e.target.value))}
            className="form-control rounded-4 h-auto"
            placeholder="Сообщение"
            rows="4"
            required
            data-error="Оставьте нам сообщение"
          />
          <div className="help-block with-errors"></div>
        </FormGroup>
        <Col className="mt-4">
          <Button color="primary" onClick={handleSubmit}>
            Отправить
          </Button>
        </Col>
      </Form>
    </div>
  );
};

export default ContactForm;
